import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface PreCheckoutModalViewedEventMetadata {
  manualReviewRequired: boolean | null
}

export const preCheckoutModalViewedEvent =
  (tracker: any) =>
  ({ manualReviewRequired }: PreCheckoutModalViewedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.dmePreCheckoutModalViewed,
      manualReviewRequired,
    })
    tracker.trackEvent(payload)
  }
