import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    phoneNumberRoot: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    phoneNumber: {
      paddingLeft: theme.spacing(1),
    },
  })
)
