export default class InsuranceVerificationFailedFactory {
  private error: APIResponse.DME.SubscriptionErrorType

  constructor(error: APIResponse.DME.SubscriptionErrorType) {
    this.error = error
  }

  get titleCopy() {
    switch (this.error) {
      case 'INSURANCE_VERIFICATION_FAILED_INACTIVE':
        return 'The insurance plan we have on file is no longer active.'
      case 'INSURANCE_VERIFICATION_FAILED_INVALID':
        return 'Something went wrong when we tried verifying your insurance.'
      case 'INSURANCE_LIMIT_REACHED':
        return "You've used your entire insurance benefit for replacement parts."
      case 'NO_PARTS_AVAILABLE':
        return 'We do not cover parts for your pump model under your insurance plan.'
      default:
        return 'We no longer support your insurance at this time.'
    }
  }

  get bodyCopyFirstLine() {
    switch (this.error) {
      case 'INSURANCE_VERIFICATION_FAILED_INACTIVE':
        return "If you've changed insurance, you can update it below and use it for your order."
      case 'INSURANCE_VERIFICATION_FAILED_INVALID':
        return 'To continue, please provide us with up-to-date insurance information.'
      case 'INSURANCE_LIMIT_REACHED':
        return 'The maximum number of replacement parts orders has been reached.'
      case 'NO_PARTS_AVAILABLE':
        return 'Your insurance provides a limited reimbursement for pump parts, so parts for upgrade models are not eligible for free.'
      default:
        return "It's likely your insurance covers replacement parts! Please contact them to discuss your options."
    }
  }

  get bodyCopySecondLine() {
    switch (this.error) {
      case 'INSURANCE_VERIFICATION_FAILED_INVALID':
        return 'Be sure that your first and last name match exactly what is on your insurance card, otherwise we may not be able to verify your coverage.'
      case 'INSURANCE_UNSUPPORTED':
        return "If you've changed insurance, you can update it below and use it for your order."
      case 'INSURANCE_LIMIT_REACHED':
        return "If you've changed insurance and can order additional parts, you can update your insurance below."
      case 'NO_PARTS_AVAILABLE':
        return "If you've changed insurance, you can update it below and use it for your order."
      default:
        return ''
    }
  }

  get updateInsuranceButtonCopy() {
    if (this.error === 'INSURANCE_VERIFICATION_FAILED_INVALID') {
      return 'Update Insurance'
    }

    return 'I have new insurance'
  }
}
