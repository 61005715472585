import { Instance, types } from 'mobx-state-tree'

const ProductStateModel = types
  .model({
    id: types.maybeNull(types.number),
    price: types.maybeNull(types.number),
  })
  .views((self) => ({
    get present() {
      return self.id !== null
    },
  }))
  .actions((self) => {
    function update(updates: PlainProductStateModelType) {
      self.id = updates.id
      self.price = updates.price
    }

    return { update }
  })

export default ProductStateModel
export type ProductStateModelType = Instance<typeof ProductStateModel>
export type PlainProductStateModelType = Pick<
  ProductStateModelType,
  'id' | 'price'
>
