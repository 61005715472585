import fetch from 'lib/fetch'
import { REPLACEMENT_PARTS_PRODUCT_PARTS_ENDPOINT } from 'bl-health/requests/constants'
import { ReplacementPartsHeroProductCombined } from 'bl-health/types'
import { RequestMethod } from './RequestMethod'

type Response = { data: ReplacementPartsHeroProductCombined[] }

export default async function getProductReplacementParts(
  productId: number
): Promise<Response> {
  return fetch(REPLACEMENT_PARTS_PRODUCT_PARTS_ENDPOINT(productId), {
    method: RequestMethod.GET,
  })
}
