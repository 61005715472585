import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useTracking } from 'lib/babylistHealthAnalytics'
import { ApplicationStrategy } from 'bl-health/types'
import { ReplacementPartsCatalogStrategy } from 'bl-health/components'
import { productListViewedEvent } from 'bl-health/events'
import ProductListingWidget from '../../widgets/ProductListingWidget/ProductListingWidget'
import { useDMEStore } from '../../store'
import PLPBannerWidget from '../../widgets/PLPBannerWidget/PLPBannerWidget'
import EdgeDrawerEmailWidget from '../../widgets/EdgeDrawerEmailWidget/EdgeDrawerEmailWidget'
import { useLayout } from '../../widgets/Layout'

export default observer(() => {
  const store = useDMEStore()
  const theme = useTheme()
  const layout = useLayout()
  const plpEmailCollectionEnabled = layout?.isPlpEmailCollectionEnabled
  const isSmallScreen = !useMediaQuery(theme.breakpoints.up('sm'))
  const tracker = useTracking()

  React.useEffect(() => {
    productListViewedEvent(tracker)()
  }, [])

  if (store.context.strategy === ApplicationStrategy.Parts) {
    return <ReplacementPartsCatalogStrategy />
  }

  return (
    <>
      <PLPBannerWidget />
      <ProductListingWidget
        catalogId={store.insurance.catalogCategoryId || 'breast-pumps'}
      />
      {plpEmailCollectionEnabled && isSmallScreen && <EdgeDrawerEmailWidget />}
    </>
  )
})
