import * as yup from 'yup'

export const autocompleteValidationSchema = yup.object({
  state: yup.string().required('Select your state to proceed.'),
  insurance: yup.string().required('Select your insurance to proceed.'),
  email: yup
    .string()
    .email()
    .when('$experimentGroup', ([experimentGroup], schema) => {
      if (experimentGroup === 'required') {
        return schema.required(
          "Email is required. Don't worry—spam is not our thing!"
        )
      }
      return schema
    }),
})

export const processInsuranceStates = (
  insuranceCatalog: APIResponse.InsuranceCatalog[]
) => insuranceCatalog?.map(({ stateName }) => stateName) || []

const byCompanyAsc = (a: APIResponse.Insurance, b: APIResponse.Insurance) => {
  if (a.company < b.company) {
    return -1
  }

  if (a.company > b.company) {
    return 1
  }

  return 0
}

export const findInsuranceProviders = (
  stateName: string,
  stateInsurancesCatalog: APIResponse.InsuranceCatalog[]
) => {
  const insurances =
    stateInsurancesCatalog.find(
      (stateInsuranceCatalogItem) =>
        stateInsuranceCatalogItem.stateName === stateName
    )?.insurances ?? []

  const eligibleInsurancesAsc = insurances
    .filter(({ eligible }) => eligible)
    .sort(byCompanyAsc)

  const eligiblePromotedInsurancesAsc = eligibleInsurancesAsc.filter(
    ({ promoted }) => promoted
  )

  return {
    allInsurances: eligibleInsurancesAsc,
    promotedInsurances: eligiblePromotedInsurancesAsc,
  }
}

export const autocompleteInsuranceCarrierOptions = (
  promotedInsurances: APIResponse.Insurance[],
  allInsuranceCarriers: APIResponse.Insurance[]
) => {
  const demotedAllInsuranceCarriers = allInsuranceCarriers.map(
    ({ promoted, ...rest }) => ({ ...rest, promoted: false })
  )
  return promotedInsurances.concat(demotedAllInsuranceCarriers)
}
