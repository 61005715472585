import { useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { grey } from '@material-ui/core/colors'
import useStyles from './styles'

const ShippingInfoWidget = ({
  shippingEligibilityDays,
}: {
  shippingEligibilityDays: number | null
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const classes = useStyles()
  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogContent>
          <DialogContentText>
            {shippingEligibilityDays ? (
              <>
                <Typography gutterBottom>
                  <b>Why isn't this shipping right away?</b> We ship your order
                  based on your insurance carrier's shipping rules for breast
                  pumps. Your order will ship once it meets the earliest
                  eligible date!
                </Typography>
                <Typography>
                  <b>Have questions?</b> Reach out to your insurance company and
                  they can provide more detail about shipping rules.
                </Typography>
              </>
            ) : (
              <Typography gutterBottom>
                Most insurances require us to wait to ship your pump until
                closer to your due date. Your insurance is more flexible and as
                soon as we have a prescription from your doctor, we will be able
                to ship your pump.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Box
        className={classes.shippingInfoIcon}
        color={grey[500]}
        component={IconButton}
        onClick={() => setDialogOpen(true)}
      >
        <InfoIcon fontSize="small" />
      </Box>
    </>
  )
}

export default ShippingInfoWidget
