import * as React from 'react'
import { CssBaseline } from '@material-ui/core'
import withReactQueryClientProvider from 'shared/HOC/withReactQueryClientProvider'
import BabyUIThemeProvider from 'baby-ui/foundation/BabyUIThemeProvider'
import AppBar from 'baby-ui/compounds/AppBar'
import Footer from 'baby-ui/compounds/Footer'
import { ApplicationProps, ApplicationStrategy } from 'bl-health/types'
import { ReplacementPartsBanner } from 'bl-health/widgets/ReplacementPartsBannerWidget/ReplacementPartsBannerWidget'
import { HOME_PATH } from 'bl-health/constants'
import { createRootStore, DMEStoreProvider, useDMEStore } from '../../store'
import { LayoutContextProvider } from './LayoutContext'
import { DMETheme } from '../../styles'
import { FooterNav } from './constants'
import InsuranceVerificationOverlay from '../InsuranceVerificationOverlay/InsuranceVerificationOverlay'
import NotificationWidget from '../NotificationWidget/NotificationWidget'

export type LayoutProps = { children: React.ReactNode } & ApplicationProps

export default function Layout({ settings, children }: LayoutProps) {
  const footerNav = FooterNav

  return (
    <LayoutContainer settings={settings}>
      <AppBar />
      {children}
      <Footer navGroups={footerNav} />
    </LayoutContainer>
  )
}

export const LayoutContainer: React.FC<LayoutProps> =
  withReactQueryClientProvider(({ settings, context, children }) => {
    const theme = new DMETheme()
    const rootStore = createRootStore(context)
    return (
      <LayoutContextProvider value={settings || null}>
        <DMEStoreProvider value={rootStore}>
          <BabyUIThemeProvider theme={theme}>
            <CssBaseline />
            {children}
            <NotificationWidget />
          </BabyUIThemeProvider>
        </DMEStoreProvider>
      </LayoutContextProvider>
    )
  })

export const LayoutPresenter: React.FC = ({ children }) => {
  const isHomePage = window.location.pathname === HOME_PATH
  const store = useDMEStore()
  const showReplacementPartsBanner =
    store.context.strategy == ApplicationStrategy.Pumps && !isHomePage
  const footerNav = FooterNav

  return (
    <>
      <AppBar />
      {showReplacementPartsBanner && <ReplacementPartsBanner />}
      <InsuranceVerificationOverlay />
      {children}
      <Footer navGroups={footerNav} />
    </>
  )
}
