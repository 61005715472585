import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface InsuranceCarrierSelected {
  insuranceName: string
  location: keyof typeof track.EventLocation
}

export const insuranceCarrierSelected =
  (tracker: any) =>
  ({ insuranceName, location }: InsuranceCarrierSelected) => {
    const payload = decorateEventPayload({
      event: track.insuranceCarrierSelected,
      insuranceName,
      eventLocation: location,
    })
    tracker.trackEvent(payload)
  }
