import { Theme, Typography, useMediaQuery } from '@material-ui/core'
import Link from 'baby-ui/foundation/Link/Link'
import { EXISTING_CUSTOMER_PATH } from 'bl-health/constants'
import { DMEInfoCard } from 'bl-health/components/InfoCard/DMEInfoCard'
import useStyles from './styles'

const title = 'Ordered your breast pump on Babylist Health?'
const link = 'Skip ahead and enter your email'

export const ReplacementPartsLink = () => {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  if (isDesktop) {
    return (
      <div className={classes.desktopView}>
        <Typography align="center">{title}</Typography>
        <Link
          align="center"
          className={classes.link}
          href={EXISTING_CUSTOMER_PATH}
          underline="always"
          variant="chevronIcon"
        >
          {link}
        </Link>
      </div>
    )
  }

  return (
    <DMEInfoCard
      content={title}
      link={EXISTING_CUSTOMER_PATH}
      linkText={link}
    />
  )
}
