import * as React from 'react'
import PanelSearchField from 'baby-ui/foundation/PanelSearchField/PanelSearchField'
import {
  Divider,
  Box,
  ListItemText,
  Modal,
  List,
  ListItem,
  Zoom,
  ListSubheader,
} from '@material-ui/core'
import PanelHeader from 'baby-ui/foundation/PanelHeader/PanelHeader'
import PanelFooter from 'baby-ui/foundation/PanelFooter/PanelFooter'
import Panel from 'baby-ui/foundation/Panel/Panel'
import CheckIcon from '@material-ui/icons/Check'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import useStyles from './FullScreenPanelTableWidget.styles'
import {
  findMatchingOptions,
  getLabel,
} from './FullScreenPanelTableWidget.utils'
import { insuranceCarrierSelected } from '../../events/insuranceCarrierSelected'
import { INSURANCE_PANEL_NAME } from './FullScreenPanelTableWidget.constants'

type EventLocations = keyof typeof track.EventLocation

export interface FullScreenPanelTableWidgetProps<Option> {
  featuredOptions?: Option[]
  featuredOptionsSubheader?: string
  optionsSubheader?: string
  footer?: React.ReactNode
  getOptionLabel?: (item: Option) => string
  name: string
  onClose?: () => void
  onOptionSelect?: (item: Option) => void
  open?: boolean
  options: Option[]
  selected?: Option
  title: string
}
export default function FullScreenPanelTableWidget<Option = any>({
  options,
  open = false,
  onOptionSelect,
  onClose,
  getOptionLabel,
  title,
  name,
  selected,
  footer,
  featuredOptions,
  featuredOptionsSubheader,
  optionsSubheader,
}: FullScreenPanelTableWidgetProps<Option>) {
  const panelSearchFieldRef = React.useRef<HTMLInputElement>(null)
  const [displayedOptions, setDisplayedOptions] = React.useState<Option[]>([])
  const [searchValue, setSearchValue] = React.useState('')

  React.useEffect(() => {
    setDisplayedOptions(options)
  }, [options])

  const blurSearchField = () => {
    panelSearchFieldRef.current?.blur()
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const matchedOptions = findMatchingOptions(value, options, getOptionLabel)
    setSearchValue(value)
    if (value.length > 0) {
      setDisplayedOptions(matchedOptions)
    } else {
      setDisplayedOptions(options)
    }
  }

  const handleOnClose = () => {
    onClose?.()
    resetPanel()
  }

  const resetPanel = () => {
    // Resetting results that were filtered by the search field
    setDisplayedOptions(options)
    setSearchValue('')
  }

  const tracker = useTracking()

  const fireInsuranceCarrierSelectedEvent = (
    insuranceName: string,
    location: EventLocations
  ) => {
    // Don't want to fire this event if the user is selecting a state
    if (name === INSURANCE_PANEL_NAME) {
      insuranceCarrierSelected(tracker)({
        insuranceName,
        location,
      })
    }
  }

  const classes = useStyles()

  return (
    <Modal open={open}>
      <Zoom in={open}>
        <Box height="100%">
          <Panel onScroll={blurSearchField}>
            <PanelHeader onClose={handleOnClose}>{title}</PanelHeader>
            <PanelSearchField
              autoCapitalize="none"
              autoComplete="off"
              name={name}
              placeholder="Search..."
              ref={panelSearchFieldRef}
              type="text"
              value={searchValue}
              onChange={handleSearch}
            />
            <Divider variant="middle" />
            {!searchValue && Boolean(featuredOptions?.length) && (
              <>
                <List
                  aria-labelledby="featured-options-subheader"
                  component="nav"
                  subheader={
                    featuredOptionsSubheader ? (
                      <ListSubheader
                        className={classes.optionSubheader}
                        component="div"
                        id="featured-options-subheader"
                      >
                        {featuredOptionsSubheader}
                      </ListSubheader>
                    ) : undefined
                  }
                >
                  {featuredOptions?.map((featuredOption) => {
                    const label = getLabel(featuredOption, getOptionLabel)
                    const selectedLabel =
                      selected && getLabel(selected, getOptionLabel)
                    return (
                      <ListItem
                        button
                        key={`featured-option:${label}`}
                        onClick={() => {
                          onOptionSelect?.(featuredOption)
                          resetPanel()
                          fireInsuranceCarrierSelectedEvent(
                            label,
                            track.EventLocation
                              .INSURANCE_PANEL_MINUS_TOP_INSURANCES as EventLocations
                          )
                        }}
                      >
                        <ListItemText primary={label} />
                        {label === selectedLabel && (
                          <CheckIcon color="primary" />
                        )}
                      </ListItem>
                    )
                  })}
                </List>
                <Divider variant="middle" />
              </>
            )}
            <List
              aria-labelledby="options-subheader"
              component="nav"
              subheader={
                optionsSubheader && searchValue.length === 0 ? (
                  <ListSubheader
                    className={classes.optionSubheader}
                    component="div"
                    id="options-subheader"
                  >
                    {optionsSubheader}
                  </ListSubheader>
                ) : undefined
              }
            >
              {displayedOptions.map((item) => {
                const label = getLabel(item, getOptionLabel)
                const selectedLabel =
                  selected && getLabel(selected, getOptionLabel)
                return (
                  <ListItem
                    button
                    key={`regular-option:${label}`}
                    onClick={() => {
                      onOptionSelect?.(item)
                      resetPanel()
                      // We want to be able to differentiate between the user selecting an insurance
                      // from the list vs from the search results list
                      const location =
                        searchValue.length > 0
                          ? track.EventLocation
                              .INSURANCE_PANEL_MINUS_SEARCH_RESULTS
                          : track.EventLocation
                              .INSURANCE_PANEL_MINUS_ALL_INSURANCES
                      fireInsuranceCarrierSelectedEvent(
                        label,
                        location as EventLocations
                      )
                    }}
                  >
                    <ListItemText primary={label} />
                    {label === selectedLabel && <CheckIcon color="primary" />}
                  </ListItem>
                )
              })}
            </List>
            {footer && <PanelFooter>{footer}</PanelFooter>}
          </Panel>
        </Box>
      </Zoom>
    </Modal>
  )
}
