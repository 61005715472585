import { currentUserLoadedPromise } from 'shared/utils/currentUserLoadedPromise/currentUserLoadedPromise'
import { track } from 'lib/analytics'
import { mapKeys, camelCase } from 'lodash'
import {
  getExpiringLocalStorage,
  setExpiringLocalStorage,
} from 'baby-ui/utils/expireLocalStorage'
import { SearchPreviewIds } from './types'
import {
  loggedInInputPlaceholder,
  loggedOutInputPlaceholder,
} from './constants'

export const getInputPlaceholder = (loggedIn: boolean) =>
  loggedIn ? loggedInInputPlaceholder : loggedOutInputPlaceholder

export const SEARCH_PREVIEW_IDS_LOCAL_STORAGE_KEY = 'unified-search-preview-ids'
export const SEARCH_PREVIEW_IDS_EXPIRATION_SECONDS = 1800 // 30 minutes

export const trackUnifiedSearchEvent = (
  event: {
    event_name: string
    properties: any
  },
  trackEvent: (props: any) => unknown
) => {
  const eventName = event.event_name
  const formattedEventProperties = mapKeys(event.properties, (_, key) =>
    camelCase(key)
  )

  currentUserLoadedPromise.then((currentUser) => {
    trackEvent({
      event: (track as any)[eventName],
      isRegistryOwner:
        currentUser?.currentRegistry?.currentUserRole === 'owner',
      registryOwnerId: currentUser?.currentRegistry?.ownerId,
      registryId: currentUser?.currentRegistry?.id,
      ...formattedEventProperties,
    })
  })
}

export const setSearchPreviewIds = (
  searchPreviewIds: SearchPreviewIds | string
) => {
  let value
  if (typeof searchPreviewIds === 'string') {
    value = searchPreviewIds
  } else {
    value = JSON.stringify(searchPreviewIds)
  }
  setExpiringLocalStorage(
    SEARCH_PREVIEW_IDS_LOCAL_STORAGE_KEY,
    value,
    SEARCH_PREVIEW_IDS_EXPIRATION_SECONDS
  )
}

export const getSearchPreviewIds = () => {
  const value = getExpiringLocalStorage(SEARCH_PREVIEW_IDS_LOCAL_STORAGE_KEY)
  return value
}
