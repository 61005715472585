import es6Promise from 'es6-promise'
import HOSTNAME from './hostname'
import { REGISTRY_SUBDOMAIN_PREFIX, absoluteUrl } from './urls'

es6Promise.polyfill() // polyfill older browsers that don't have promise support

function checkStatus(response) {
  if (response.status == 204) {
    // no content in the body, don't try to parse the response json
    return Promise.resolve()
  }
  if (response.status >= 200 && response.status < 300) {
    return response.json()
  }
  return response
    .json()
    .catch(() => response.text())
    .then(Promise.reject.bind(Promise))
}

export default function (path, config = {}) {
  if (isRequestFromRegistrySubdomain()) {
    path = absoluteUrl(path)
  } else if (path.startsWith('/')) {
    path = HOSTNAME + path
  }
  const credentials =
    isRequestFromRegistrySubdomain() && isBabylistRequest(path)
      ? 'include'
      : 'same-origin'
  const headers = config.headers || {}

  // only set content type if the request is not a GET, HEAD, or DELETE
  if (
    config.method &&
    config.method != 'GET' &&
    config.method != 'HEAD' &&
    config.method != 'DELETE'
  ) {
    headers['Content-Type'] = 'application/json'
    // if there's no body add one b/c the server expects it when the content type is json
    if (!config.body) {
      config.body = JSON.stringify({})
    }
  }

  const csrfMeta = document.querySelector('meta[name="csrf-token"]')
  if (csrfMeta) {
    headers['X-CSRF-Token'] = csrfMeta.getAttribute('content')
  }

  // Inflect keys to camel case by default
  if (config.inflectKeys !== false) {
    headers['X-Key-Inflection'] = 'camel'
  }

  if (isBabylistRequest(path)) {
    // These are added to enable consistent attributes for Segment events triggered on the server.
    // They match the client-side values populated in app/assets/javascripts/lib/analytics/track.js:125-126.
    // See server-side usage in packs/analytics/app/lib/analytics/request_properties.rb
    headers['X-Referrer'] = document?.referrer
    headers['X-Current-URL'] = window?.location?.href
    headers['X-Babylist-SPA-Version'] = 'a'
  }

  delete config.inflectKeys
  config = { ...config, credentials, headers }
  return fetch(path, config).then(checkStatus)
}

const isRequestFromRegistrySubdomain = () => {
  const host = window?.location?.hostname
  return host && host.startsWith(REGISTRY_SUBDOMAIN_PREFIX)
}

const isBabylistRequest = (path) => {
  if (path.startsWith('/')) {
    return true
  }

  try {
    const url = new URL(path)
    return (
      url.hostname.endsWith('babylist.com') ||
      url.hostname.endsWith('babylist.test')
    )
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return false
  }
}
