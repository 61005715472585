import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { CUSTOMER_INSURANCES_ENDPOINT } from './constants'

interface CreateCustomerInsurancePayload {
  customer: {
    id: number
    aobAcknowledged: boolean
    firstName?: string
    lastName?: string
  }
  phiCustomerInsurance: {
    insuranceState: string
    insuranceId: number
    insuranceMemberId: string
  }
  subscription: {
    id: string
  }
}

export default function createCustomerInsurance(
  payload: CreateCustomerInsurancePayload
): Promise<APIResponse.CustomerInsurance> {
  return fetch(CUSTOMER_INSURANCES_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })
}
