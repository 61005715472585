import HeroCTAWidget from 'bl-health/widgets/HeroCTAWidget/HeroCTAWidget'
import { Box, Link, Typography } from '@material-ui/core'
import IllustratedSteps from 'baby-ui/compounds/IllustratedSteps'
import {
  THREE_STEPS_PARACHUTE_URL_IMG_URL,
  THREE_STEPS_PORTRAIT_URL_IMG_URL,
  THREE_STEPS_PUMP_IMG_URL,
} from 'bl-health/views/constants'
import EducationHeroSection from 'bl-health/components/EducationHeroSection/EducationHeroSection'
import { DME_REPLACEMENT_PARTS_TERMS_URL } from 'bl-health/constants'
import useStyles from './styles'

const disclaimer = (
  <Typography color="textPrimary" variant="body2">
    *Exclusions apply.{' '}
    <Link
      href={DME_REPLACEMENT_PARTS_TERMS_URL}
      style={{ color: 'inherit' }}
      target="_blank"
      underline="always"
    >
      See details/terms
    </Link>
  </Typography>
)

export default function PumpsHomeView() {
  const classes = useStyles()

  return (
    <>
      <HeroCTAWidget />
      <EducationHeroSection
        buttonText="Select your pump"
        disclaimer={disclaimer}
        imageSrc="//images.babylist.com/image/upload/v1706907064/DME/dme-feeding-baby-with-gradient-shapes.png"
        title="Why order your breast pump through Babylist Health?"
      >
        <ul>
          <Typography gutterBottom component="li">
            We'll coordinate with your insurance and doctors to help you get
            what you need.
          </Typography>
          <Typography gutterBottom component="li">
            Get <b>free*</b> pump replacement parts and milk storage bags
            through your insurance with ease.
          </Typography>
          <Typography gutterBottom component="li">
            If your insurance is accepted, we make sure you have at least one
            100% free option to choose from.
          </Typography>
        </ul>
      </EducationHeroSection>

      <Box alignContent="center" className={classes.illustratedStepsWrapper}>
        <IllustratedSteps
          steps={[
            {
              title: 'Share your info',
              content:
                'Enter your state and insurance provider to see a list of eligible pumps.',
              imageUrl: THREE_STEPS_PUMP_IMG_URL,
              alt: 'Breast pump',
            },
            {
              title: 'Choose your pump',
              content:
                "Once you find one you like, we'll verify your insurance and handle the paperwork.",
              imageUrl: THREE_STEPS_PORTRAIT_URL_IMG_URL,
              alt: 'Prescription',
            },
            {
              title: 'Check your doorstep',
              content:
                'A breast pump will show up at your doorstep (with free shipping to boot)!',
              imageUrl: THREE_STEPS_PARACHUTE_URL_IMG_URL,
              alt: 'Product delivery',
            },
          ]}
          subtitle="(the easy way)"
          title="Get a breast pump through insurance"
        />
      </Box>
    </>
  )
}
