import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { CATALOG_CATEGORY_ENDPOINT } from './constants'

export default async function getCatalogCategory(
  catalogId: number | string
): Promise<APIResponse.CatalogCategory> {
  return fetch(CATALOG_CATEGORY_ENDPOINT(catalogId), {
    method: RequestMethod.GET,
  })
}
