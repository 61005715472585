import { useEffect, useState } from 'react'

interface UseCharactersRemainingProps {
  value?: string | null
  maxCharacters: number
}

export const useCharactersRemaining = ({
  value = '',
  maxCharacters,
}: UseCharactersRemainingProps): number => {
  const initialCharactersRemaining = maxCharacters - (value?.length ?? 0)
  const [charactersRemaining, setCharactersRemaining] = useState<number>(
    initialCharactersRemaining
  )

  useEffect(() => {
    const currentLength = value?.length ?? 0
    setCharactersRemaining(maxCharacters - currentLength)
  }, [value, maxCharacters])

  return charactersRemaining
}
