import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // https://tanstack.com/query/v4/docs/framework/react/reference/useQuery
      // Set conservatively low so that hydrated data is not considered immediately stale.
      staleTime: 300000, // 5 minutes
    },
  },
})
