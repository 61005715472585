export const isPrerenderUserAgent = () => {
  try {
    if (typeof window === 'undefined') {
      return false
    }
    return window?.navigator?.userAgent?.includes(
      'Prerender (+https://github.com/prerender/prerender)'
    )
  } catch (e) {
    return false
  }
}
