import { Instance, types } from 'mobx-state-tree'
import SnackbarStateModel from './SnackbarStateModel'
import OverlayStateModel from './OverlayStateModel'

const GlobalComponentsStateModel = types.model({
  Snackbar: types.optional(SnackbarStateModel, {}),
  Overlay: types.optional(OverlayStateModel, {}),
})

export default GlobalComponentsStateModel
export type GlobalComponentsStateModelType = Instance<
  typeof GlobalComponentsStateModel
>
export type PlainGlobalComponentsStateModelType = Pick<
  GlobalComponentsStateModelType,
  'Snackbar' | 'Overlay'
>
