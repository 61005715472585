import fetch from 'lib/fetch'
import { RequestMethod } from './RequestMethod'
import { LEAD_CREATION_ENDPOINT } from './constants'

export interface LeadCreationPayload {
  email: string
}

export default async function createLead(
  payload: LeadCreationPayload
): Promise<Response> {
  return fetch(LEAD_CREATION_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })
}
