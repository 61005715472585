import { Instance, SnapshotIn, types } from 'mobx-state-tree'

const PhysicianModel = types
  .model({
    npi: types.identifier,
    lastName: types.string,
    firstName: types.string,
    lastUpdatedEpoch: types.string,
    hasPhone: types.boolean,
    hasFax: types.boolean,
    address: types.model({
      address1: types.string,
      address2: types.maybe(types.string),
      addressPurpose: types.string,
      addressType: types.string,
      city: types.string,
      countryCode: types.string,
      countryName: types.string,
      faxNumber: types.maybe(types.string),
      postalCode: types.string,
      state: types.string,
      telephoneNumber: types.string,
    }),
  })
  .views((self) => ({
    get displayName() {
      return `${self.lastName}, ${self.firstName}`
    },
    get displayAddress() {
      return {
        line1: self.address.address1,
        line2: `${self.address.city}, ${
          self.address.state
        }, ${self.address.postalCode.slice(0, 5)}`,
      }
    },
    get missingContactInfo() {
      return !self.hasPhone && !self.hasFax
    },
  }))

export default PhysicianModel

export type PhysicianModelType = Instance<typeof PhysicianModel>

export type PhysicianModelIn = SnapshotIn<PhysicianModelType>
