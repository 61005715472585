import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    bannerContainer: {
      padding: theme.spacing(4),
      backgroundColor: theme.palette.background.contrast,
      textAlign: 'center',
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        marginBottom: 0,
      },
    },
    heading: {
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(1),
      margin: 'auto',
      maxWidth: 680,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
        margin: '0 auto',
      },
    },
    subText: {
      maxWidth: 680,
      margin: '0 auto',
      lineHeight: '22px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
        lineHeight: '20px',
        padding: theme.spacing(0, 4),
      },
    },
  })
)
