import fetch from 'lib/fetch'
import { CART_ENDPOINT } from './constants'
import { RequestMethod } from './RequestMethod'

export default function addToCart(
  cartPayload: APIPayload.Cart
): Promise<{ cart: APIResponse.Cart }> {
  return fetch(CART_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(cartPayload),
  })
}
