import {
  storeSearchResultsPath,
  unifiedSearchStoreSearchResultsPath,
} from 'lib/urls'
import { ResultType, SearchResult } from './types'

export const popularSearches: SearchResult[] = [
  {
    title: 'Popular Searches',
    type: ResultType.HEADING,
  },
  {
    title: 'Try-it Kits',
    query: 'Try-it Kits',
    type: ResultType.TYPE_AHEAD_SUGGESTION,
    url: storeSearchResultsPath('Try-it Kits'),
  },
  {
    title: 'Strollers',
    query: 'Strollers',
    type: ResultType.TYPE_AHEAD_SUGGESTION,
    url: storeSearchResultsPath('Strollers'),
  },
  {
    title: 'Nursery',
    query: 'Nursery',
    type: ResultType.TYPE_AHEAD_SUGGESTION,
    url: storeSearchResultsPath('Nursery'),
  },
  {
    title: 'Clothing',
    query: 'Clothing',
    type: ResultType.TYPE_AHEAD_SUGGESTION,
    url: storeSearchResultsPath('Clothing'),
  },
  {
    title: 'Postpartum',
    query: 'Postpartum',
    type: ResultType.TYPE_AHEAD_SUGGESTION,
    url: storeSearchResultsPath('Postpartum'),
  },
]

export const unifiedSearchPopularSearches = [
  ...popularSearches.map((searchResult: any) => ({
    ...searchResult,
    url: unifiedSearchStoreSearchResultsPath(searchResult?.title),
  })),
]

export const loggedInInputPlaceholder = 'Search products, guides & registries'
export const loggedOutInputPlaceholder = 'Search registries, products & guides'
