import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    contentContainer: {
      textAlign: 'center',
      margin: theme.spacing(2),
    },
    heading: {
      fontSize: 32,
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.h3.fontSize,
      },
    },
    overlay: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.background.contrast,
      [theme.breakpoints.up('sm')]: {
        background: `radial-gradient(50% 50% at 50% 50%, ${theme.palette.background.contrast} 0%, rgba(249, 246, 243, 0.9) 100%)`,
      },
    },
    wrapper: {
      margin: '0 auto',
      width: '50px',
      height: '50px',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: '75px',
        height: '75px',
      },
    },
    background: {
      height: '100%',
      position: 'relative',
      background: 'radial-gradient(at center top, #E06031, #FFD103)',
    },
    mask: {
      width: '150%',
      height: '150%',
      top: '-25%',
      left: '-25%',
      position: 'relative',
      background: theme.palette.background.contrast,
      maskImage:
        'url("//images.babylist.com/image/upload/v1685458357/DME/rainbow-mask.png")',
      maskRepeat: 'no-repeat',
      maskSize: '100%',
      maskPosition: 'center',
      animation: '$rotate 1.75s ease-in-out infinite',
    },
    '@keyframes rotate': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
    arch: {
      position: 'absolute',
      zIndex: -1,
      bottom: '2%',
      left: '4%',
    },
    burst: {
      position: 'absolute',
      zIndex: -1,
      right: 0,
      bottom: '20%',
    },
    vector: {
      position: 'absolute',
      zIndex: -1,
      left: 0,
      top: '10%',
    },
    clover: {
      position: 'absolute',
      zIndex: -1,
      top: '0%',
      right: '0%',
    },
  })
)
