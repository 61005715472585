import Layout from './widgets/Layout'
import globalStyles from './styles/globalStyles'
import NotFoundView from './views/NotFoundView'
import { DMEAppProps } from './DMEApp'

export type DMENotFoundPageProps = Pick<DMEAppProps, 'settings'>

const DMENotFoundPage = ({ settings }: DMENotFoundPageProps) => {
  const globalCSS = globalStyles()

  return (
    <Layout settings={settings}>
      <div className={globalCSS.babylistHealthStripe} />
      <NotFoundView />
    </Layout>
  )
}

export default DMENotFoundPage
