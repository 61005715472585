// @ts-ignore
import { track } from 'lib/analytics'
import { CurrentUser } from '../global'

/**
 * Derive the user's current role from the passed currentUser object.
 * @returns A new Avo collaborator role event value
 */
export function getCollaboratorRole(currentUser?: CurrentUser) {
  if (currentUser) {
    const role = currentUser?.currentRegistry?.currentUserRole

    if (role === 'partner') {
      return track.CollaboratorRole.PARTNER
    }

    if (role === 'owner') {
      return track.CollaboratorRole.REGISTRANT
    }

    if (role === 'collaborator') {
      return track.CollaboratorRole.COLLABORATOR
    }
  }

  return undefined
}

/**
 * Get the Avo Product type.
 * @returns An Avo ProductType property value
 */
export function getProductType(isGiftCard: boolean) {
  return isGiftCard ? track.ProductType.GIFT_CARD : track.ProductType.PRODUCT
}

/**
 * Get the value of the ref query parameter from the current URL.
 * @returns The value of the ref query parameter
 */
export function getRefFromURL(): string | null {
  if (typeof window === 'undefined') return null

  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get('ref')
}

/**
 * Get the value of the given query parameter from the current URL.
 *
 * @param key - The query parameter key
 * @returns The value of the query parameter
 * @example
 * // If the current URL is https://example.com?foo=bar
 * getQueryParam('foo') // 'bar'
 * getQueryParam('baz') // null
 */
export function getQueryParam(key: string): string | null {
  if (typeof window === 'undefined') return null

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const paramValue = urlParams.get(key)

  return paramValue
}

export function urlWithChecklistParam(url: string): string {
  const lpContent = getQueryParam('lp_content')

  return lpContent ? `${url}?lp_content=${lpContent}` : url
}
