import { Instance, types } from 'mobx-state-tree'

const OverlayStateModel: any = types
  .model({
    open: types.optional(types.boolean, false),
    name: types.maybeNull(types.enumeration('Name', ['insuranceVerification'])),
  })
  .actions((self) => {
    const show = (name: Instance<typeof OverlayStateModel>['name'] = null) => {
      self.open = true
      self.name = name
    }

    const close = () => {
      self.open = false
    }

    return { show, close }
  })

export default OverlayStateModel
export type OverlayStateModelType = Instance<typeof OverlayStateModel>
