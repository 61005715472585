import * as React from 'react'
import { Paper } from '@material-ui/core'
import useStyles from './Panel.styles'

export interface PanelProps {
  children: React.ReactNode
  onScroll?: () => void
}
const Panel = ({ children, onScroll }: PanelProps) => {
  const classes = useStyles()
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (onScroll) onScroll()
  }

  React.useEffect(() => {
    wrapperRef?.current?.addEventListener('scroll', handleScroll)

    return () => {
      wrapperRef?.current?.removeEventListener('scroll', handleScroll)
    }
  }, [wrapperRef])

  return (
    <Paper className={classes.root} component="div" ref={wrapperRef}>
      {children}
    </Paper>
  )
}

export default Panel
