import fetch from 'lib/fetch'
import { InsuranceValidatorCarouselProductResponse } from 'bl-health/types'
import { RequestMethod } from './RequestMethod'
import { INSURANCE_VALIDATOR_CAROUSEL_PRODUCTS_ENDPOINT } from './constants'

export default function getInsuranceValidatorCarouselProducts(): Promise<InsuranceValidatorCarouselProductResponse> {
  return fetch(INSURANCE_VALIDATOR_CAROUSEL_PRODUCTS_ENDPOINT, {
    method: RequestMethod.GET,
  })
}
