import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import { ApplicationContext, ApplicationStrategy } from 'bl-health/types'

interface FieldError<T> {
  error: { fields: T }
}

export type Error =
  | APIResponse.StandardError
  | FieldError<{ email?: string[] }>
  | FieldError<{ phiCustomerInsurance?: { insuranceMemberId?: string[] } }>
  | { errors: { title: string }[] }

type FieldErrorWithBase = { [key: string]: { base: string[] } }

function extractErrorMessage(value: string | string[]) {
  if (isArray(value)) {
    return value.pop()
  }

  if (isString(value)) {
    return value
  }

  return undefined
}

function findErrorMessageViaBaseProperty(fields?: FieldErrorWithBase) {
  let errorMsg: string | undefined

  if (fields) {
    if (fields.base) {
      errorMsg = extractErrorMessage(fields.base as any)
    } else {
      const keys = Object.keys(fields)
      keys.forEach((key) => {
        if (fields[key].base) {
          errorMsg = extractErrorMessage(fields[key].base)
        }
      })
    }
  }

  return errorMsg
}

function findErrorMessage(error: Error) {
  const message = get(error, 'error.message')
  const title = get(error, 'error.title')
  const altTitle = get(error, 'errors[0].title')

  return (
    findErrorMessageViaBaseProperty(get(error, 'error.fields')) ||
    message ||
    title ||
    altTitle
  )
}

function findInsuranceMemberIdErrorMessage(error: Error) {
  const errorPath = 'error.fields.phiCustomerInsurance.insuranceMemberId[0]'
  const insuranceMemberId = get(error, errorPath)
  return insuranceMemberId
}

export default function apiErrorResponseAdapter(
  error: Error,
  context?: ApplicationContext
) {
  const birthDate = get(error, 'error.fields.phiCustomer.birthDate[0]')
  const dueDate = get(error, 'error.fields.phiCustomer.dueDate[0]')
  const email = get(error, 'error.fields.email[0]')
  const phoneNumber = get(error, 'error.fields.phoneNumber[0]')
  const lastName =
    get(error, 'error.fields.lastName[0]') ||
    get(error, 'error.fields.customer.lastName[0]')
  const firstName =
    get(error, 'error.fields.firstName[0]') ||
    get(error, 'error.fields.customer.firstName[0]')
  const aobAcknowledged = get(error, 'error.fields.aobAcknowledged[0]')
  const otp = get(error, 'error.fields.otp[0]')
  const dueDateLabel =
    context?.strategy === ApplicationStrategy.Parts ? "Baby's DOB" : 'Due Date'

  return {
    birthDate: birthDate ? `DOB ${birthDate}` : undefined,
    dueDate: dueDate ? `${dueDateLabel} ${dueDate}` : undefined,
    email,
    memberId: findInsuranceMemberIdErrorMessage(error),
    message: findErrorMessage(error),
    phoneNumber,
    lastName,
    firstName,
    aobAcknowledged: aobAcknowledged ? `AOB ${aobAcknowledged}` : undefined,
    otp,
  }
}
