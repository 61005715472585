import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    panelFooter: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      // Extra padding on the footer bottom to make space for iOS home button
      paddingBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.background.contrast,
      position: 'sticky',
      marginTop: 'auto',
      width: ' 100%',
      bottom: 0,
      borderTop: `${theme.palette.divider} 1px solid`,
    },
  })
)
