/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react'
import AlertDialog, { AlertDialogProps } from 'baby-ui/compounds/AlertDialog'
import { useTracking } from 'lib/babylistHealthAnalytics'
import {
  unsupportedInsuranceProviderSelectedEvent,
  unsupportedStateSelectedEvent,
} from 'bl-health/events'
import { useDMEStore } from 'bl-health/store'
import {
  UNSUPPORTED_INSURANCE_PLAN_MODAL,
  UNSUPPORTED_STATE_MODAL,
} from './constants'
import { dialogMap } from './misc'
import InsuranceNameForm, {
  InsuranceNameFormValues,
} from '../../forms/InsuranceNameForm'

export interface UnsupportedSelectionDialogProps
  extends Omit<AlertDialogProps, 'children' | 'title'> {
  dialogName: 'unsupportedStateModal' | 'unsupportedInsurancePlanModal'
  inputValue?: string
}

export default function UnsupportedSelectionDialog({
  dialogName,
  open,
  onClose,
  inputValue,
}: UnsupportedSelectionDialogProps) {
  const store = useDMEStore()
  const tracker = useTracking()
  const [providedInsuranceName, setProvidedInsuranceName] = useState<
    null | string
  >(null)
  const insuranceName = providedInsuranceName || inputValue
  const insuranceNameExists = !!insuranceName

  const shouldFireUnsupportedStateSelectedEvent =
    dialogName === UNSUPPORTED_STATE_MODAL
  const shouldFireUnsupportedInsuranceProviderSelectedEvent =
    dialogName === UNSUPPORTED_INSURANCE_PLAN_MODAL && insuranceNameExists

  useEffect(() => {
    function fireUnsupportedStateSelectedEvent() {
      unsupportedStateSelectedEvent(tracker)({ state: inputValue ?? '' })
    }

    function fireUnsupportedInsuranceProviderSelected() {
      unsupportedInsuranceProviderSelectedEvent(tracker)({
        insuranceName: insuranceName ?? '',
      })
    }
    if (open) {
      if (shouldFireUnsupportedStateSelectedEvent) {
        fireUnsupportedStateSelectedEvent()
      } else if (shouldFireUnsupportedInsuranceProviderSelectedEvent) {
        fireUnsupportedInsuranceProviderSelected()
      }
    }
  }, [
    inputValue,
    insuranceName,
    open,
    shouldFireUnsupportedInsuranceProviderSelectedEvent,
    shouldFireUnsupportedStateSelectedEvent,
    store.context.inPumpFlow,
    store.context.strategy,
    tracker,
  ])

  function getTitle() {
    if (dialogName === UNSUPPORTED_INSURANCE_PLAN_MODAL) {
      if (insuranceNameExists) {
        return dialogMap[dialogName].titleB
      }

      return dialogMap[dialogName].titleA
    }

    return dialogMap[dialogName].title
  }

  function handleOnSubmit(value: InsuranceNameFormValues) {
    setProvidedInsuranceName(value.insuranceProviderName)
  }

  function handleOnClose(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setProvidedInsuranceName(null)
    onClose?.(event)
  }

  function getBody() {
    const commonBody = <>{dialogMap[dialogName].description}</>

    if (dialogName === UNSUPPORTED_INSURANCE_PLAN_MODAL) {
      if (insuranceNameExists) {
        return commonBody
      }

      return <InsuranceNameForm onSubmit={handleOnSubmit} />
    }

    return commonBody
  }

  return (
    <AlertDialog open={open} title={getTitle()} onClose={handleOnClose}>
      {getBody()}
    </AlertDialog>
  )
}
