import { useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactOnScreenTrackVisibility from 'react-on-screen'
import { useTracking } from './track'

export const useTrackImpression = (properties, shouldFireEvent) => {
  const tracker = useTracking()

  useEffect(() => {
    if (shouldFireEvent) {
      tracker.trackEvent(properties)
    }
  }, []) // [] fire side effect only once!
}

export const TrackImpression = ({
  children,
  eventProperties,
  shouldFireEvent = true,
}) => {
  useTrackImpression(eventProperties, shouldFireEvent)
  return children
}

export const TrackVisibility = ({ children, eventProperties }) => {
  const tracker = useTracking()
  return (
    <ReactOnScreenTrackVisibility partialVisibility once>
      {({ isVisible }) => {
        if (isVisible) tracker.trackEvent(eventProperties)
        return children
      }}
    </ReactOnScreenTrackVisibility>
  )
}

TrackVisibility.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  eventProperties: PropTypes.object.isRequired,
}
