import fetch from 'lib/fetch'
import { ApplicationStrategy, CartAddOn } from 'bl-health/types'
import { rootStore } from 'bl-health/store'
import { CHECKOUT_ENDPOINT } from './constants'
import { RequestMethod } from './RequestMethod'

interface CheckoutPayload {
  cartItemIds?: string[]
  subscriptionId?: string
  aobAcknowledged?: boolean
  includeFreeGift?: boolean
  phiCustomerInsuranceId?: string
  strategy?: ApplicationStrategy
  addOns?: CartAddOn[]
}

export default function checkout(
  payload: CheckoutPayload
): Promise<APIResponse.Checkout> {
  if (!payload.strategy) {
    payload.strategy = rootStore.context.strategy
  }

  return fetch(CHECKOUT_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })
}
