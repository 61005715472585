export function getLabel<T = any>(
  option: T,
  makeOptionLabel?: (option: T) => string
) {
  if (typeof option === 'string') {
    return option
  }

  if (makeOptionLabel) {
    return makeOptionLabel(option)
  }

  throw new Error(
    'The value returned from `getLabel` must be a string. If you are using a custom `getOptionLabel` function, please make sure it returns a string.'
  )
}

export function findMatchingOptions<T = any>(
  needle: string,
  haystack: T[],
  makeOptionLabel?: (option: T) => string
) {
  return haystack.filter((option) => {
    const label = getLabel(option, makeOptionLabel)
    return label.toString().toLowerCase().includes(needle.toLowerCase())
  })
}
