import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface PumpCatalogSelectedEventMetadata {
  insuranceProvider: string
  insuranceProviderId: number
  insuredState: string
  emailProvided: boolean
}

export const pumpCatalogSelectedEvent =
  (tracker: any) =>
  ({
    insuranceProvider,
    insuranceProviderId,
    insuredState,
    emailProvided,
  }: PumpCatalogSelectedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.pumpCatalogSelected,
      insuranceProvider,
      insuranceProviderId,
      insuredState,
      emailProvided,
    })
    tracker.trackEvent(payload)
  }
