import { Box, Button, Typography, useTheme } from '@material-ui/core'
import { OpenInNewWindowIcon } from 'components/icons'
import { useEffect } from 'react'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { InsuranceNoBenefitCopy } from 'bl-health/views/ReplacementPartsView/ReplacementPartsOrderBlocker/InsuranceNoBenefitCopy'
import useStyles from './styles'
import { OrderInProgressCopy } from './OrderInProgressCopy'
import { OrderInIntervalCopy } from './OrderInIntervalCopy'
import { AwaitingPaidInvoiceCopy } from './AwaitingPaidInvoiceCopy'
import { UnknownErrorCopy } from './UnknownErrorCopy'
import { SubscriptionEndedCopy } from './SubscriptionEndedCopy'
import { AwaitingPumpDeliveryCopy } from './AwaitingPumpDeliveryCopy'
import { InsuranceStateUnsupportedCopy } from './InsuranceStateUnsupportedCopy'
import { InsurancePartsUnsupportedCopy } from './InsurancePartsUnsupportedCopy'
import { RecentDueDateCopy } from './RecentDueDateCopy'
import { PUMP_PARTS_STORE_PAGE_URL, STORE_NAME } from '../../../constants'
import { InsuranceDisabledCopy } from './InsuranceDisabledCopy'

export interface ReplacementPartsOrderBlockerProps {
  error:
    | Exclude<
        APIResponse.DME.SubscriptionErrorType,
        // These errors are special cases handled by ReplacementPartsView
        | 'INSURANCE_VERIFICATION_FAILED_INACTIVE'
        | 'INSURANCE_VERIFICATION_FAILED_INVALID'
        | 'INSURANCE_UNSUPPORTED'
        | 'INSURANCE_LIMIT_REACHED'
        | 'NO_PARTS_AVAILABLE'
      >
    | APIResponse.DME.SubscriptionIneligibilityErrorType
  subscription: APIResponse.DME.Subscription
}

const blockerComponents = {
  ORDER_IN_PROGRESS: OrderInProgressCopy,
  AWAITING_PAID_INVOICE: AwaitingPaidInvoiceCopy,
  ORDER_PLACED_WITHIN_INTERVAL: OrderInIntervalCopy,
  SUBSCRIPTION_ENDED: SubscriptionEndedCopy,
  UNKNOWN_ERROR: UnknownErrorCopy,
  ORDER_PLACED_BEFORE_CUTOFF: SubscriptionEndedCopy,
  ORDER_NOT_DELIVERED: AwaitingPumpDeliveryCopy,
  DUE_DATE_BEFORE_CUTOFF: SubscriptionEndedCopy,
  RECENT_DUE_DATE: RecentDueDateCopy,
  INSURANCE_STATE_NOT_ELIGIBLE: InsuranceStateUnsupportedCopy,
  NO_AVAILABLE_REPLACEMENT_PARTS: InsurancePartsUnsupportedCopy,
  INSURANCE_NO_BENEFIT: InsuranceNoBenefitCopy,
  INSURANCE_DISABLED: InsuranceDisabledCopy,
}

const ReplacementPartsOrderBlocker = ({
  error,
  subscription,
}: ReplacementPartsOrderBlockerProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const tracker = useTracking()

  const BlockerComponent =
    blockerComponents[error] || blockerComponents.UNKNOWN_ERROR

  useEffect(() => {
    tracker.trackEvent({
      event: track.replacementPartsOrderBlockerViewed,
      blockerType: error,
      storeName: STORE_NAME,
    })
  }, [])

  return (
    <Box
      bgcolor={theme.palette.background.contrast}
      flexGrow={1}
      px={{ xs: 4, sm: 0 }}
      py={8}
    >
      <Box className={classes.container}>
        <BlockerComponent subscription={subscription} />

        <Typography
          className={classes.subtitle}
          color="primary"
          component="h1"
          variant="h4"
        >
          Need parts now?
        </Typography>
        <Typography className={classes.body} color="primary" variant="body2">
          We should have what you need on the Babylist Shop.
        </Typography>

        <Button
          classes={{ root: classes.linkButton }}
          color="secondary"
          endIcon={<OpenInNewWindowIcon />}
          href={PUMP_PARTS_STORE_PAGE_URL}
          size="large"
          variant="contained"
        >
          Shop for Pump Parts
        </Button>
      </Box>
    </Box>
  )
}

export default ReplacementPartsOrderBlocker
