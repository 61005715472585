import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface AddedToCartEventMetadata {
  location: string
  productId: number
  price: number
}

export const addedToCartEvent =
  (tracker: any) =>
  ({ location, productId, price }: AddedToCartEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.addedToCart,
      cartItemQuantity: 1,
      cartTypes: [track.CartTypes.STORE],
      cartStores: [track.CartStores.BABYLIST_STORE],
      storeAdded: track.StoreAdded.BABYLIST,
      eventLocation: location,
      productIds: [productId],
      productPrice: price,
      productType: track.ProductType.PRODUCT,
      subscribeToMarketingEmail: false,
    })
    tracker.trackEvent(payload)
  }
