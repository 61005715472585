import { ButtonProps } from 'baby-ui/foundation/Button'
import inputConfig, {
  allFormFieldNamesInDisplayOrder,
  InputName,
  InputSetting,
} from '../input-config'
import { RootStateModelType } from '../../../store/models/RootStateModel'

export type PhysicianFormMode = 'search' | 'details' | 'veiled'

export type PhysicianFormValues = Partial<Record<InputName, string>>

export default class PhysicianFormFactory {
  private readonly initialValues: PhysicianFormValues

  private readonly mode: PhysicianFormMode

  private inputConfig = inputConfig

  constructor(mode: PhysicianFormMode, initialValues: PhysicianFormValues) {
    this.initialValues = initialValues
    this.mode = mode
  }

  static deriveDefaultValues(store: RootStateModelType) {
    const { searchInput } = store.physician
    const stateName = searchInput?.stateName || store.insurance.state
    return {
      firstName: searchInput?.firstName,
      lastName: searchInput?.lastName,
      stateName: stateName || undefined,
      city: searchInput?.city,
    }
  }

  createButton() {
    return {
      children: 'Continue',
      type: 'submit',
      variant: 'contained',
      fullWidth: true,
      color: 'primary',
    } as ButtonProps
  }

  createInputs() {
    if (this.mode === 'search') {
      return this.createSearchConfiguration()
    }

    if (this.mode === 'details') {
      return this.createDetailsConfiguration()
    }

    return this.createVeiledConfiguration()
  }

  private createSearchConfiguration() {
    return this.selectInputs(['firstName', 'lastName', 'city', 'stateName'])
  }

  private createDetailsConfiguration() {
    return this.selectInputs([...allFormFieldNamesInDisplayOrder])
  }

  private createVeiledConfiguration() {
    // Hides all of the fields that have a truthy default value
    return [...allFormFieldNamesInDisplayOrder].map((key) => {
      const setting = this.inputConfig[key] as InputSetting

      if (this.initialValues[key]) {
        return { ...setting, input: 'input', type: 'hidden' } as InputSetting
      }

      return setting
    })
  }

  private selectInputs(keys: InputName[]) {
    return keys.map((key) => this.inputConfig[key] as InputSetting)
  }
}
