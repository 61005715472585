import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { AUTH_CONTEXT_REVIEW, AUTH_CONTEXT_QUESTION } from 'shared/constants'
import {
  track,
  tracking,
  useTracking,
  getEventLocationFromPath,
} from 'lib/analytics'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { loginPathAsPopup } from 'lib/urls'
import onCurrentUserExternalLogin from 'shared/utils/onCurrentUserExternalLogin/onCurrentUserExternalLogin'
import { getCurrentUser } from 'shared/utils/getCurrentUser/getCurrentUser'

export const updateSku = (sku, gallerySkus) => {
  if (typeof window === 'undefined') return
  if (!window.TurnToCmd) return
  window.TurnToCmd('set', { sku })
  if (gallerySkus) window.TurnToCmd('gallery.set', { skus: gallerySkus })
}

const TurnToSnippet = ({ sku, gallerySkus, pageId, trackingOverride }) => {
  if (typeof window === 'undefined') return null
  const [currentUser] = useCurrentUser()
  const TURN_TO_SITE_KEY = window.__TURN_TO_SITE_KEY__
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const tracker = trackingOverride || useTracking() // trackingOverride ONLY used in test..

  window.turnToConfig = {
    sku,
    pageId,
    locale: 'en_US',
    gallery: {
      skus: gallerySkus,
    },
    eventHandlers: {
      // no callback on failure :( (like review already exist..)
      onQuestionSubmit: (event) => {
        tracker.trackEvent({
          eventLocation: getEventLocationFromPath(window.location.pathname),
          event: track.productQuestionCompleted,
        })
      },
      onReviewSubmit: (event) => {
        tracker.trackEvent({
          eventLocation: getEventLocationFromPath(window.location.pathname),
          event: track.productReviewCompleted,
        })
      },
    },
    /*
    TurnTo SSO settings (also required a flag to be switch by TurnTo on the account)
    https://docs.turnto.com/en/speedflex-widget-implementation/authentication.html
    https://docs.turnto.com/en/speedflex-widget-implementation/authentication/speedflex-single-sign-on--sso--integration.html
    - when SSO is enable, there is no login to a TurnTo authentication system AND a registration is REQUIRED!
     */
    sso: {
      // https://docs.turnto.com/en/speedflex-widget-implementation/authentication/speedflex-single-sign-on--sso--integration.html#step-2--confirm-a-user-s-logged-in-status-or-provide-a-registration-or-login-form
      // https://docs.turnto.com/en/speedflex-widget-implementation/authentication/speedflex-single-sign-on--sso--integration.html#userdatafn-contextobj-
      userDataFn: (context) => {
        /*
        This function is called when TurnTo NEEDS a user
        https://docs.turnto.com/en/speedflex-widget-implementation/authentication/speedflex-single-sign-on--sso--integration.html#function-reference-28052
        Widget context is passed around using a context token called contextObj. You can use the context token to display appropriate messaging.
        context is a base64 encoded hash
        Use the action context to customize the dialog
        The context can be pass around (in an email link, ...) to be restored in order to complete the current action (creating a review)
        BUT we need to be sure TurnTo SDK is available on the given page
         */
        // console.log('turnTo: sso.userDataFn', JSON.parse(atob(context)))
        const { action } = JSON.parse(atob(context)) // => { "action": "REVIEW_CREATE", "authSetting": "REGISTRATION_REQUIRED", "sku": "BL-3720", "submissionToken": "xxxx", "source": 2 }
        // question started event tracking
        if (action === 'QUESTION_CREATE') {
          // for question, there is NO pre-auth (loggedInDataFn)
          // so we use THIS method to trigger tracking
          // note: for review flow, we use the counter part method (loggedInDataFn)
          tracker.trackEvent({
            eventLocation: getEventLocationFromPath(window.location.pathname),
            event: track.productQuestionStarted,
            userLoggedIn: !!currentUser,
          })
        }
        // login or callback
        // customize login dialog based on action type
        const authContext = {
          REVIEW_CREATE: AUTH_CONTEXT_REVIEW,
          QUESTION_CREATE: AUTH_CONTEXT_QUESTION,
        }[action]

        const callback = () => {
          const updatedCurrentUser = getCurrentUser()
          const userDataToken = updatedCurrentUser?.turnToJwt
          // https://docs.turnto.com/en/speedflex-widget-implementation/authentication/speedflex-single-sign-on--sso--integration.html#ssoregdone
          window.TurnToCmd('ssoRegDone', { context, userDataToken })
        }
        if (currentUser) {
          callback()
        } else {
          // When you pass an email address to us during an SSO query, we treat that email address as confirmed
          // When a user registers with your site during the content submission process, you should call ssoRegDone only after the user's email address is verified to your satisfaction. TurnTo understands this call as your authorization to submit the content. If you only want to allow confirmed email addresses, wait to call the ssoRegDone function until after the user confirms their email address.
          window.open(loginPathAsPopup, '_blank')

          onCurrentUserExternalLogin(callback)
        }
      },
      loggedInDataFn: (context) => {
        /*
        This function is called by TurnTo code to retrieve details about a user who is currently logged in on your site.
        The operation is handled asynchronously, so this function does not have a return value.
        When a result is ready, your code makes a callback to the TurnTo loggedInDataFnDone function
        If the user is not logged in, the value of loggedInData should be null.
        - TurnTo does not keep track of the user's logged-in status. The SSO process is triggered each time a user takes an action that requires authentication.
        - turnTo call this method prior to presenting a review form (to avoid showing it if user logged in and already reviewed..)
        - do not attempt to login user here.. just a check from turnto to check if we have some user data
        https://docs.turnto.com/en/speedflex-widget-implementation/authentication/speedflex-single-sign-on--sso--integration.html#loggedindatafn-contextobj-
         */
        // console.log('turnTo: sso.loggedInDataFn', JSON.parse(atob(context)))
        const loggedInData = currentUser?.turnToJwt // loggedInData == null, indicate TurnTo, user not logged in
        // we send reviewStarted event here, because TurnTo call this method when starting a review to check if user is logged in
        // note: for question flow, we use the counter part method (userDataFn)
        // const decodedContext = JSON.parse(atob(context)) // => {"sku":"BL-3720","page":{"page":"review-form","props":{}},"launchData":{"startAction":"review","source":2},"launchType":"rev"}
        tracker.trackEvent({
          eventLocation: getEventLocationFromPath(window.location.pathname),
          event: track.productReviewStarted, // question flow DOES NOT trigger this loggedInDataFn
          userLoggedIn: !!loggedInData,
        })
        window.TurnToCmd('loggedInDataFnDone', { context, loggedInData })
      },
      logout: () => {
        /*
        Called when user "logout" from TurnTo widget
        We probably don't want to do anything here..
        https://docs.turnto.com/en/speedflex-widget-implementation/authentication/speedflex-single-sign-on--sso--integration.html#step-4--add-your-sso-logout-logic
         */
      },
    },
    teaser: {
      onFinish() {
        // custom logic to scroll to our scroll elements instead of theirs
        try {
          const el = document.querySelectorAll(
            "a.tt-c-teaser__link[href='#tt-reviews-list']"
          )[0]
          el.setAttribute('href', '#reviews-scroll-element')
          el.setAttribute('onclick', 'trackReviewTeaserReviewsQuickLink()')
          // overflow padding to cover the hearts and receive the same click behavior
          el.style.marginLeft = '-100px'
          el.style.paddingLeft = '104px'
          const qaLink = document.querySelectorAll(
            "a.tt-c-teaser__link[href='#tt-qa-list']"
          )[0]
          qaLink.setAttribute('href', '#qa-scroll-element')
          qaLink.setAttribute('onclick', 'trackReviewTeaserQnaQuickLink()')
        } catch (e) {
          // do nothing. If an error throws it's bc there are no reviews for the product
        }
      },
    },
  }
  window.TurnToCmd =
    window.TurnToCmd ||
    function () {
      // eslint-disable-next-line prefer-rest-params
      ;(TurnToCmd.q = TurnToCmd.q || []).push(arguments)
    }
  return (
    <Helmet>
      <script
        src={`https://widgets.turnto.com/v5/widgets/${TURN_TO_SITE_KEY}/js/turnto.js`}
        async
      />
    </Helmet>
  )
}

TurnToSnippet.propTypes = {
  sku: PropTypes.string,
  gallerySkus: PropTypes.arrayOf(PropTypes.string),
  pageId: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  trackingOverride: PropTypes.object,
}

TurnToSnippet.defaultProps = {
  sku: '',
  gallerySkus: [],
  pageId: 'pdp-page',
}

// contextualize the snippets (it will inherit PDP event props, like productIds, ...)
const TurnToSnippetWithTracking = tracking()(TurnToSnippet)
export { TurnToSnippetWithTracking as TurnToSnippet }
