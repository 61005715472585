import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

interface ProductPreviewRemoveButtonClickedEventMetadata {
  productId: number
  productName: string
}

export const productPreviewRemoveButtonClickedEvent =
  (tracker: any) =>
  ({
    productId,
    productName,
  }: ProductPreviewRemoveButtonClickedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.productPreviewRemoveButtonClicked,
      productId,
      productName,
    })
    tracker.trackEvent(payload)
  }
