import { Instance, SnapshotIn, types } from 'mobx-state-tree'

const UserStateModel = types
  .model({
    email: types.maybeNull(types.string),
    hasRx: types.optional(types.boolean, false),
    id: types.maybeNull(types.number),
    persistenceToken: types.maybeNull(types.string),
  })
  .views((self) => ({
    get present() {
      return !!self.id
    },
  }))
  .actions((self) => {
    function update({ id, persistenceToken, hasRx, email }: UserStateModelIn) {
      self.email = email!
      self.hasRx = hasRx!
      self.id = id!
      self.persistenceToken = persistenceToken!
    }

    return { update }
  })

export default UserStateModel
export type UserStateModelType = Instance<typeof UserStateModel>
export type UserStateModelIn = SnapshotIn<UserStateModelType>
