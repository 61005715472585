import { FormHelperText, Grid, Typography } from '@material-ui/core'
import Checkbox from 'baby-ui/foundation/CheckBox'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import React from 'react'
import { addToCart } from 'bl-health/requests'
import { REPLACEMENT_PARTS_CUSTOMER_INFORMATION_PATH } from 'bl-health/constants'
import { BrandsFragmentProps } from 'bl-health/components/ReplacementPartsCatalogStrategy/BrandsFragment'
import { ProductsFragmentProps } from 'bl-health/components/ReplacementPartsCatalogStrategy/ProductsFragment'
import { PartsFragmentProps } from 'bl-health/components/ReplacementPartsCatalogStrategy/PartsFragment'
import { observer } from 'mobx-react-lite'
import { useDMEStore } from 'bl-health/store'
import classNames from 'classnames'
import css from './ReplacementPartsCatalogStrategy.styles.scss'

export interface ActionFragmentProps {
  brandsController: BrandsFragmentProps['controller']
  productsController: ProductsFragmentProps['controller']
  partsController: PartsFragmentProps['controller']
}

export default observer(
  ({
    brandsController,
    partsController,
    productsController,
  }: ActionFragmentProps) => {
    const [agreed, setAgreed] = React.useState(false)
    const [agreedError, setAgreedError] = React.useState<string>()
    const [isLoading, setIsLoading] = React.useState(false)
    const shouldRenderText = partsController.selected?.availableOnBabylist
    const store = useDMEStore()

    function validate() {
      if (!brandsController.selected) {
        brandsController.setError('Please select a brand to proceed.')
        return false
      }

      if (!productsController.selected) {
        productsController.setError('Please select a product model to proceed.')
        return false
      }

      if (!partsController.selected) {
        partsController.setError('Please select a flange size to proceed.')
        return false
      }

      if (!partsController.selected.availableOnBabylist) return false

      if (!agreed) {
        setAgreedError(
          'You must read and accept the acknowledgement to continue.'
        )
        return false
      }

      return true
    }

    async function handleContinue() {
      const isValid = validate()

      if (isValid) {
        try {
          setIsLoading(true)
          const response = await addToCart({
            cartItem: { productId: partsController.selected!.id, quantity: 1 },
          })
          store.cart.setLastItemAdded(
            response.cart,
            partsController.selected!.id
          )
          window.location.assign(REPLACEMENT_PARTS_CUSTOMER_INFORMATION_PATH)
        } catch (e: any) {
          setIsLoading(false)
          store.ui.globalComponents.Snackbar.show(e.error.message)
        }
      }
    }

    return (
      <>
        {shouldRenderText && (
          <Grid item xs={12}>
            <Typography variant="body2">
              Your insurance will cover the following items and we will cover
              shipping costs:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">
                  2 sets of breast flanges
                </Typography>
              </li>
              <li>
                <Typography variant="body2">2 sets of tubing</Typography>
              </li>
              <li>
                <Typography variant="body2">2 backflow protectors</Typography>
              </li>
              <li>
                <Typography variant="body2">2 duckbill valves</Typography>
              </li>
              <li>
                <Typography variant="body2">
                  2 wide neck milk bottles with cap, disc, and lid
                </Typography>
              </li>
            </ul>
          </Grid>
        )}

        {shouldRenderText && (
          <Grid item xs={12}>
            <Checkbox
              checked={agreed}
              className={classNames({ [css.Checkbox_error]: !!agreedError })}
              label={
                <Typography variant="body2">
                  I confirm that I'm still pumping and need breast pump
                  replacement parts.
                </Typography>
              }
              onClick={(e) => {
                setAgreedError(undefined)
                // @ts-ignore for some reason type `checked` is not included int the type definition
                setAgreed(e.target.checked)
              }}
            />
            {!!agreedError && (
              <FormHelperText error>{agreedError}</FormHelperText>
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <div className={css.ActionFragment__button}>
            <LoadingButton
              fullWidth
              color="primary"
              loading={isLoading}
              variant="contained"
              onClick={handleContinue}
            >
              Continue
            </LoadingButton>
          </div>
        </Grid>
      </>
    )
  }
)
