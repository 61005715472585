import { useCallback, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { CartAddOn } from 'bl-health/types'
import { addedToCartEvent, formSubmittedEvent } from 'bl-health/events'
import { addToCart, checkout } from '../../requests'
import apiErrorResponseAdapter, {
  Error,
} from '../../utils/apiErrorResponseAdapter'

// Checkout params driving by user form state
interface CheckoutFormParams {
  productId?: number
  productPrice?: number
  aobAcknowledged?: boolean
}

// Checkout params not driven by form state
interface CheckoutIdentifierParams {
  subscriptionId: string
  customerInsuranceId: string
  addOns: CartAddOn[] | undefined
}

const useCheckout = (
  { subscriptionId, customerInsuranceId, addOns }: CheckoutIdentifierParams,
  onError: (e: Error) => void
) => {
  const [isNavigating, setIsNavigating] = useState(false)
  const [checkoutParams, setCheckoutParams] = useState<CheckoutFormParams>({})
  const tracker = useTracking()

  const addToCartSucceeded = useCallback(
    (response: { cart: APIResponse.Cart }) => {
      const cartItems = response.cart.items.filter(
        (item) => item.productId === checkoutParams.productId
      )

      // In practice, `checkoutParams.productId` should always be defined if we've made it this far.
      // The if guard is due to the event tracking function's type definition for `productId`.
      if (checkoutParams.productId) {
        addedToCartEvent(tracker)({
          location: track.EventLocation.HEALTH_REPLACEMENT_PARTS_ORDER_FORM,
          productId: checkoutParams.productId,
          price: checkoutParams.productPrice || 0,
        })
      }

      checkoutMutation({
        cartItemIds: cartItems.length ? [cartItems[0].uuid] : [],
        subscriptionId,
        aobAcknowledged: checkoutParams?.aobAcknowledged,
        phiCustomerInsuranceId: customerInsuranceId,
        addOns,
      })
    },
    [
      subscriptionId,
      customerInsuranceId,
      checkoutParams.aobAcknowledged,
      checkoutParams.productId,
      addOns,
    ]
  )

  const checkoutSucceeded = ({ checkoutPageUrl }: APIResponse.Checkout) => {
    formSubmittedEvent(tracker)({
      formName: track.FormName.REPLACEMENT_PARTS_ORDER_FORM,
      status: track.Status.SUCCESS,
    })
    setIsNavigating(true)
    window.location.href = checkoutPageUrl
  }

  const errorHandler = (e: Error) => {
    const formattedError = apiErrorResponseAdapter(e)
    formSubmittedEvent(tracker)({
      formName: track.FormName.REPLACEMENT_PARTS_ORDER_FORM,
      status: track.Status.FAIL,
      errorMessage: formattedError.message || JSON.stringify(e),
    })
    onError(e)
  }

  const { mutate: addToCartMutation, isLoading: addToCartLoading } =
    useMutation({
      mutationFn: addToCart,
      onSuccess: addToCartSucceeded,
      onError: errorHandler,
    })

  const { mutate: checkoutMutation, isLoading: checkoutLoading } = useMutation({
    mutationFn: checkout,
    onSuccess: checkoutSucceeded,
    onError: errorHandler,
  })

  const performCheckout = useCallback(() => {
    const { productId } = checkoutParams
    if (productId === undefined) return

    addToCartMutation({ cartItem: { productId, quantity: 1 } })
  }, [checkoutParams.productId])

  return {
    checkout: performCheckout,
    isLoading: addToCartLoading || checkoutLoading,
    isNavigating,
    setCheckoutParams,
  }
}

export default useCheckout
