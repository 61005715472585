import { useState, useEffect } from 'react'

const windowAvailable = typeof window === 'object'

const getWindowSize = () => ({
  width: windowAvailable ? window.innerWidth : undefined,
  height: windowAvailable ? window.innerHeight : undefined,
})

const useWindowSize = (throttleMs = 100) => {
  const [windowSize, setWindowSize] = useState(getWindowSize)

  useEffect(() => {
    if (!windowAvailable) return

    let throttleTimeout = null
    const handleResize = () => {
      if (!throttleTimeout) {
        throttleTimeout = setTimeout(() => {
          setWindowSize(getWindowSize())
          throttleTimeout = null
        }, throttleMs)
      }
    }

    window.addEventListener('resize', handleResize) // on mount
    return () => {
      clearTimeout(throttleTimeout)
      window.removeEventListener('resize', handleResize) // on unmount
    }
  }, [throttleMs])

  return windowSize
}

export default useWindowSize
