import { useEffect, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'
import { CurrentUser } from '../../../global'

export const CURRENT_USER_LOCAL_STORAGE_KEY = 'currentUser'

/**
 * Custom hook for accessing the current user from localStorage.
 */
const useCurrentUser = (): [
  CurrentUser | undefined,
  (currentUser?: CurrentUser) => void,
] => {
  const [_currentUser, _setCurrentUser] = useLocalStorage<
    CurrentUser | undefined
  >(CURRENT_USER_LOCAL_STORAGE_KEY, undefined)

  // Temporary solution to prevent markup mismatch when rehydrating due to
  // currentUser being immediately present client-side
  // TODO: Once on React 18 use useSyncExternalStore to more reliably hydrate with undefined currentUser
  const [isHydrated, setIsHydrated] = useState<boolean>(false)
  useEffect(() => setIsHydrated(true), [])

  const setCurrentUser = (currentUser?: CurrentUser) => {
    _setCurrentUser(currentUser)

    // Update the currentUser value on the window object as well.
    // In the future we should move to using localStorage exclusively for current user.
    // Once all code directly calling window.currentUser is updated to use localStorage, we can remove this.
    if (typeof window !== 'undefined') {
      window.currentUser = currentUser as CurrentUser
    }
  }

  if (!isHydrated) {
    return [undefined, setCurrentUser]
  }

  return [_currentUser, setCurrentUser]
}

export default useCurrentUser
