import { Factory } from 'fishery'
import faker from 'faker'
import { Shipment } from 'src/types/shipments'

const shipmentFactory = Factory.define<Shipment>(({ sequence }) => ({
  address: {
    name: faker.name.findName(),
    address1: faker.address.streetAddress(),
    address2: faker.address.secondaryAddress(),
    city: faker.address.city(),
    state: faker.address.state(),
    zip: faker.address.zipCode(),
    country: faker.address.country(),
    phone: faker.phone.phoneNumber(),
  },
  consolidationStatus: null,
  description: faker.lorem.sentence(),
  editableUntil: faker.date.future().toISOString(),
  eligibleActions: [],
  id: sequence.toString(),
  itemsCount: 1,
  noticeType: null,
  orders: [
    {
      uuid: faker.random.uuid(),
      reference: faker.random.alphaNumeric(),
      registryId: 1,
      gift: false,
      giftGiver: null,
      items: [
        {
          id: faker.random.uuid(),
          title: faker.commerce.productName(),
          imageUrl: faker.image.imageUrl(),
          productPageUrl: faker.internet.url(),
          quantity: 1,
          productId: 1,
        },
      ],
      shopifyStatusUrl: faker.internet.url(),
    },
  ],
  ordersCount: 1,
  purchasedForRequestingUser: false,
  shipmentStatus: null,
  simpleShippingBundle: false,
  trackingInfos: [],
}))

export default shipmentFactory
