import * as React from 'react'
import Card from 'baby-ui/foundation/Card'
import {
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  LinkProps,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { formatAsUSD } from 'baby-ui/utils'
import useStyles from './EnhancedProductCard.styles'
import RatedReviews from '../RatedReviews'
import Features from './Features'
import ScoredAttributes from './ScoredAttributes'

export interface EnhancedProductCardProps {
  brand: string
  features: string[]
  heroPath: string
  id: number
  imageDescription?: string
  imageUrl: string
  name: string
  shortName: string
  onBodyClick?: LinkProps['onClick']
  price: number
  rating?: number
  reviewCount?: number
  CardAction?: React.ElementType
  scoredAttributes: APIResponse.ScoredAttribute[]

  displayFeatureIndicator?: boolean
  onFeatureIndicatorOpen?: (index?: number) => void
  index?: number
  featureIndicatorProductCardIndex?: number | null
  onFeatureIndicatorClose?: () => void
}

const EnhancedProductCard = ({
  CardAction,
  brand,
  features,
  heroPath,
  id,
  imageUrl,
  imageDescription,
  name,
  shortName,
  onBodyClick,
  price,
  rating,
  reviewCount,
  scoredAttributes,
  displayFeatureIndicator,
  onFeatureIndicatorOpen,
  index,
  featureIndicatorProductCardIndex,
  onFeatureIndicatorClose,
}: EnhancedProductCardProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    if (displayFeatureIndicator) {
      onFeatureIndicatorOpen?.(index)
    }
  }, [displayFeatureIndicator])

  return (
    <Card className={classes.card}>
      <CardActionArea component="a" href={heroPath} onClick={onBodyClick}>
        <Grid container className={classes.imageRatingsContainer} spacing={0}>
          <Grid item xs className={classes.image}>
            <CardMedia
              component="img"
              image={imageUrl}
              title={imageDescription || name}
            />
            <CardContent className={classes.ratings}>
              <RatedReviews rating={rating} reviewCount={reviewCount} />
            </CardContent>
          </Grid>
          <Grid item xs className={classes.cardWidth}>
            <CardContent className={classes.nameFeatures}>
              <Typography color="textSecondary" variant="caption">
                {brand}
              </Typography>
              <Tooltip
                title={
                  isSmallScreen ? (
                    ''
                  ) : (
                    <Typography variant="subtitle2">{name}</Typography>
                  )
                }
              >
                <Typography className={classes.productName} variant="subtitle2">
                  <strong>{shortName}</strong>
                </Typography>
              </Tooltip>
              <Features features={features} />
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
      <ScoredAttributes
        displayFeatureIndicator={
          featureIndicatorProductCardIndex === index ||
          (featureIndicatorProductCardIndex === null && displayFeatureIndicator)
        }
        productId={id}
        scoredAttributes={scoredAttributes}
        onFeatureIndicatorClose={onFeatureIndicatorClose}
      />
      {CardAction && (
        <Grid container className={classes.cardFooter}>
          <Grid item xs className={classes.priceContainer}>
            <CardActionArea
              classes={{
                root: classes.actionArea,
                focusHighlight: classes.focusHighlight,
              }}
              component="a"
              href={heroPath}
              onClick={onBodyClick}
            >
              <Typography color="textSecondary" variant="caption">
                What You Pay
              </Typography>
              <Typography variant="h5">{formatAsUSD(price)}</Typography>
            </CardActionArea>
          </Grid>
          <Grid item xs className={classes.cardWidth}>
            <CardActions className={classes.addToCart}>
              <CardAction />
            </CardActions>
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

export default EnhancedProductCard
