import { Factory } from 'fishery'
import faker from 'faker'
import { Reservation } from 'src/types/reservation'
import { RegItem } from 'src/types/regItem'
import regItemFactory from './regItemFactory'

export const reservationFactory = Factory.define<Reservation>(
  ({ sequence }) => {
    const regItem = regItemFactory.build() as RegItem
    return {
      alert: null,
      bundleComponents: [],
      canCancelOrder: false,
      consolidationMessage: null,
      createdAt: faker.date.past().toISOString(),
      createdBy: null,
      daysSincePurchase: undefined,
      displayTitle: 'Reservation title',
      email: faker.internet.email(),
      giftAmount: 0,
      giftCardOptions: [],
      giftCardPrintableUrl: null,
      giftGiverThankYouAddressProvided: false,
      giftMessage: null,
      id: sequence,
      isActive: true,
      isConsolidated: false,
      isEditable: false,
      isEligibleForRegistryDiscount: false,
      isGroupGiftContribution: false,
      isPurchasableOnBabylist: true,
      isPurchased: false,
      isPurchaseVerified: false,
      isSelfPurchase: false,
      isSurprise: false,
      isSurpriseRevealedForUser: false,
      name: faker.name.findName(),
      orderCancelUntil: undefined,
      orderNumber: null,
      orderUuid: undefined,
      paymentMethod: null,
      paymentMethodKey: null,
      price: 10,
      printableUrl: null,
      productId: 1,
      purchasedAt: faker.date.past().toISOString(),
      quantity: 1,
      regItem,
      regItemId: regItem.id,
      registryId: regItem.registryId,
      returnWebUrl: null,
      returnlyDeepLink: null,
      shippingStatus: undefined,
      storeName: 'Babylist',
      token: undefined,
      trackingInfos: [],
      unpurchasable: false,
      updatedAt: faker.date.past().toISOString(),
      url: undefined,
      userId: 1,
    }
  }
)

export default reservationFactory
