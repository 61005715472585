import { useState } from 'react'
import {
  INSURANCE_NOT_FOUND_TEXT,
  UNSUPPORTED_INSURANCE_PLAN_MODAL,
  UNSUPPORTED_STATE_MODAL,
} from './constants'
import { UnsupportedSelectionDialogProps } from './UnsupportedInsuranceDialog'

const useUnsupportedInsuranceDialog = () => {
  const [dialogProps, setDialogProps] =
    useState<UnsupportedSelectionDialogProps>({
      dialogName: UNSUPPORTED_STATE_MODAL,
      inputValue: '',
      open: false,
    })

  const isUnsupportedState = (
    value: string,
    insuranceCatalog: APIResponse.InsuranceCatalog[]
  ) => {
    const state = insuranceCatalog.find(({ stateName }) => stateName === value)
    return !state?.enabled
  }

  const isUnsupportedProvider = (insuranceProvider: { company: string }) =>
    insuranceProvider.company === INSURANCE_NOT_FOUND_TEXT

  const isDisabledProvider = (insuranceProvider: { enabled: boolean }) =>
    !insuranceProvider.enabled

  const hideDialog = (inputValue: string | undefined = undefined) => {
    setDialogProps({
      ...dialogProps,
      open: false,
      ...(inputValue === undefined ? {} : { inputValue }),
    })
  }

  const showUnsupportedStateDialog = (value: string) => {
    setDialogProps({
      open: true,
      inputValue: value,
      dialogName: UNSUPPORTED_STATE_MODAL,
    })
  }

  const showUnsupportedProviderDialog = (value?: string) => {
    setDialogProps({
      open: true,
      inputValue: value,
      dialogName: UNSUPPORTED_INSURANCE_PLAN_MODAL,
    })
  }

  return {
    dialogProps,
    isUnsupportedState,
    isUnsupportedProvider,
    hideDialog,
    showUnsupportedStateDialog,
    showUnsupportedProviderDialog,
    isDisabledProvider,
  }
}

export default useUnsupportedInsuranceDialog
