import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface EmailCapturedEventMetadata {
  email: string
}

export const emailCapturedEvent =
  (tracker: any) =>
  ({ email }: EmailCapturedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.emailCaptured,
      email,
    })
    tracker.trackEvent(payload)
  }
