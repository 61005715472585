import { OTPRequiredResponse } from 'bl-health/types'

export default function instanceOfOTPRequiredResponse(
  obj: any
): obj is OTPRequiredResponse {
  return (
    obj.otpRequired !== undefined &&
    obj.customerEmail !== undefined &&
    obj.customerId !== undefined
  )
}
