import useStyles from './InsuranceVerificationOverlay.styles'

export const Spinner = () => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.background}>
        <div className={classes.mask} />
      </div>
    </div>
  )
}

export default Spinner
