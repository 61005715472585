import React from 'react'
import useClickAway from 'registry/components/useClickAway/useClickAway'
import {
  // eslint-disable-next-line babylist/no-deprecated-import
  DropdownButton as RBDropdownButton,
  DropdownButtonProps as RBDropdownButtonProps,
} from 'react-bootstrap'

export const DropdownButton = ({
  open = false,
  onClick,
  ...rest
}: RBDropdownButtonProps) => {
  const clickAwayRef = useClickAway<HTMLDivElement>(() => setIsOpen(false))
  const [isOpen, setIsOpen] = React.useState(open)

  return (
    <div ref={clickAwayRef}>
      <RBDropdownButton
        open={isOpen}
        onClick={(e) => {
          setIsOpen(!isOpen)
          onClick?.(e)
        }}
        onSelect={() => setIsOpen(false)}
        {...rest}
      />
    </div>
  )
}
