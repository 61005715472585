import {
  UNSUPPORTED_INSURANCE_PLAN_MODAL,
  UNSUPPORTED_STATE_MODAL,
} from './constants'

export const dialogMap = {
  [UNSUPPORTED_STATE_MODAL]: {
    title:
      "We're working hard to ship to your state, but we're not quite there yet.",
    description:
      "It's likely that you are covered under your insurance! Please contact them to discuss your options.",
  },
  [UNSUPPORTED_INSURANCE_PLAN_MODAL]: {
    titleA: 'Please enter your Insurance Carrier',
    titleB:
      "We're working hard to support your insurance, but we're not quite there yet.",
    description:
      "It's likely that you are covered under your insurance! Please contact them to discuss your options.",
  },
}
