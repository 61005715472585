import { ASYNC_CURRENT_USER_LOADED } from 'shared/constants'
import { CurrentUser } from '../../../global'

export type OnCurrentUserLoadedCallback = (
  currentUser: CurrentUser | undefined
) => unknown

/**
 * Function to register a callback to fire when the current user data is loaded asynchronously.
 * This is useful for when you need to wait until the current user data is loaded asynchronously
 * before firing a callback (page viewed events, etc.).
 *
 * If you are working in a functional react component you probably want useOnCurrentUserLoaded instead.
 */
const onCurrentUserLoaded = (
  callback: OnCurrentUserLoadedCallback
): (() => void) => {
  if (typeof window !== 'undefined') {
    const CurrentUserCallback = (event: any) => callback(event.detail)

    window.addEventListener(ASYNC_CURRENT_USER_LOADED, CurrentUserCallback)

    return () => {
      window.removeEventListener(ASYNC_CURRENT_USER_LOADED, CurrentUserCallback)
    }
  }

  return () => ({})
}

export default onCurrentUserLoaded
