/* eslint-disable no-console */
import queryString from 'query-string'
import { useContext, createElement } from 'react'
import { pick, camelCase, assign, each } from 'lodash'
import tracking, { useTracking } from 'react-tracking'
import Bowser from 'lib/bowser'
import Cookies from 'js-cookie'
import {
  settingsYourOrdersPath,
  registryGiftTrackerPath,
  genericProductsPath,
} from 'lib/urls'
import ReactTrackingContext from 'react-tracking/build/ReactTrackingContext'
import { resetDeprecatedGiftGiverAnonymousId } from '../resetDeprecatedGiftGiverAnonymousId'
import * as transform from './event-props'
import * as Avo from './__generated__/BabylistAvo'
import { FBC_BACKUP_COOKIE_NAME, setRedditClickIdCookie } from './cookies'

/**
 * Extracts and returns the 'last_referrer' from a JSON-formatted string obtained from a cookie.
 *
 * @param {Object} param - Object containing the last referrer data from a cookie.
 * @param {string} [param.last_referrer_data=''] - JSON string of last referrer data from the cookie.
 * @returns {string} Last referrer or an empty string.
 */
const getLastExternalReferrer = (param = {}) => {
  const lastReferrerData = param?.last_referrer_data

  if (!lastReferrerData) {
    return ''
  }

  try {
    return JSON.parse(lastReferrerData)?.last_referrer || ''
  } catch (error) {
    return ''
  }
}

/**
 * extract system props (persistent and send with all event in the app -- do not survive reload / navigate page)
 * UMTs, landingPage, referer
 * UTM special case: extract UTMs from params, store in local session (and cookies) to be restore on each app load
 */
const gatherSystemProperties = () => {
  if (typeof document === 'undefined') return {}
  if (typeof window === 'undefined') return {}
  const utm = {}
  const params = queryString.parse(window.location.search)
  const utmParams = [
    'utm_campaign',
    'utm_medium',
    'utm_source',
    'utm_content',
    'utm_term',
    'utm_coop',
    'utm_group',
  ]
  utmParams.forEach((e) => {
    let value = params[e]

    // Handle case where multiple values are passed for the same UTM parameter
    // Ex. ?utm_campaign=foo&utm_campaign=bar -> utm_campaign = bar
    if (Array.isArray(value)) {
      value = value.pop()
    }

    if (value) {
      utm[camelCase(e)] = value
      localStorage.setItem(e, value)
      Cookies.set(e, value, { expires: 7, path: '/', domain: 'babylist.com' })
    } else {
      // Restore from local storage if present
      const storedValue = localStorage.getItem(e)
      if (storedValue) {
        // Handle existing values in local storage that were stored as arrays
        const lastStoredValue = storedValue.includes(',')
          ? storedValue.split(',').pop()
          : storedValue
        utm[camelCase(e)] = lastStoredValue
      }
    }
  })

  const browser = Bowser.parse(window.navigator.userAgent)
  const platform = `${browser.os.name} ${browser.os.version}`
  const deviceType = browser.platform?.type?.toLowerCase()
  const library = Avo.Library.WEB

  // Looks for existing cookie and grabs it if it exists
  const splitTests = []
  const existingCookies = Cookies.get()
  Object.keys(existingCookies).forEach((cookie) => {
    if (cookie.startsWith('blsplit_')) {
      const cookieData = JSON.parse(existingCookies[cookie])
      const { testName, testVariation } = cookieData
      splitTests.push({
        test_name: testName,
        test_variation: testVariation,
      })
    }
  })

  const blAnonId = Cookies.get('bl_anonymous_id') || ''

  // Storing a backup of fbclid if present as a fallback
  // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
  if (params?.hasOwnProperty?.('fbclid')) {
    Cookies.set(FBC_BACKUP_COOKIE_NAME, params.fbclid, {
      expires: 7,
      path: '/',
      domain: 'babylist.com',
    })
  }

  if (Object.prototype.hasOwnProperty.call(params, 'rdt_cid')) {
    setRedditClickIdCookie(params.rdt_cid)
  }

  const isSynthetic = !!(
    typeof window !== 'undefined' && window._DATADOG_SYNTHETICS_BROWSER
  )

  return {
    ...utm,
    blAnonId,
    deviceType,
    isSynthetic,
    library,
    platform,
    splitTests,
    landingPage: window.location.href,
    referrer: document.referrer,
    lastExternalReferrer: getLastExternalReferrer(existingCookies),
  }
}

// Custom Destination allow us to have a special destination which will be handle here
// Use this destination to track all events to a HTTP bucket while testing
const customAvoLogger = {
  make(env) {
    // call when Avo create the custom destination
    // console.log('[avo/custom] make', env)
  },
  logEvent(eventName, eventProperties) {
    // call when Avo send metrics to custom destination
    // console.log('[avo/custom] logEvent', eventName, eventProperties)
    /* ex.:
    you can send the event to a pipedream bucket to store all events send
    for this session
    fetch(
      `https://bc51f0aeee7fdc79049544cdc0ab1dd6.m.pipedream.net/${eventName}`,
      {
        method: 'post',
        body: JSON.stringify(eventProperties)
      }
    )
    */
  },
  setUserProperties(userId, userProperties) {
    // call when Avo try to set some user props on custom destination
    // console.log('[avo/custom] setUserProperties', userId, userProperties)
    /*
    fetch(
      `https://bc51f0aeee7fdc79049544cdc0ab1dd6.m.pipedream.net/users/${userId}`,
      {
        method: 'put',
        body: JSON.stringify(userProperties)
      }
    );
    */
  },
  identify(userId) {
    // call when Avo identify user on custom destination
    // console.log('[avo/custom] identify', userId)
    /*
    fetch(
      `https://bc51f0aeee7fdc79049544cdc0ab1dd6.m.pipedream.net/identify/${userId}`,
      {
        method: 'post'
      }
    );
    */
  },
  unidentify() {
    // call when Avo unidentify user on custom destination
    // console.log('[avo/custom] unidentify')
    /*
    fetch(
      `https://bc51f0aeee7fdc79049544cdc0ab1dd6.m.pipedream.net/unidentify/${userId}`,
      {
        method: 'post'
      }
    );
    */
  },
}

/**
 * Call this somewhere that it is globally initialized for
 * all react apps or pages that need analytics tracking.
 */
const defaultInitOptions = {
  env: 'dev',
  strict: false,
  inspectorEnv: 'dev',
  systemProperties: {},
  destinationProperties: {},
}

export const initAnalytics = (options) => {
  const {
    systemProperties,
    destinationProperties,
    inspectorEnv,
    branchId,
    appVersion,
    ...restOptions
  } = { ...defaultInitOptions, ...options }

  // Require inspector here because importing the module tries to
  // access `window` which doesn't work in environments like jsdom.
  // In general there is some weird edge cases with importing this module
  // so do some extra testing if you change this (test IE (dev and canary),
  // run yarn test, test canary).
  // eslint-disable-next-line global-require
  const Inspector = require('./inspector').default
  let inspector
  if (!restOptions.noop) {
    inspector = new Inspector.AvoInspector({
      apiKey: Avo.avoInspectorApiKey,
      env: inspectorEnv,
      version: '1.0.0',
      appName: 'Babylist Web',
    })
  }

  // set some custom batch size / flush (in dev)
  // inspector.setBatchSize(0);
  // inspector.setBatchFlushSeconds(1);

  Avo.initAvo(
    {
      ...restOptions,
      inspector,
    },
    { branchId, appVersion, ...systemProperties, ...gatherSystemProperties() },
    destinationProperties,
    customAvoLogger
  )
}

const defaultEventProps = {
  eventLocation: Avo.EventLocation.NONE,
  eventType: Avo.EventType.NONE,
  eventCta: 'None', // this is not a restricted list so no ENUM
}

let userIdentified = false

/**
 * trigger a userIdentified API call IF user HAS NOT already been identified
 * this logic exist to deal with user already logged in before new metrics system in place
 * as they won't do a login event to be associated, we need to do this here before
 * sending any event
 * Also as Segment charges us per event, we want to do it only once...
 * If user log out and then relogin, the identify call will be trigger at login time
 * so it's ok to set cookie forever (1 year)
 */
export const identifyUser = () => {
  if (typeof document === 'undefined') return
  if (typeof window === 'undefined') return

  // Get logged in user ID from cookie
  const userId = Cookies.get('bl_logged_in_user')

  // identity user on analytics (once per year max)
  const userIdentifiedCookieName = 'userIdentifiedEventSent'

  // prevent excessive user identification
  userIdentified = true

  // get currently identified user
  const identifiedUserId = Cookies.get('ajs_user_id')

  // if we have a user do a direct AVO call to identify
  if (
    userId &&
    (Cookies.get(userIdentifiedCookieName) === undefined ||
      userId !== identifiedUserId)
  ) {
    Avo.userIdentified({ userId_: `${userId}` })
    Cookies.set(userIdentifiedCookieName, true, { expires: 365 })
  }
}

/**
 * Set default (global) props
 *
 *
 * eventProperties are available for any event.
 * eventLocation, eventCta and eventType
 * ie.: eventLocation can be set an App level instead of settings it on each event
 *
 * Usage:
 *  1. `import { setDefaultEventProps } from 'lib/analytics'`
 *  2. you can set some default props (at app level) -- already set for registry/cart/home -> `setDefaultEventProps({eventLocation: track.EventLocation.HOME_PAGE})`
 *    - all future event will include `{eventLocation: 'PDP'}`, but can be overriden at tracking time: `track.addToRegistry(eventLocation: 'abc')`
 *    - you can opt out or select default props to be included:
 *      - `track.addToRegistry({blah: '123'}, [])`: will not send any set default
 *      - `track.addToRegistry({blah: '123'}, ['eventLocation'])`: will only add `eventLocation` default
 *
 * @see track
 */
export const setDefaultEventProps = (props) => assign(defaultEventProps, props)

// wrapping Avo in a proxy to handle setting default properties for event..
const trackHandler = {
  get(target, propKey, receiver) {
    try {
      const origMethod = target[propKey]
      if (typeof origMethod === 'function') {
        return function (...args) {
          resetDeprecatedGiftGiverAnonymousId()

          // check if current user have already been identified
          if (!userIdentified) {
            identifyUser()
          }

          // current event props
          const eventProps = args[0]
          // allow track.event({registry}) -> track.event({registryId: 123, registryOwnerId: 123, isRegistryOwner: true})
          const {
            registry,
            product,
            regItem,
            post,
            cartItem,
            user,
            reservation,
            newCartItem,
            cartItems,
            ...rest
          } = eventProps
          // TODO: use deepmerge!
          const spreadedEventProps = {
            // {...{ a: 1 }, ...{ a: undefined }} => { a: undefined}
            // merge({ a: 1 }, { a: undefined }) => { a: 1}
            // we spread the default props for each normalize objects
            ...transform.postEventProps(post),
            ...transform.registryEventProps(registry),
            ...transform.productEventProps(product),
            ...transform.regItemEventProps(regItem),
            ...transform.cartItemsEventProps(cartItems),
            ...transform.cartItemEventProps(cartItem),
            ...transform.newCartItemEventProps(newCartItem), // refactor
            ...transform.reservationEventProps(reservation),
            ...transform.userEventProps(user),
            // we spread the remaining event props
            ...rest,
          }
          // opt-in to inject default props (default behavior)
          const pickedProps = args[1]
          // if opt-out, select only desired default
          const pickedDefaultEventProps =
            pickedProps === undefined
              ? defaultEventProps
              : pick(defaultEventProps, ...pickedProps)
          // extend event props with default
          const extendedEventProps = {
            ...pickedDefaultEventProps,
            ...spreadedEventProps,
          }
          // call original method
          const result = origMethod.apply(this, [extendedEventProps])
          // console.log('[avo/track] ', propKey, extendedEventProps);
          return result
        }
      }
      return origMethod
    } catch (e) {
      console.log('tracking error', e)
      // if error we still returning an empty function...
      return (...args) => {}
    }
  },
}

/**
 * Public interface.
 *
 * Proxy Object around Avo, to handle defaultEventProps
 *
 * @type {Avo}
 * @see setDefaultEventProps
 * @see {@link https://www.avo.app/docs/commands}
 */
// eslint-disable-next-line import/no-mutable-exports
let track
try {
  track = new Proxy(Avo, trackHandler)
} catch (e) {
  // drop proxy if not available (IE <= 11)
  // we "just" loosing some default props
  track = Avo
}

/**
 * dummy tracking
 *
 * Just a safety dummy tracking object to be set as default props
 * Any highly re-used component (like Button, Link) should have this dummyTracking
 * as a default props to ensure not error would be raise if the component is included
 * in an hierarchy where no context have been defined..
 * this will log an error
 *
 */
export const dummyTracking = {
  Track: () => createElement(''),
  trackEvent: () => {},
  getTrackingData: () => ({}),
}

/**
 * withContextualizedTracking
 *
 * Top level metrics HOC (App) which is in charge of dispatching any event
 * to Avo SDK
 *
 * usage:
 * ```
 * class MyApp extend Component {}
 * export default withContextualizedTracking({eventLocation: 'MyApp'})(MyApp)
 * ```
 *
 * @param {props} Hash - top level default props
 */
const withContextualizedTracking = (props = {}) =>
  tracking(props, {
    dispatch: (data) => {
      try {
        const { event, events = [], ...props } = data
        const eventPropsWithUrl = { ...props, eventUrl: window.location.href } // default props on ALL event
        if (event) {
          events.push(event)
        }
        each(events, (event) => {
          if (event === undefined || typeof event !== 'function') {
            return
          }
          event(eventPropsWithUrl)
        })
      } catch (e) {
        console.log('tracking error', e, props)
      }
      // (window.dataLayer = window.dataLayer || []).push(data);
    },

    /*
      // track component mount
      dispatchOnMount: data => {
        console.log('dispatchOnMount', data);
        const { event, dispatchOnMount, ...props } = data;
        // console.log('event', event, props);
        if(dispatchOnMount) {
          console.log('tracking view');
          event(props);
        }
      }
      */

    /*
      // only process event if we have an event
      // https://github.com/nytimes/react-tracking#top-level-optionsprocess
      // todo check if any issue (if no event it cancel in the context of promise?)
      process: (data) => data.event ? data : null
      */
  })

/**
 * @typedef {Object} TrackingMethods
 * @property {React.ComponentType<any>} Track - A React component for tracking
 * @property {() => Object} getTrackingData - A function to get tracking data
 * @property {(data: Object) => void} trackEvent - A function to track events
 */

/**
 * A safe wrapper around the `useTracking` hook from react-tracking.
 * It ensures that tracking operations are always safe to call,
 * even if the tracking context is missing or if an error occurs.
 *
 * @param {Object|Function} [trackingData={}] - Data to be tracked or a function returning that data
 * @param {Object} [options={}] - Optional tracking options
 * @returns {TrackingMethods} An object containing tracking methods
 */
const useSafeTracking = (trackingData = {}, options = {}) => {
  const trackingContext = useContext(ReactTrackingContext)

  if (!trackingContext || !trackingContext.tracking) {
    console.warn(
      'Missing tracking context. Tracking functionality will be disabled.'
    )
    return {
      Track: () => createElement('div'),
      getTrackingData: () => ({}),
      trackEvent: (data) => {
        console.warn(
          'Tracking is disabled due to missing context or error.',
          data
        )
      },
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useTracking(trackingData, options)
}

const isTestEnv = process.env.NODE_ENV === 'test'
// mocking the withContextualizedTracking HOC to do nothing (return the comp)
const Identity = (Component) => Component
// mocking the @tracking decorator to NOT decorate the method
const IdentityTracking = () =>
  function decorator(...rest) {
    if (rest.length === 1) {
      const [cls] = rest
      cls.defaultProps = {
        ...cls.defaultProps,
        tracking: {
          trackEvent: () => {},
        },
      }
      // decorating a class
      return cls
    } // decorating a method
    return rest[0].initializer
  }

const exportedTrack = isTestEnv ? Avo : track
const exportedTracking = isTestEnv ? IdentityTracking : tracking
const exportedUseTracking = isTestEnv ? () => dummyTracking : useSafeTracking
const exportedWithContextualizedTracking = isTestEnv
  ? () => Identity
  : withContextualizedTracking

export {
  exportedTrack as track,
  exportedTracking as tracking,
  exportedUseTracking as useTracking,
  exportedWithContextualizedTracking as withContextualizedTracking,
}

export const getEventLocationFromPath = (pathname) => {
  switch (pathname) {
    case settingsYourOrdersPath:
      return exportedTrack.EventLocation.ORDER_HISTORY
    case registryGiftTrackerPath:
      return exportedTrack.EventLocation.GIFT_TRACKER
    default:
      // eslint-disable-next-line no-case-declarations
      const matcher = new RegExp(`^${genericProductsPath}`)
      if (matcher.test(pathname)) {
        return exportedTrack.EventLocation.PDP
      }
      return exportedTrack.EventLocation.NONE
  }
}
