import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

interface InteractedWithScoredAttributeEventMetadata {
  attribute: string
}

export const interactedWithScoredAttributeEvent =
  (tracker: any) =>
  ({ attribute }: InteractedWithScoredAttributeEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.interactedWithScoredAttribute,
      attribute,
    })
    tracker.trackEvent(payload)
  }
