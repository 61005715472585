import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(() =>
  createStyles({
    babylistHealthStripe: {
      backgroundImage: 'linear-gradient(to right, #00A3C7, #6E3264)',
      height: 10,
    },
  })
)
