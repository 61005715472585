import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface FormSubmittedEventMetadata {
  formName: string
  status: string
  errorMessage?: string
}

export const formSubmittedEvent =
  (tracker: any) =>
  ({ formName, status, errorMessage }: FormSubmittedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.formSubmitted,
      formName,
      status,
      errorMessage,
    })
    tracker.trackEvent(payload)
  }
