import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface ExperimentReachedEventMetadata {
  experimentName: string
  experimentGroup: string
}

export const experimentReachedEvent =
  (tracker: any) =>
  ({ experimentName, experimentGroup }: ExperimentReachedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.experimentReached,
      experimentName,
      experimentGroup,
    })
    tracker.trackEvent(payload)
  }
