export const setExpiringLocalStorage = (
  key: string,
  value: string,
  seconds: number
) => {
  if (typeof localStorage === 'undefined') return

  const expiresAt = new Date(Date.now() + 1000 * seconds)
  localStorage.setItem(key, JSON.stringify({ expiresAt, value }))
}

export const getExpiringLocalStorage = (key: string) => {
  if (typeof localStorage === 'undefined') return null

  const item = localStorage.getItem(key)
  if (!item) return null

  try {
    const object = JSON.parse(item)
    const today = new Date()
    const expireDate = new Date(object.expiresAt)
    if (today > expireDate) {
      localStorage.removeItem(key)
      return null
    }
    return object.value
  } catch (e) {
    return null
  }
}
