import { useEffect, useRef, useState } from 'react'
import { Box, useTheme, Link, Typography } from '@material-ui/core'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { OpenInNewWindowIcon } from 'components/icons'
import Button from 'baby-ui/foundation/Button'
import useStyles from './InsuranceVerificationFailed.styles'
import InsuranceForm, {
  InsuranceFormSuccessCallback,
} from '../../../forms/InsuranceForm/InsuranceForm'
import {
  STORE_NAME,
  HEALTH_CONTACT_EMAIL,
  PUMP_PARTS_STORE_PAGE_URL,
} from '../../../constants'
import { BackIcon } from '../../../icons/BackIcon'
import InsuranceVerificationFailedFactory from './utils/InsuranceVerificationFailedFactory'

interface InsuranceVerificationFailedProps {
  subscriptionId: APIResponse.DME.Subscription['id']
  customerId: APIResponse.Customer['id']
  onSuccess: InsuranceFormSuccessCallback
  error: APIResponse.DME.SubscriptionErrorType
}

const InsuranceVerificationFailed = (
  props: InsuranceVerificationFailedProps
) => {
  const classes = useStyles()
  const theme = useTheme()
  const tracker = useTracking()
  const [insuranceFormVisible, setInsuranceFormVisible] = useState(false)
  const factory = useRef(
    new InsuranceVerificationFailedFactory(props.error)
  ).current

  useEffect(() => {
    tracker.trackEvent({
      event: track.replacementPartsOrderBlockerViewed,
      blockerType: 'INSURANCE_VERIFICATION_FAILED',
      storeName: STORE_NAME,
    })
  }, [])

  return (
    <Box
      bgcolor={theme.palette.background.contrast}
      flexGrow={1}
      pb={8}
      pt={4}
      px={{ xs: 4, sm: 0 }}
    >
      <Box className={classes.container}>
        {insuranceFormVisible ? (
          <>
            <Button
              color="primary"
              startIcon={<BackIcon />}
              style={{ textDecoration: 'underline' }}
              variant="text"
              onClick={() => {
                setInsuranceFormVisible(false)
              }}
            >
              Back
            </Button>
            <InsuranceForm
              customerId={props.customerId}
              subscriptionId={props.subscriptionId}
              onSuccess={props.onSuccess}
            />
          </>
        ) : (
          <>
            <Typography
              className={classes.title}
              color="primary"
              component="h1"
              variant="h4"
            >
              {factory.titleCopy}
            </Typography>
            <Typography
              className={classes.body}
              color="textSecondary"
              variant="body2"
            >
              {factory.bodyCopyFirstLine}
            </Typography>
            <Typography
              className={classes.body}
              color="textSecondary"
              variant="body2"
            >
              {factory.bodyCopySecondLine}
            </Typography>
            <Button
              fullWidth
              color="secondary"
              size="large"
              type="button"
              variant="contained"
              onClick={() => {
                setInsuranceFormVisible(true)
              }}
            >
              {factory.updateInsuranceButtonCopy}
            </Button>

            <ShopForPartsCTA />
            <HelpCTA />
          </>
        )}
      </Box>
    </Box>
  )
}

const ShopForPartsCTA = () => {
  const classes = useStyles()

  return (
    <>
      <Typography
        className={classes.subtitle}
        color="primary"
        component="h1"
        variant="h4"
      >
        Need parts now?
      </Typography>
      <Typography
        className={classes.body}
        color="textSecondary"
        variant="body2"
      >
        We should have what you need on the Babylist Shop.
      </Typography>

      <Button
        color="primary"
        endIcon={<OpenInNewWindowIcon />}
        href={PUMP_PARTS_STORE_PAGE_URL}
        size="large"
        style={{ textDecoration: 'underline', paddingLeft: 0 }}
        variant="text"
      >
        Shop for Pump Parts
      </Button>
    </>
  )
}

const HelpCTA = () => {
  const classes = useStyles()

  return (
    <>
      <Typography
        className={classes.subtitle}
        color="primary"
        component="h1"
        variant="h4"
      >
        We're here to help.
      </Typography>
      <Typography
        className={classes.body}
        color="textSecondary"
        variant="body2"
      >
        Still have questions or think there's been an error? Email{' '}
        <Link
          className={classes.emailLink}
          href={`mailto:${HEALTH_CONTACT_EMAIL}`}
        >
          {HEALTH_CONTACT_EMAIL}
        </Link>
        .
      </Typography>
    </>
  )
}

export default InsuranceVerificationFailed
