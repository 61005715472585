import fetch from 'lib/fetch'
import qs from 'query-string'
import { PHYSICIAN_SEARCH_ENDPOINT } from './constants'

export interface PhysicianSearchParams {
  firstName: string
  lastName: string
  stateAbbr: string
  city: string
}

export default async function searchPhysician(
  searchParams: PhysicianSearchParams
): Promise<APIResponse.DME.Physician[]> {
  const query = qs.stringify(searchParams)
  return fetch(PHYSICIAN_SEARCH_ENDPOINT(query))
}
