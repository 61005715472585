import { useEffect, useState } from 'react'
import { Box, Drawer, Grid, Typography } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { useScrollDirection, SCROLL_UP, SCROLL_DOWN } from 'lib/hooks'
import {
  setExpiringLocalStorage,
  getExpiringLocalStorage,
} from 'baby-ui/utils/expireLocalStorage'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import useStyles from './EdgeDrawerEmailWidget.styles'
import EmailForm from '../../forms/EmailForm/EmailForm'
import EmailFormConfirmation from '../../forms/EmailForm/EmailFormConfirmation'
import {
  EMAIL_COLLECTION_VIEWED_KEY,
  EMAIL_COLLECTION_VIEWED_EXPIRATION_SECONDS,
  EMAIL_COLLECTED_KEY,
  SUBMIT_DELAY,
  SCROLL_UP_THRESHOLD_PIXELS,
  SCROLL_DOWN_THRESHOLD_PIXELS,
  SCROLL_DOWN_DELAY,
} from './EdgeDrawerEmailWidget.constants'
import { emailCapturedEvent } from '../../events/emailCapturedEvent'
import { emailCaptureViewedEvent } from '../../events/emailCaptureViewedEvent'
import { bannerClickedEvent } from '../../events/bannerClickedEvent'

export const EdgeDrawerEmailWidget = () => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [visible, setVisible] = useState(false)
  const emailCollected = localStorage.getItem(EMAIL_COLLECTED_KEY)
  const emailCollectionViewed = getExpiringLocalStorage(
    EMAIL_COLLECTION_VIEWED_KEY
  )
  const classes = useStyles()
  const scrollDirection = useScrollDirection({
    initialDirection: SCROLL_DOWN,
    thresholdPixels: SCROLL_UP_THRESHOLD_PIXELS,
    off: false,
  })

  const handleScroll = () => {
    const position = window.pageYOffset
    if (position > SCROLL_DOWN_THRESHOLD_PIXELS) {
      setVisible(true)
    }
  }

  useEffect(() => {
    // Adding delay to prevent scroll event from firing on page load when page is auto scrolled to the top
    setTimeout(() => {
      window.addEventListener('scroll', handleScroll)
    }, SCROLL_DOWN_DELAY)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const setEmailCollectionViewed = () => {
    setExpiringLocalStorage(
      EMAIL_COLLECTION_VIEWED_KEY,
      'true',
      EMAIL_COLLECTION_VIEWED_EXPIRATION_SECONDS
    )
  }

  const setEmailCollected = () => {
    localStorage.setItem(EMAIL_COLLECTED_KEY, 'true')
  }
  const tracker = useTracking()

  const closeDrawer = () => {
    setOpen(false)
  }

  const openDrawer = () => {
    setOpen(true)
    setEmailCollectionViewed()
    emailCaptureViewedEvent(tracker)()
  }

  const clickToOpenDrawer = () => {
    bannerClickedEvent(tracker)({
      bannerName: track.BannerName.PLP_MOBILE_EMAIL_COLLECTION,
    })
    openDrawer()
  }

  const submitEmail = ({ email }: { email: string }) => {
    setIsLoading(true)
    // Adding time delay so there is a transition to the confirmation screen
    setTimeout(() => {
      completeEmailCapture(email)
    }, SUBMIT_DELAY)
  }

  const completeEmailCapture = (email: string) => {
    setIsLoading(false)
    setShowConfirmation(true)
    setEmailCollected()
    emailCapturedEvent(tracker)({ email })
  }

  if (
    scrollDirection === SCROLL_UP &&
    !open &&
    !emailCollectionViewed &&
    !emailCollected &&
    visible
  ) {
    openDrawer()
  }

  if (!visible) {
    return null
  }

  return (
    <>
      <Drawer
        ModalProps={{
          keepMounted: true,
        }}
        anchor="bottom"
        open={open}
        onClose={closeDrawer}
      >
        <Box className={classes.drawerContent} p={2} pb={4}>
          <Box className={classes.closeIcon}>
            {showConfirmation ? (
              <CloseIcon color="primary" onClick={closeDrawer} />
            ) : (
              <ExpandMoreIcon color="primary" onClick={closeDrawer} />
            )}
          </Box>
          {showConfirmation ? (
            <EmailFormConfirmation
              cta="Back to Browse Pumps"
              onClick={closeDrawer}
            />
          ) : (
            <EmailForm isLoading={isLoading} onSubmit={submitEmail} />
          )}
        </Box>
      </Drawer>
      {!emailCollected && (
        <Box
          className={classes.fixedBanner}
          pb={3}
          pt={1}
          px={2}
          onClick={clickToOpenDrawer}
        >
          <Grid container className={classes.bannerTextContainer}>
            <Grid item>
              <Typography variant="subtitle2">
                Not ready to decide today?
              </Typography>
            </Grid>
            <Grid item className={classes.helpText}>
              <Typography variant="body2">We'd </Typography>
              <FavoriteIcon className={classes.heartIcon} color="secondary" />
              <Typography variant="body2"> to help</Typography>
              <ExpandLessIcon />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default EdgeDrawerEmailWidget
