import { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Divider,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Radio from '@material-ui/core/Radio'
import Image from 'components/image'
import { useMutation } from '@tanstack/react-query'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import {
  errorThrownEvent,
  milkBagsOfferedEvent,
  preCheckoutModalCompletedEvent,
} from 'bl-health/events'
import { useLayout } from 'bl-health/widgets/Layout'
import useStyles from '../styles'
import PhysicianCard from '../PhysicianCard'
import { useDMEStore } from '../../../store'
import { PhysicianModelType } from '../../../store/models/PhysicianModel'
import { checkout, createCustomerPhysician } from '../../../requests'

import { apiErrorResponseAdapter } from '../../../utils'
import PhysicianDetailsForm from '../PhysicianDetailsForm'
import PhysicianDetailsModal from '../PhysicianDetailsModal'
import PreCheckoutWidget from '../../../widgets/PreCheckoutWidget/PreCheckoutWidget'
import PreCheckoutMilkBagWidget from '../../../widgets/PreCheckoutWidget/PreCheckoutMilkBagWidget'

import PhysicianPhoneNumberModal from '../PhysicianPhoneNumberModal'
import useMilkBagsCartAddOn from './useMilkBagsCartAddOn'

export interface PhysicianSelectorWidgetProps {
  goToPreviousPage: () => void
}

const PhysicianSelectorWidget = observer(
  ({ goToPreviousPage }: PhysicianSelectorWidgetProps) => {
    const store = useDMEStore()
    const classes = useStyles()
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { flagValue: wyntkShopifyFeatureFlag } = useFeatureFlag(
      '24e_ff_wyntk_shopify',
      false
    )

    const [showPhysicianDetailsModal, setShowPhysicianDetailsModal] =
      useState(false)
    const [showPhysicianPhoneNumberModal, setShowPhysicianPhoneNumberModal] =
      useState(false)
    const [noDoctor, setNoDoctor] = useState(false)
    const [isNavigating, setIsNavigating] = useState(false)
    const [showPreCheckoutModal, setShowPreCheckoutModal] = useState(false)
    const [showPreCheckoutMilkBagModal, setShowPreCheckoutMilkBagModal] =
      useState(false)
    const milkBagsCartAddOn = useMilkBagsCartAddOn()

    useEffect(() => () => setIsNavigating(false), [])

    const selectPhysician = (physician: PhysicianModelType) => {
      store.physician.select(physician)
      setNoDoctor(false)
    }

    const deselectPhysician = () => {
      store.physician.deselect()
      setNoDoctor(true)
    }

    const checkoutSucceeded = ({ checkoutPageUrl }: APIResponse.Checkout) => {
      setIsNavigating(true)
      window.location.href = checkoutPageUrl
    }

    const tracker = useTracking()

    const mutationFailed = (error: any) => {
      const adaptedError = apiErrorResponseAdapter(error)
      errorThrownEvent(tracker)({
        error: adaptedError.message,
      })
      store.ui.globalComponents.Snackbar.show(adaptedError.message)
    }

    const goToPreCheckout = () => {
      if (store.context.inPartsFlow) {
        if (layout?.milkBagsForPartsBuyers) {
          goToPreCheckoutMilkBagOrCheckout()
        } else {
          fireCheckout()
        }
      } else if (wyntkShopifyFeatureFlag) {
        goToPreCheckoutMilkBagOrCheckout()
      } else {
        setShowPreCheckoutModal(true)
      }
    }

    const goToPreCheckoutMilkBagOrCheckout = () => {
      if (milkBagsCartAddOn.addOn) {
        setShowPreCheckoutMilkBagModal(true)
      } else {
        fireCheckout()
      }
    }

    const { mutate: createPhysicianMutation, isLoading: phyIsLoading } =
      useMutation({
        mutationFn: createCustomerPhysician,
        onError: mutationFailed,
        onSuccess: goToPreCheckout,
      })

    const { mutate: _checkoutMutation, isLoading: checkoutIsLoading } =
      useMutation({
        mutationFn: checkout,
        onSuccess: checkoutSucceeded,
        onError: mutationFailed,
      })

    const firePreCheckoutModalCompletedEvent = () => {
      preCheckoutModalCompletedEvent(tracker)({
        freeGiftSelected: store.cart.checkoutPayload.includeFreeGift,
        milkBagsSelected: store.cart.checkoutPayload.includeMilkBags,
        manualReviewRequired: store.insurance.manualReviewRequired,
      })
    }

    const fireCheckout = () => {
      firePreCheckoutModalCompletedEvent()
      checkoutMutation()
    }

    const checkoutMutation = () => {
      const addOns = milkBagsCartAddOn.addOn
        ? [milkBagsCartAddOn.addOn]
        : undefined
      _checkoutMutation({
        ...store.cart.checkoutPayload,
        addOns: store.cart.includeMilkBags ? addOns : undefined,
      })
    }

    const layout = useLayout()

    const handleCheckoutClick = () => {
      if (!store.physician.selected) {
        setShowPhysicianDetailsModal(true)
      } else if (store.physician.selected.missingContactInfo) {
        setShowPhysicianPhoneNumberModal(true)
      } else {
        createPhysicianMutation({
          npi: store.physician.selected.npi,
          npiLastUpdatedAt: store.physician.selected.lastUpdatedEpoch,
          hasRx: store.user.hasRx,
        })
      }
    }

    const isLoading =
      (phyIsLoading || checkoutIsLoading || isNavigating) &&
      !showPhysicianDetailsModal &&
      !showPreCheckoutModal &&
      !showPhysicianPhoneNumberModal

    return (
      <div>
        {store.physician.all && store.physician.all.length > 0 ? (
          <div>
            <Typography gutterBottom variant="h6">
              Results for{' '}
              <Typography component="span" variant="body2">
                {store.physician.searchInputDisplayText}
              </Typography>
            </Typography>
            <Typography
              color="textSecondary"
              component="span"
              style={{
                display: 'flex',
                alignContent: 'baseline',
              }}
              variant="body2"
            >
              Made a mistake?&nbsp;
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                component="button"
                variant="body2"
                onClick={goToPreviousPage}
              >
                Go back
              </Link>
            </Typography>

            <ul className={classes.doctorList}>
              {store.physician.all?.map((physician, i) => (
                <Fragment key={physician.npi}>
                  {1 % (i + 1) === 1 && <Divider component="li" />}
                  <li className={classes.selectableDoctor}>
                    <Radio
                      checked={store.physician.selected?.npi === physician.npi}
                      className={classes.radioButton}
                      onChange={() => selectPhysician(physician)}
                    />
                    <PhysicianCard
                      addressLine1={physician.displayAddress.line1}
                      addressLine2={physician.displayAddress.line2}
                      name={physician.displayName}
                      onClick={() => selectPhysician(physician)}
                    />
                  </li>
                </Fragment>
              ))}
              <Divider component="li" />
              <li className={classes.selectableDoctor}>
                <Radio
                  checked={noDoctor}
                  className={classes.radioButton}
                  onChange={deselectPhysician}
                />
                <PhysicianCard
                  isDefault
                  addressLine1="No worries! We will still be able to reach out to your provider for a prescription."
                  addressLine2=""
                  name={"I don't see my care provider"}
                  onClick={deselectPhysician}
                />
              </li>
            </ul>
            <LoadingButton
              fullWidth
              className={classes.checkoutBttn}
              color="primary"
              loading={isLoading}
              variant="contained"
              onClick={handleCheckoutClick}
            >
              Continue
            </LoadingButton>

            <PhysicianDetailsModal
              nextAction={goToPreCheckout}
              open={showPhysicianDetailsModal}
              onClose={() => setShowPhysicianDetailsModal(false)}
            />

            <PhysicianPhoneNumberModal
              nextAction={goToPreCheckout}
              open={showPhysicianPhoneNumberModal}
              onClose={() => setShowPhysicianPhoneNumberModal(false)}
            />
          </div>
        ) : (
          <div className={classes.noResultsWrapper}>
            {isSmallScreen ? (
              <Image
                height={55}
                src="//images.babylist.com/image/upload/v1684877396/DME/small_stethoscope.svg"
              />
            ) : (
              <Image
                height={72}
                src="//images.babylist.com/image/upload/v1668642427/DME/stethoscope.svg"
              />
            )}
            <Typography
              gutterBottom
              className={classes.subHeader}
              color="textPrimary"
              component="p"
              variant="h6"
            >
              We need a little more info to reach out to your provider for a
              prescription!
            </Typography>
            <PhysicianDetailsForm nextAction={goToPreCheckout} />
          </div>
        )}
        <PreCheckoutWidget
          finalCheckoutLoading={checkoutIsLoading}
          open={showPreCheckoutModal}
          onConfirm={goToPreCheckoutMilkBagOrCheckout}
        />
        <PreCheckoutMilkBagWidget
          finalCheckoutLoading={checkoutIsLoading}
          milkBagsCount={milkBagsCartAddOn.unitCount}
          open={showPreCheckoutMilkBagModal}
          onConfirm={fireCheckout}
          onShow={() => {
            milkBagsOfferedEvent(tracker)({
              location: store.context.inPumpFlow
                ? track.EventLocation.PUMPS
                : track.EventLocation.PARTS_WITH_NO_PUMP_ORDER,
            })
          }}
        />
      </div>
    )
  }
)

export default PhysicianSelectorWidget
