import { memo } from 'react'
import PropTypes from 'prop-types'
import { track, useTracking } from 'lib/analytics'
import {
  trackReviewTeaserQnaQuickLink,
  trackReviewTeaserReviewsQuickLink,
} from 'src/routes/(shop)/lib/tracking'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import ProductContentSection from '../product-details-page/product-content-section'
import { TurnToSnippet, updateSku } from './turnto-snippet'

const setupTracking = ({ trackEvent }) => {
  if (typeof window !== 'undefined') {
    window.trackReviewTeaserReviewsQuickLink =
      trackReviewTeaserReviewsQuickLink(trackEvent)
    window.trackReviewTeaserQnaQuickLink =
      trackReviewTeaserQnaQuickLink(trackEvent)
  }
}

// ReviewTeaser will only work on the same page as the ProductReviews component (PDP)
export const ReviewTeaser = memo(({ sku }) => {
  if (!sku) return null
  updateSku(sku)
  setupTracking(useTracking())

  return <div id="tt-teaser-widget" style={{ minHeight: '28px' }} />
})

ReviewTeaser.propTypes = { sku: PropTypes.string.isRequired }

export const ProductReviews = memo(({ sku, productSkus }) => {
  if (!sku) return null
  updateSku(sku)

  return (
    <>
      <TurnToSnippet gallerySkus={productSkus} pageId="pdp-page" sku={sku} />
      <div
        id="qa-scroll-element"
        style={{ marginTop: '-120px', position: 'absolute' }}
        // ^ the element that will be scrolled to when you click "x Answered Questions" in the teaser
        // marginTop: '-120px' to account for the 100px PreviewBar
      />
      <ProductContentSection title="Q&A">
        <div className="container">
          <div id="tt-instant-answers-widget" />
          <div id="tt-qa-list" />
        </div>
      </ProductContentSection>
      <div
        id="reviews-scroll-element"
        style={{ marginTop: '-120px', position: 'absolute' }}
        // ^ the element that will be scrolled to when you click "x Reviews" in the teaser
        // marginTop: '-120px' to account for the 100px PreviewBar
      />
      <ProductContentSection title="Customer reviews">
        <div className="container">
          <div id="tt-reviews-summary" />
          <div id="tt-gallery-row" />
          <div id="tt-reviews-list" />
        </div>
      </ProductContentSection>
    </>
  )
})

ProductReviews.propTypes = {
  sku: PropTypes.string.isRequired,
  productSkus: PropTypes.arrayOf(PropTypes.string),
}

ProductReviews.defaultProps = {
  productSkus: [],
}
