import { createStyles, makeStyles } from '@material-ui/core'
import { HERO_MASK, HeroImageUrl } from '../../views/constants'

export default makeStyles((theme) =>
  createStyles({
    heroImage: {
      height: 250,
      backgroundImage: (heroImageUrl: HeroImageUrl) =>
        `url('${heroImageUrl.MOBILE}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      maskImage: `url('${HERO_MASK}')`,
      maskRepeat: 'no-repeat',
      maskPosition: 'bottom center',
      maskSize: 'cover',
      [theme.breakpoints.up('sm')]: {
        height: 605,
        backgroundImage: (heroImageUrl: HeroImageUrl) =>
          `url('${heroImageUrl.DESKTOP}')`,
      },
      [theme.breakpoints.up('xl')]: {
        backgroundImage: (heroImageUrl: HeroImageUrl) =>
          `url('${heroImageUrl.WIDE}')`,
      },
    },
    heroImageWrapper: {
      [theme.breakpoints.down('xs')]: {
        backgroundColor: theme.palette.background.contrast,
      },
    },
  })
)
