import React from 'react'
import { STORE_PATH } from 'bl-health/constants'
import { productPreviewRemoveButtonClickedEvent } from 'bl-health/events'
import { useTracking } from 'lib/babylistHealthAnalytics'
import css from './ProductPreviewer.styles.scss'
import Button from '../../../baby-design/components/Button/Button'

interface ProductPreviewerProps {
  id: number
  brand: string
  imgUrl: string
  name: string
  price: string
}

const ProductPreviewer: React.FC<ProductPreviewerProps> = ({
  id,
  brand,
  imgUrl,
  name,
  price,
}) => {
  const tracker = useTracking()

  const handleOnRemoveClick = () => {
    productPreviewRemoveButtonClickedEvent(tracker)({
      productId: id,
      productName: name,
    })
    window.location.assign(STORE_PATH)
  }

  return (
    <div className={css.ProductPreviewer}>
      <div className={css.ProductPreviewer__content}>
        <div className={css.ProductPreviewer__details}>
          <div className={css.ProductPreviewer__thumbnail}>
            <img
              alt={name}
              className={css.ProductPreviewer__product_img}
              src={imgUrl}
            />
          </div>

          <div className={css.ProductPreviewer__info}>
            <p className={css.ProductPreviewer__product_brand}>{brand}</p>
            <p className={css.ProductPreviewer__product_name}>{name}</p>
            <p className={css.ProductPreviewer__product_price}>{price}</p>
          </div>
        </div>

        <div>
          <Button size="sm" variant="primary" onClick={handleOnRemoveClick}>
            Remove
          </Button>
        </div>
      </div>
    </div>
  )
}
export default ProductPreviewer
