interface AddOnMetadata {
  count: number
  productId: number
}

export interface CartAddOn {
  name: 'milk-bag'
  metadata: AddOnMetadata
}

export type CartAddOnsResponse = CartAddOn[]
