import { Instance, types } from 'mobx-state-tree'

const SnackbarStateModel = types
  .model({
    open: types.optional(types.boolean, false),
    message: types.maybeNull(types.string),
    severity: types.maybeNull(
      types.enumeration('Severity', ['info', 'success', 'warning', 'error'])
    ),
  })
  .actions((self) => {
    function show(
      message: string,
      severity: Instance<typeof SnackbarStateModel>['severity'] = null
    ) {
      self.open = true
      self.message = message
      self.severity = severity
    }

    function close() {
      self.open = false
    }

    return { show, close }
  })

export default SnackbarStateModel
export type SnackbarStateModelType = Instance<typeof SnackbarStateModel>
