import { useState } from 'react'
import { ComposableComponent } from 'baby-ui/types'
import classnames from 'classnames'
import { useSwipeable } from 'react-swipeable'
import useStyles from './styles'

export type CarouselProps<T> = ComposableComponent<T>

export default function Carousel<T = any>({
  component: Component,
  data,
  every,
}: CarouselProps<T>) {
  const [activeIndex, setActiveIndex] = useState(0)
  const classes = useStyles()
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setActiveIndex(Math.min(activeIndex + 1, data.length - 1))
    },
    onSwipedRight: () => {
      setActiveIndex(Math.max(activeIndex - 1, 0))
    },
  })

  return (
    <section aria-label="carousel" {...handlers}>
      <div className={classes.carouselWrapper}>
        <div className={classes.previewWrapper}>
          <ul className={classes.listRoot}>
            {data.map((datum, i) => {
              const { key, ...localProps } = every(datum, i)
              return (
                <li
                  aria-label={`slide ${i + 1} of ${data.length}`}
                  key={key}
                  className={classes.previewItem}
                >
                  <Component
                    {...datum}
                    {...localProps}
                    onClick={() => setActiveIndex(i)}
                  />
                </li>
              )
            })}
          </ul>
        </div>
        <div className={classes.contentGroupWrapper}>
          <div
            role="group"
            className={classnames(classes.listRoot, classes.contentGroup)}
            style={{ transform: `translateX(-${activeIndex * 100}%)` }}
          >
            {data.map((datum, i) => {
              const { key, ...localProps } = every(datum, i)

              return (
                <div
                  aria-label={`slide ${i + 1} of ${data.length}`}
                  className={classes.contentItem}
                  key={key}
                >
                  <Component {...datum} {...localProps} />
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <ul className={classnames(classes.listRoot, classes.actionGroup)}>
        {data.map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={i}>
            <button
              tabIndex={-1}
              type="button"
              className={classnames(classes.actionItem, {
                [classes.activeItem]: activeIndex === i,
              })}
              onClick={() => setActiveIndex(i)}
            >
              <span className={classes.srOnly}>
                go to slide {activeIndex + 1}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </section>
  )
}
