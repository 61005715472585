import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface FormFieldClickedMetadata {
  formName: string
  formFieldName: string
}

export const formFieldClickedEvent =
  (tracker: any) =>
  ({ formName, formFieldName }: FormFieldClickedMetadata) => {
    const payload = decorateEventPayload({
      event: track.formFieldClicked,
      formName,
      formFieldName,
    })
    tracker.trackEvent(payload)
  }
