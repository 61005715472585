import { withContextualizedTracking } from 'lib/babylistHealthAnalytics'
import PDPView from 'bl-health/views/PDPView'
import { LayoutContainer, LayoutPresenter } from 'bl-health/widgets/Layout'
import { ApplicationProps } from 'bl-health/types'

export interface DMEProductPageProps extends ApplicationProps {
  pdpProps: APIResponse.GenericProduct[]
}

const DMEProductPage = ({
  pdpProps,
  settings,
  context,
}: DMEProductPageProps) => (
  <LayoutContainer context={context} settings={settings}>
    <LayoutPresenter>
      <PDPView pdpProps={pdpProps} />
    </LayoutPresenter>
  </LayoutContainer>
)

export default withContextualizedTracking()(DMEProductPage)
