import Cookies from 'js-cookie'

export function setLastCartItemAdded(uuid: string) {
  Cookies.set('last_cart_item_added', uuid)
}

export function getLastCartItemAdded() {
  const id = Cookies.get('last_cart_item_added')
  return id || undefined
}
