const HEALTH_PREFIX = '/health'

/**
 * This is temporary while we migrate from health.babylist.com to babylist.com/health.
 * We need both URLs to serve the Health app, so our routing should keep the user on
 * whatever URL they're using. Once we deprecate health.babylist.com and redirect
 * it to babylist.com/health, all routes can be set to `/health` and there will be no
 * need to inspect the window location.
 */
const prefixedRoute = (route: string) => {
  if (typeof window === 'undefined') return route

  const { pathname } = window.location
  const isHealthPath =
    pathname === HEALTH_PREFIX || pathname.startsWith(`${HEALTH_PREFIX}/`)

  if (isHealthPath) {
    return route === '/' ? HEALTH_PREFIX : HEALTH_PREFIX + route
  }

  return route
}

export default prefixedRoute
