import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    listRoot: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },
    contentGroupWrapper: {
      margin: 'auto',
      overflow: 'hidden',
    },
    contentGroup: {
      [theme.breakpoints.only('xs')]: {
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.short,
          easing: theme.transitions.easing.easeInOut,
        }),
      },
      whiteSpace: 'nowrap',
    },
    actionGroup: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    contentItem: {
      alignItems: 'center',
      display: 'inline-flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
    actionItem: {
      transition: theme.transitions.create('background', {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeOut,
      }),
      background:
        theme.palette.type === 'dark'
          ? theme.palette.grey[600]
          : theme.palette.grey[400],
      border: 'none',
      borderRadius: '50%',
      height: 12,
      margin: theme.spacing(),
      padding: 0,
      width: 12,
      cursor: 'pointer',
    },
    srOnly: theme.mixins.screenReadersOnly,
    activeItem: {
      background:
        theme.palette.type === 'dark'
          ? theme.palette.grey[400]
          : theme.palette.grey[600],
    },
    previewWrapper: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        marginRight: theme.spacing(2),
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    previewItem: {
      border: '1px solid',
      borderColor: theme.palette.grey[200],
      marginBottom: theme.spacing(1),
      width: 50,
      height: 50,
    },
    carouselWrapper: {
      display: 'flex',
    },
  })
)
