import fetch from 'lib/fetch'
import { ApplicationStrategy } from 'bl-health/types'
import customerInformationInputsToCustomerPayloadAdapter from '../forms/CustomerInformationForm/customerInformationInputsToCustomerPayloadAdapter'
import { RequestMethod } from './RequestMethod'
import { CUSTOMER_ENDPOINT } from './constants'

export type CustomerPayload = ReturnType<
  typeof customerInformationInputsToCustomerPayloadAdapter
> & { strategy: ApplicationStrategy }

export default function createOrUpdateCustomer(
  payload: CustomerPayload
): Promise<APIResponse.Customer> {
  return fetch(CUSTOMER_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })
}
