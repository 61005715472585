import { Instance, types } from 'mobx-state-tree'

const MEDEQUIP = 'medequip'

const InsuranceStateModel = types
  .model({
    catalogCategoryId: types.maybeNull(types.number),
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    requiresPrescription: types.optional(types.boolean, false),
    state: types.maybeNull(types.string),
    shippingEligibilityDays: types.optional(types.maybeNull(types.number), 30),
    billingProvider: types.maybeNull(types.string),
    manualReviewRequired: types.maybeNull(types.boolean),
  })
  .views((self) => ({
    get present() {
      return !!self.id && !!self.catalogCategoryId
    },
    get billingProviderDisplayName() {
      if (self.billingProvider === MEDEQUIP) {
        return "Medequip Inc., a member of Babylist's Preferred Durable Medical Equipment Network,"
      }

      return 'Babylist Health LLC'
    },
    get shippingDateMessage() {
      if (self.shippingEligibilityDays === null) {
        return ''
      }

      if (self.shippingEligibilityDays < 0) {
        const shippingDaysAbs = Math.abs(self.shippingEligibilityDays)
        return `Ships ${shippingDaysAbs} days after your due date`
      }

      return `Ships ${self.shippingEligibilityDays} days prior to due date`
    },
  }))
  .actions((self) => {
    function set(
      data: APIResponse.Insurance & Pick<InsuranceStateModelType, 'state'>
    ) {
      self.catalogCategoryId = data.catalogCategoryId
      self.id = data.id
      self.name = data.company
      self.requiresPrescription = data.prescriptionRequired
      self.state = data.state
      self.shippingEligibilityDays = data.shippingEligibilityDays
      self.billingProvider = data.billingProvider
      self.manualReviewRequired = data.manualReviewRequired
    }

    function saveState(name: string) {
      self.state = name
      localStorage.setItem('state_name', name)
    }

    function setManualReviewRequired(value: boolean) {
      self.manualReviewRequired = value
    }

    function setShippingEligibilityDays(value: number | null) {
      self.shippingEligibilityDays = value
    }

    return {
      set,
      saveState,
      setManualReviewRequired,
      setShippingEligibilityDays,
    }
  })

export default InsuranceStateModel
export type InsuranceStateModelType = Instance<typeof InsuranceStateModel>
