import { Typography, TypographyProps } from '@material-ui/core'
import { formatAsUSD } from 'baby-ui/utils'

export interface PriceDisplayProps {
  /**
   * The number value of the price
   */
  price: number
  /**
   * Typography variant
   */
  variant?: TypographyProps['variant']
  /**
   * Upgrade fee caption that shows next to the price
   * @default undefined
   */
  label?: string
}

export default function PriceDisplay({
  price,
  variant,
  label,
}: PriceDisplayProps) {
  return (
    <Typography variant={variant} component="p">
      <strong>{formatAsUSD(price)}</strong>
      {label && (
        <Typography variant="caption" component="span">
          {' ' + label}
        </Typography>
      )}
    </Typography>
  )
}
