import { GuideCardProps } from 'baby-ui/compounds/GuideCard'
import { Guide } from '../../../global'

export default function guidesToGuideCardPropsAdapter(
  guides: Guide[]
): GuideCardProps[] {
  return guides.map((guide) => ({
    alt: guide.title,
    description: guide.teaser,
    href: guide.postUrl,
    imageUrl: guide.imageUrl,
    title: guide.title,
  }))
}
