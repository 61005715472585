import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    illustratedStepsWrapper: {
      backgroundColor: theme.palette.background.contrast,
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  })
)
