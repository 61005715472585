import { dehydrate, Hydrate, QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from 'src/queryClient'

function withReactQueryClientProvider<P extends Record<any, any>>(
  WrappedComponent: React.ComponentType<P>
): React.FC<P> {
  return function WithReactQueryClientProvider(props: P) {
    return (
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydrate(queryClient)}>
          <WrappedComponent queryClient={queryClient} {...(props as P)} />
        </Hydrate>
      </QueryClientProvider>
    )
  }
}

export default withReactQueryClientProvider
