import BabyUIColorPalette from 'baby-ui/lib/BabyUIColorPalette'
import { ComponentsProps } from '@material-ui/core/styles/props'
import BabyUIPaletteColorNode from 'baby-ui/lib/BabyUIPaletteColorNode'
import { TypeBackground } from '@material-ui/core/styles/createPalette'
import { Shape } from '@material-ui/core/styles/shape'

export enum PaletteMode {
  Light,
  Dark,
}

export interface BabyUIThemeOptions {
  /**
   * @default Light
   */
  paletteMode?: PaletteMode
}

export default abstract class BabyUITheme {
  public readonly isDarkModeEnabled: boolean

  public readonly AUTO_HIDE_DURATION = 6 * 1000 // 6s

  public readonly buttonRadius: number = 20

  public readonly borderRadius: number = 4

  constructor({ paletteMode }: BabyUIThemeOptions = {}) {
    this.isDarkModeEnabled = paletteMode === PaletteMode.Dark
  }

  public getColorPalette(): BabyUIColorPalette {
    return new BabyUIColorPalette(
      this.getPrimaryColor(),
      this.getSecondaryColor(),
      this.getBackgroundColor()
    )
  }

  public getPropsOverrides(): Partial<ComponentsProps> {
    return {
      MuiAppBar: { elevation: 0 },
      MuiButton: { size: 'medium', disableElevation: true },
      MuiButtonBase: { disableRipple: true },
      MuiCard: { elevation: 0 },
      MuiSnackbar: {
        autoHideDuration: this.AUTO_HIDE_DURATION,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
    }
  }

  public listClearStyle() {
    return { listStyleType: 'none', margin: 0, padding: 0 }
  }

  public screenReadersOnlyStyle() {
    return {
      borderWidth: 0,
      clip: 'rect(0, 0, 0, 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: 1,
    }
  }

  public getTypographyStyles() {
    return {
      fontFamily: ['Maax', 'Helvetica Light', 'Arial', 'sans-serif'].join(','),
      htmlFontSize: this.getHTMLFontSize(),
    }
  }

  public getShape(): Shape {
    return { buttonRadius: this.buttonRadius, borderRadius: this.borderRadius }
  }

  protected abstract getHTMLFontSize(): number

  protected abstract getPrimaryColor(): BabyUIPaletteColorNode

  protected abstract getSecondaryColor(): BabyUIPaletteColorNode

  protected abstract getBackgroundColor(): Partial<TypeBackground>
}
