import { Grid, Typography } from '@material-ui/core'
import { uniqueId } from 'lodash'
import useStyles from './styles'

interface Step {
  /**
   * Description for the image for accessibility and SEO.
   */
  alt: string
  /**
   * Text display next to the image.
   */
  content: string
  /**
   * The location of the image.
   */
  imageUrl: string
  title?: string
}

export interface IllustratedStepsProps {
  /**
   * A list containing the image url and text content for each step.
   */
  steps: Step[]
  /**
   * Title to show above all steps.
   */
  title: string
  /**
   * subtitle to show below the title
   */
  subtitle: string
}

export default function IllustratedSteps({
  steps,
  title,
  subtitle,
}: IllustratedStepsProps) {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography
        className={classes.title}
        color="primary"
        component="h1"
        variant="h4"
      >
        {title}
        <br />
        {subtitle}
      </Typography>

      <Grid container direction="row" justifyContent="center" spacing={4}>
        {steps.map((step) => {
          const id = uniqueId(IllustratedSteps.name)
          return (
            <Grid
              item
              className={classes.step}
              key={step.content}
              sm={4}
              xs={12}
            >
              <img alt={step.alt} aria-labelledby={id} src={step.imageUrl} />
              <div>
                <Typography className={classes.stepTitle} component="h3">
                  {step.title}
                </Typography>

                <Typography
                  className={classes.stepContent}
                  component="div"
                  id={id}
                  variant="body1"
                >
                  {step.content}
                </Typography>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
