import Cookies from 'js-cookie'

export const FBC_BACKUP_COOKIE_NAME = 'fbclid_bl_backup'
export const REDDIT_UUID_COOKIE_NAME = '_rdt_uuid'

// '_rdt_cid' is stored by the Reddit pixel.
// The click ID comes through the landing query string and we manually store it
// in 'bl_rdt_cid'. This is a fallback if the Reddit pixel is blocked.
export const BL_REDDIT_CLICK_ID_COOKIE_NAME = 'bl_rdt_cid'
export const REDDIT_CLICK_ID_COOKIE_NAME = '_rdt_cid'

// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
export const getFBPCookie = (): string | null => {
  try {
    return Cookies.get('_fbp') || null
  } catch (e) {
    return null
  }
}

export const getFBCCookie = (): string | null => {
  try {
    const cookie = Cookies.get('_fbc')
    if (!cookie) {
      return Cookies.get(FBC_BACKUP_COOKIE_NAME) || null
    }
    return cookie
  } catch (e) {
    return null
  }
}

export const setRedditClickIdCookie = (value: string): void => {
  try {
    Cookies.set(BL_REDDIT_CLICK_ID_COOKIE_NAME, value, {
      path: '/',
      domain: 'babylist.com',
    })
  } catch (e) {
    // do nothing
  }
}

export const getRedditClickIdCookie = (): string | null => {
  try {
    return (
      Cookies.get(BL_REDDIT_CLICK_ID_COOKIE_NAME) ||
      Cookies.get(REDDIT_CLICK_ID_COOKIE_NAME) ||
      null
    )
  } catch (e) {
    return null
  }
}

export const getRedditUUIDCookie = (): string | null => {
  try {
    return Cookies.get(REDDIT_UUID_COOKIE_NAME) || null
  } catch (e) {
    return null
  }
}
