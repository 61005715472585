import eventEmitter from 'event-emitter'
const ee = eventEmitter()

const _PubSub = {
  publish: ee.emit,
  subscribe: ee.on,
  unsubscribe: ee.off,
}

export function shared() {
  if (typeof window !== 'undefined') {
    if (typeof window.PubSub === 'undefined') {
      window.PubSub = _PubSub
    }
    return window.PubSub
  }
  return _PubSub
}
