import { withContextualizedTracking } from 'lib/babylistHealthAnalytics'
import { LayoutContainer, LayoutPresenter } from 'bl-health/widgets/Layout'
import PLPView from 'bl-health/views/PLPView'
import { ApplicationProps } from 'bl-health/types'

const DMECatalogPage = ({ settings, context }: ApplicationProps) => (
  <LayoutContainer context={context} settings={settings}>
    <LayoutPresenter>
      <PLPView />
    </LayoutPresenter>
  </LayoutContainer>
)

export default withContextualizedTracking()(DMECatalogPage)
