import { useState } from 'react'
import { Typography } from '@material-ui/core'
import Banner from 'baby-ui/foundation/Banner/Banner'
import Link from 'baby-ui/foundation/Link/Link'
import { ReplacementPartsModal } from './components'
import useStyles from './styles'

export const ReplacementPartsBanner = () => {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)
  const onClick = () => setDialogOpen(true)

  return (
    <>
      <ReplacementPartsModal
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <Link className={classes.linkWrapper} underline="none" onClick={onClick}>
        <Banner>
          <Typography style={{ textAlign: 'center' }}>
            <span className={classes.freePump}>
              Order your pump and get <b>FREE</b> replacement parts once baby
              has arrived!
            </span>
            &nbsp;
            <span className={classes.exclusionsApply}>Exclusions apply.</span>
          </Typography>
        </Banner>
      </Link>
    </>
  )
}
