import { createStyles, makeStyles } from '@material-ui/core'

interface BannerStyleProps {
  adornmentUrl?: string
}

export default makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      background: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    contentRoot: {
      position: 'relative',
      paddingTop: theme.spacing(2.8),
      paddingBottom: theme.spacing(2.8),
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    content: {
      textAlign: 'center',
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'left',
      },
    },
    contentPaddingLeft: {
      paddingLeft: 152,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 116,
      },
    },
    contentPaddingRight: {
      paddingRight: 152,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 116,
      },
    },
    adornment: {
      position: 'absolute',
      height: 136,
      width: 136,
      backgroundImage: ({ adornmentUrl }: BannerStyleProps) =>
        `url('${adornmentUrl}')`,
      backgroundSize: 136,
      backgroundRepeat: 'no-repeat',
      flexShrink: 0,
      bottom: -81,
      [theme.breakpoints.down('xs')]: {
        backgroundSize: 108,
        height: 108,
        width: 108,
        bottom: -64,
      },
    },
    adornmentLeftAligned: {
      left: 0,
    },
    adornmentRightAligned: {
      right: 0,
    },
    pointerCursor: {
      cursor: 'pointer',
    },
  })
)
