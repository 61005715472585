import {
  Backdrop,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import useStyles from './InsuranceVerificationOverlay.styles'
import { useDMEStore } from '../../store'
import Arch from './shapes/Arch'
import Burst from './shapes/Burst'
import Clover from './shapes/Clover'
import Vector from './shapes/Vector'
import Spinner from './Spinner'

export const InsuranceVerificationOverlay = observer(() => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmallScreen = !useMediaQuery(theme.breakpoints.up('sm'))
  const {
    ui: { globalComponents },
  } = useDMEStore()
  const open =
    globalComponents.Overlay.open &&
    globalComponents.Overlay.name === 'insuranceVerification'

  return (
    <Backdrop className={classes.overlay} open={open}>
      <div className={classes.contentContainer}>
        <div>
          <Typography className={classes.heading} color="primary" variant="h3">
            We're verifying your insurance is active and in our network!
          </Typography>
          <Typography color="primary" variant="h5">
            This should take less than a minute.
          </Typography>
        </div>
        <Box mb={12}>
          <Spinner />
        </Box>
      </div>
      {isSmallScreen && (
        <>
          <Arch />
          <Burst />
          <Vector />
          <Clover />
        </>
      )}
    </Backdrop>
  )
})

export default InsuranceVerificationOverlay
