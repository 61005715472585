import { FC } from 'react'
import { createRootStore, DMEStoreProvider } from 'bl-health/store'

const LegacyMobXWrapper: FC = ({ children }) => {
  const rootStore = createRootStore()

  return <DMEStoreProvider value={rootStore}>{children}</DMEStoreProvider>
}

export default LegacyMobXWrapper
