import PropTypes from 'prop-types'
import classnames from 'classnames'

// Use for heading large sections of content
// ex. "Natalie's Registry", "Cart", "Reservations"
export const SectionHeading = ({ style, className, children, id = '' }) => {
  // Pass null to component so id doesn't render with empty string
  // This patterns allows TS's type inference to still see id as a string prop
  const nullSafedId = id || null

  return (
    <h1 style={style} className={className} id={nullSafedId}>
      {children}
    </h1>
  )
}

// Use for heading *nested* sections of content below a SectionHeading
// ex. "Cash Funds", "Feeding", "Diapering" (below "Natalie's Registry")
export const SubHeading = ({ style, className, children }) => (
  <h2 style={style} className={className}>
    {children}
  </h2>
)

export const StyledSubHeading = ({ style, className, children }) => (
  <SubHeading style={style} className={classnames('h4 mvl pbm bbs', className)}>
    {children}
  </SubHeading>
)

export const CollapsibleSubHeading = ({
  style,
  className,
  children,
  collapsed,
}) => (
  <SubHeading
    style={style}
    className={classnames(
      'tiny text-bold text-muted text-uppercase mvl pbm bbs flex',
      className
    )}
  >
    <span className="flex grow">{children}</span>
    <span className="">
      <i
        className={classnames(
          { 'rotate-90-neg': collapsed },
          'fa fa-chevron-down h7'
        )}
      />
    </span>
  </SubHeading>
)

SectionHeading.propTypes = {
  style: PropTypes.object, // @TODO add style propType validation
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

StyledSubHeading.propTypes = SectionHeading.propTypes
SubHeading.propTypes = SectionHeading.propTypes

SectionHeading.defaultProps = {
  style: {},
  className: '',
}

StyledSubHeading.defaultProps = SectionHeading.defaultProps
SubHeading.defaultProps = SectionHeading.defaultProps

CollapsibleSubHeading.propTypes = {
  ...SectionHeading.propTypes,
  collapsed: PropTypes.bool,
}

CollapsibleSubHeading.defaultProps = {
  ...SectionHeading.defaultProps,
  collapsed: false,
}
