import { track } from 'lib/babylistHealthAnalytics'
import { BRAND_INDEX, FEATURES_INDEX } from 'baby-ui/shared/constants'
import { decorateEventPayload } from './decorateEventPayload'

export interface FiltersAppliedEventMetadata {
  filters: Record<string, string[]>
  sortOrder: string
  sortCriteria: string
}

export const filtersAppliedEvent =
  (tracker: any) =>
  ({ filters, sortOrder, sortCriteria }: FiltersAppliedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.filtersApplied,
      filters: JSON.stringify(filters),
      sortOrder,
      sortCriteria,
      brands: filters[BRAND_INDEX],
      features: filters[FEATURES_INDEX],
      included: filters.included,
    })
    tracker.trackEvent(payload)
  }
