import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import { useProductFilter } from 'baby-ui/hooks/useProductFilter'
import DisplayGrid from 'baby-ui/compounds/DisplayGrid'
import { useIsScrolling } from 'baby-ui/hooks/useIsScrolling'
import { getCatalogCategory } from '../../requests'
import ProductCardSkeleton from './ProductCardSkeleton'
import { apiErrorResponseAdapter } from '../../utils'
import catalogCategoryToProductCardAdapter from './catalogCategoryToProductCardAdapter'
import CardActionWidget from './CardActionWidget'
import EnhancedProductFilterWidget from '../EnhancedProductFilterWidget/EnhancedProductFilterWidget'
import { productClickedEvent } from '../../events/productClickedEvent'
import EmailCollectionWidget from '../EmailCollectionWidget/EmailCollectionWidget'
import { useLayout } from '../Layout'
import { ProductCardWidget } from './ProductCardWidget'
import { useDMEStore } from '../../store'
import browserHistory from '../../utils/browserHistory'
import * as productFilterSearchParams from '../../utils/productFilterSearchParams'

export interface ProductListingWidgetProps {
  catalogId: number | string
}

const ProductListingWidget = observer(
  ({ catalogId }: ProductListingWidgetProps) => {
    const tracker = useTracking()
    const { data, isLoading, error } = useQuery<
      APIResponse.CatalogCategory,
      APIResponse.StandardError
    >({
      queryKey: ['pumpCatalogCategory', catalogId],
      queryFn: () => getCatalogCategory(catalogId),
      refetchOnWindowFocus: false,
    })
    const productData = data?.data.heroProducts || []
    const currentLocation = window.location
    const initialSettings = productFilterSearchParams.parse(
      currentLocation.search
    )
    const [products, { allProducts, applyFilter, applySort }] =
      useProductFilter(productData, {
        initialSettings,
        onChange: (settings) => {
          const search = `?${productFilterSearchParams.stringify(settings)}`
          // set the browser history to the current search params
          browserHistory.replace({
            ...currentLocation,
            search,
          })
        },
      })
    const displayProducts = catalogCategoryToProductCardAdapter(products)
    const hasProducts = displayProducts.length > 0

    const adaptedError = error ? apiErrorResponseAdapter(error) : null
    const theme = useTheme()
    const layout = useLayout()
    const plpEmailCollectionEnabled = layout?.isPlpEmailCollectionEnabled
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
    const [_, { scrollStatus }] = useIsScrolling({ listenDelay: 500 })
    const [
      featureIndicatorProductCardIndex,
      setFeatureIndicatorProductCardIndex,
    ] = useState<number | null>(null)
    const store = useDMEStore()

    if (isLoading) {
      return (
        <Box pt={4}>
          <DisplayGrid
            breakpoints={{ xs: 12, sm: 6, md: 4 }}
            component={ProductCardSkeleton}
            data={new Array(8).fill({})}
            every={(_, i) => ({ key: `ProductListingWidget:skeleton:${i}` })}
          />
        </Box>
      )
    }

    const onFeatureIndicatorClose = () => {
      setFeatureIndicatorProductCardIndex(-1)
      store.ui.sawFeatureIndicator()
    }

    const onFeatureIndicatorOpen = (index: number) => {
      if (featureIndicatorProductCardIndex === null) {
        setFeatureIndicatorProductCardIndex(index as number)
      }
    }

    function fireProductClickedEvent(
      productId: number,
      productPrice: number,
      rank: number
    ) {
      productClickedEvent(tracker)({
        location: track.EventLocation.STORE_MINUS_PRODUCT_LIST,
        productId,
        price: productPrice,
        rank,
      })
    }

    return (
      <Box mb={12}>
        <Container>
          {adaptedError?.message && (
            <Alert severity="error" variant="standard">
              {adaptedError.message}
            </Alert>
          )}
        </Container>

        <EnhancedProductFilterWidget
          allProducts={allProducts}
          applyFilter={applyFilter}
          applySort={applySort}
          initialSettings={initialSettings}
        />

        {hasProducts ? (
          <DisplayGrid
            breakpoints={{ xs: 12, sm: 6, md: 4 }}
            component={ProductCardWidget}
            data={displayProducts}
            every={({ id, price }, i) => ({
              featureIndicatorProductCardIndex,
              scrollStatus,
              onFeatureIndicatorClose,
              onFeatureIndicatorOpen,
              index: i,
              onBodyClick: () => {
                fireProductClickedEvent(id, price, i + 1)
              },
              key: id,
              CardAction: () => (
                <CardActionWidget cta="ADD TO CART" product={{ id, price }} />
              ),
            })}
          />
        ) : (
          <Container>
            <Box mx="auto" pt={12} textAlign="center">
              <Typography variant="h6">
                We wish we had what you're looking for.
              </Typography>
              <Box>
                <Typography>
                  Clear your filters to see all our amazing breast pump options.
                </Typography>
              </Box>
            </Box>
          </Container>
        )}
        {plpEmailCollectionEnabled && isLargeScreen && (
          <Container>
            <Box pt={12}>
              <EmailCollectionWidget />
            </Box>
          </Container>
        )}
      </Box>
    )
  }
)

ProductListingWidget.displayName = 'ProductListingWidget'

export default ProductListingWidget
