import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    filterPanelWrapper: {
      zIndex: theme.zIndex.modal,
      display: 'flex',
      backgroundColor: theme.palette.background.default,
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: `${theme.palette.divider} 1px solid`,
      position: 'sticky',
      width: '100%',
      top: 0,
      '& $icon': {
        marginRight: -theme.spacing(2),
      },
    },
    icon: {},
  })
)
