import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface ProductClickedEventMetadata {
  location: string
  productId: number
  rank: number
  price: number
}

export const productClickedEvent =
  (tracker: any) =>
  ({ location, productId, rank, price }: ProductClickedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.productClicked,
      location,
      productIds: [productId],
      productPrice: price,
      productType: track.ProductType.PRODUCT,
      rank,
    })
    tracker.trackEvent(payload)
  }
