import GuideCard from 'baby-ui/compounds/GuideCard'
import DisplayGrid from 'baby-ui/compounds/DisplayGrid'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import guidesToGuideCardPropsAdapter from './guidesToGuideCardPropsAdapter'
import { GuidesViewProps } from '../../views/GuidesView'
import { STORE_NAME } from '../../constants'

export interface GuidesWidgetProps extends Pick<GuidesViewProps, 'guides'> {
  numGuides?: number
  openGuidesInNewTab?: boolean
}

export default function GuidesWidget({
  guides,
  openGuidesInNewTab = false,
  numGuides,
}: GuidesWidgetProps) {
  const tracker = useTracking()

  function fireGuideClickedEvent(url: string) {
    tracker.trackEvent({
      event: track.guideClicked,
      postId: 'N/A',
      postType: 'Guide',
      postRevision: 0,
      postSlug: url,
      storeName: STORE_NAME,
    })
  }

  return (
    <>
      <DisplayGrid
        disableContainer
        breakpoints={{ xs: 12, md: 6 }}
        component={GuideCard}
        data={guidesToGuideCardPropsAdapter(
          numGuides ? guides.slice(0, numGuides) : guides
        )}
        every={({ href }) => ({
          linkTarget: openGuidesInNewTab ? '_blank' : undefined,
          key: href,
          onClick: () => {
            fireGuideClickedEvent(href)
          },
        })}
      />
    </>
  )
}
