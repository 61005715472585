import { Typography, Box } from '@material-ui/core'
import Link from 'baby-ui/foundation/Link/Link'

interface EmailConfirmationProps {
  email: string
  onUpdateEmail(): void
}
export const EmailConfirmation = ({
  email,
  onUpdateEmail,
}: EmailConfirmationProps) => (
  <>
    <Box mb={1}>
      <Typography color="primary" component="h1" variant="h4">
        Check your email inbox.
      </Typography>
    </Box>
    <Typography color="textPrimary" variant="body1">
      We sent a link to order your free parts to: <b>{email}</b>
    </Typography>
    <Link
      style={{ cursor: 'pointer' }}
      underline="always"
      onClick={onUpdateEmail}
    >
      Update email
    </Link>
    <Box mb={1} mt={4}>
      <Typography color="primary" component="h1" variant="h5">
        Need help?
      </Typography>
    </Box>
    <Typography color="textPrimary" variant="body2">
      If you have questions or think there's been an error, email us at{' '}
      <Link href="mailto:health@babylist.com" underline="always">
        health@babylist.com
      </Link>
    </Typography>
  </>
)
