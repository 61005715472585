import { useQuery } from '@tanstack/react-query'
import { getProductReplacementParts } from 'bl-health/requests'

export default function useProductReplacementParts(productId?: number) {
  const productPartsResponse = useQuery({
    queryKey: ['getProductReplacementParts', productId],
    queryFn: () => getProductReplacementParts(productId as number),
    refetchOnWindowFocus: false,
    enabled: !!productId,
  })
  const data = productPartsResponse.data?.data
    .map((d) => d.products || d.availableProducts)
    .flat()

  return {
    data,
    isFetching: productPartsResponse.isFetching,
  }
}
