import * as React from 'react'
import { IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useStyles from './PanelHeader.styles'

export interface PanelHeaderProps {
  children: React.ReactNode
  onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const PanelHeader = ({ children, onClose }: PanelHeaderProps) => {
  const classes = useStyles()
  return (
    <div className={classes.filterPanelWrapper}>
      <Typography component="h2" variant="h6">
        {children}
      </Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.icon}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  )
}

export default PanelHeader
