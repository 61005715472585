import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface PreCheckoutModalCompletedEventMetadata {
  freeGiftSelected: boolean
  milkBagsSelected: boolean
  manualReviewRequired: boolean | null
}

export const preCheckoutModalCompletedEvent =
  (tracker: any) =>
  ({
    freeGiftSelected,
    milkBagsSelected,
    manualReviewRequired,
  }: PreCheckoutModalCompletedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.dmePreCheckoutModalCompleted,
      freeGiftSelected,
      milkBagsSelected,
      manualReviewRequired,
    })
    tracker.trackEvent(payload)
  }
