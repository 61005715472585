import Bowser from 'bowser'

const bowser =
  typeof window === 'undefined'
    ? undefined
    : Bowser.parse(window.navigator?.userAgent)
const bowserFlags = {
  babylistApp:
    bowser?.os.name === 'iOS' ||
    bowser?.os.name === 'Android' ||
    browserName === 'blwebbrowser',
  mobile: bowser?.platform?.type === 'mobile',
  tablet: bowser?.platform?.type === 'tablet',
  android: bowser?.os.name === 'Android',
  ios: bowser?.os.name === 'iOS',
  ipad: bowser?.os.name === 'iOS' && bowser?.platform?.type === 'tablet',
  windows: bowser?.os.name === 'Windows',
}
export const isBot = bowser?.platform?.type === 'bot'
const browserName = bowser?.browser?.name
export { Bowser as default, browserName, bowserFlags }
