import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import TextField from 'baby-ui/foundation/TextField'
import Button from 'baby-ui/foundation/Button'

const formDefaultValues = { insuranceProviderName: '' }

export interface InsuranceNameFormValues {
  insuranceProviderName: string
}

export interface InsuranceNameFormProps {
  onSubmit: (formValues: InsuranceNameFormValues) => void
}

export default function InsuranceNameForm({
  onSubmit,
}: InsuranceNameFormProps) {
  const { handleSubmit, control } = useForm({
    defaultValues: formDefaultValues,
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            fullWidth
            required
            as={TextField}
            control={control}
            label="Insurance Carrier"
            name="insuranceProviderName"
            rules={{ required: true }}
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <Button fullWidth color="primary" type="submit" variant="contained">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
