import * as blUrls from 'lib/urls'
import { CONFIG } from 'shared/constants'
import {
  HOME_PATH,
  PARTS_HOME_PATH,
  PRIVACY_URL,
  TERMS_AND_CONDITIONS_PATH,
} from '../../constants'

const WHAT_INSURANCE_COVERS_SECTION = {
  heading: 'What Insurance Covers',
  href: HOME_PATH,
  links: [
    {
      href: HOME_PATH,
      name: 'Breast Pumps',
    },
    {
      href: PARTS_HOME_PATH,
      name: 'Breast Pump Replacement Parts',
    },
  ],
}

const BREAST_PUMP_GUIDES_SECTION = {
  heading: 'Breast Pump Guides',
  href: blUrls.babylistUrl(blUrls.helloBabyPath),
  links: [
    {
      href: blUrls.babylistUrl(blUrls.helloBabyPostPath('best-breast-pump')),
      name: 'Best Breast Pumps',
    },
    {
      href: blUrls.babylistUrl(blUrls.helloBabyPostPath('breast-pump-parts')),
      name: 'Babylist Gear Glossary: Breast Pumps',
    },
    {
      href: blUrls.babylistUrl(
        blUrls.helloBabyPostPath('breast-pumps-for-every-budget')
      ),
      name: 'Best Breast Pump for Every Budget',
    },
    {
      href: blUrls.babylistUrl(
        blUrls.helloBabyPostPath('best-wearable-breast-pumps')
      ),
      name: 'Best Wearable Breast Pumps',
    },
    {
      href: blUrls.babylistUrl(
        blUrls.helloBabyPostPath('how-to-pump-breast-milk')
      ),
      name: 'How To Pump Breast Milk',
    },
    {
      href: blUrls.babylistUrl(
        blUrls.helloBabyPostPath('how-to-store-breast-milk')
      ),
      name: 'How To Store Breast Milk',
    },
    {
      href: blUrls.babylistUrl(
        blUrls.helloBabyPostPath('flying-with-breast-milk')
      ),
      name: 'Flying With Breast Milk',
    },
    {
      href: blUrls.babylistUrl(
        blUrls.helloBabyPostPath('best-bottles-for-breastfed-babies')
      ),
      name: 'Best Bottles for Breastfed Babies',
    },
    {
      href: blUrls.babylistUrl(
        blUrls.helloBabyPostPath('best-breast-pump-bags')
      ),
      name: 'Best Breast Pump Bags',
    },
  ],
}

const ABOUT_SECTION = {
  heading: 'About Babylist Health',
  href: HOME_PATH,
  links: [
    { name: 'Our Story', href: blUrls.babylistUrl(blUrls.aboutPath) },
    {
      name: 'Babylist Health Advisory Board',
      href: blUrls.babylistUrl(blUrls.healthAdvisoryBoardPath),
    },
    { name: 'Terms of Use', href: TERMS_AND_CONDITIONS_PATH },
    { name: 'Privacy Policy', href: PRIVACY_URL, target: '_blank' },
    { name: 'Cookie Notice', href: blUrls.babylistUrl(blUrls.cookiesPath) },
    { name: 'CCPA Notice', href: blUrls.babylistUrl(blUrls.ccpaPath) },
    { name: 'Sitemap', href: blUrls.babylistUrl(blUrls.seoSitemapPath) },
  ],
}

const REGISTRY_SECTION = {
  heading: 'Babylist Registry',
  href: blUrls.babylistUrl(blUrls.howToCreateABabyRegistryPath),
  links: [
    {
      name: 'Why Babylist',
      href: blUrls.babylistUrl(blUrls.whyBabyListPath),
    },
    { name: 'Find a Registry', href: blUrls.babylistUrl(blUrls.findPath) },
    {
      name: 'Create a Registry',
      href: blUrls.babylistUrl(blUrls.newRegistryPath),
    },
    {
      name: 'Baby Registry Checklist',
      href: blUrls.babylistUrl(blUrls.checklistPath),
    },
    {
      name: `${CONFIG.store.registryDiscountPctDisplay} Registry Discount`,
      href: blUrls.babylistUrl(blUrls.registryDiscountPath),
    },
  ],
}

export const FooterNav = [
  WHAT_INSURANCE_COVERS_SECTION,
  BREAST_PUMP_GUIDES_SECTION,
  ABOUT_SECTION,
  REGISTRY_SECTION,
]
