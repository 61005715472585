import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    optionSubheader: {
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.text.primary,
    },
  })
)
