import qs from 'query-string'

export default function inDebugMode() {
  if (typeof window !== 'undefined') {
    const { search } = window.location
    const searchObj = qs.parse(search)
    return searchObj.bl_debug == 1
  }

  return false
}
