import React from 'react'
import classNames from 'classnames'

import css from './Button.styles.scss'

import { ButtonProps } from './Button.types'
import LoadingDots from '../LoadingDots/LoadingDots'

const Button: React.FC<ButtonProps> = ({
  block = false,
  variant,
  size,
  loading = false,
  icon,
  className,
  children,
  onClick = null,
  href,
  ...props
}) => {
  const buttonClasses = classNames(
    css.button,
    css[`button--style-${variant}`],
    css[`button--size-${size}`],
    icon?.size ? css[`button--icon-${icon.size}`] : null,
    {
      [css.button__left_icon]: icon?.side === 'left',
      [css.button__right_icon]: icon?.side === 'right',
      [css['button--loading']]: loading,
      [css.button__block]: block,
    },
    className
  )

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.currentTarget.blur()
    if (onClick) {
      onClick(
        e as React.MouseEvent<HTMLButtonElement> &
          React.MouseEvent<HTMLAnchorElement>
      )
    }
  }

  const content = (
    <>
      {loading && (
        <div className={css.button__loading_dots_wrapper}>
          <LoadingDots className={css.button__loading_dots} />
        </div>
      )}
      <div aria-hidden={loading} className={css.button__wrapper}>
        {icon?.side === 'left' && icon.icon}
        <div className={css.button__label}>{children}</div>
        {icon?.side === 'right' && icon.icon}
      </div>
    </>
  )

  if (href) {
    const anchorProps = {
      ...props,
    } as React.AnchorHTMLAttributes<HTMLAnchorElement>
    return (
      <a
        className={buttonClasses}
        data-testid="button"
        href={href}
        onClick={handleClick}
        {...anchorProps}
      >
        {content}
      </a>
    )
  }
  const buttonProps = {
    ...props,
  } as React.ButtonHTMLAttributes<HTMLButtonElement>
  return (
    <button
      className={buttonClasses}
      data-testid="button"
      type="button"
      onClick={handleClick}
      {...buttonProps}
    >
      {content}
    </button>
  )
}

export default Button
