import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import Mailcheck from 'mailcheck'
import TextField from 'baby-ui/foundation/TextField'
import styles from './mailchecked-input.module.scss'

const MailcheckedInput = ({ mailcheckDelay = 0, onChange, ...otherProps }) => {
  const [emailSuggestion, setEmailSuggestion] = useState(null)

  const debouncedSetSuggestion = useCallback(
    debounce(setEmailSuggestion, mailcheckDelay),
    [mailcheckDelay]
  )

  const clearSuggestion = () => {
    debouncedSetSuggestion(null)
  }

  const mailcheck = (email) => {
    const handleNoSuggestion = () => {
      debouncedSetSuggestion.cancel()
      clearSuggestion()
    }

    const regex = /@[a-zA-Z0-9_-]{0,}\.[a-zA-Z0-9_-]{1,}/
    const shouldMailcheck = regex.test(email)
    if (!shouldMailcheck) {
      handleNoSuggestion()
      return
    }

    Mailcheck.run({
      email,
      suggested: (suggestion) => {
        const { domain, full } = suggestion

        const canadaRegex = /\.ca$/
        const isCanadaDomain = canadaRegex.test(email)
        if (isCanadaDomain) {
          debouncedSetSuggestion(suggestion)
        } else {
          const noCanadaSuggestion = {
            ...suggestion,
            domain: domain.replace(canadaRegex, '.com'),
            full: full.replace(canadaRegex, '.com'),
          }
          debouncedSetSuggestion(noCanadaSuggestion)
        }
      },
      empty: handleNoSuggestion,
    })
  }

  const handleChange = (e) => {
    onChange(e)
    mailcheck(e.target.value)
  }

  const handleBlur = (e) => {
    mailcheck(e.target.value)
  }

  const changeEmail = (email) => {
    onChange(email)
    clearSuggestion()
  }

  const helpText = emailSuggestion && (
    <div className={styles.suggestionContainer}>
      <span>
        Did you mean <strong>{emailSuggestion.full}</strong>?
      </span>
      <button
        className={styles.suggestionButton}
        type="button"
        onClick={() => changeEmail(emailSuggestion.full)}
      >
        Yes
      </button>
    </div>
  )

  return (
    <TextField
      noValidate
      FormHelperTextProps={{
        style: {
          marginLeft: '0px',
          marginRight: '0px',
        },
      }}
      helperText={helpText}
      onBlur={handleBlur}
      onChange={handleChange}
      {...otherProps}
    />
  )
}

MailcheckedInput.propTypes = {
  mailcheckDelay: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

MailcheckedInput.defaultProps = {
  mailcheckDelay: 0,
}

export default MailcheckedInput
