import {
  UNIFIED_SEARCH_POST_SEARCH_ID_KEY,
  UNIFIED_SEARCH_PRODUCT_SEARCH_ID_KEY,
  UNIFIED_SEARCH_REGISTRY_SEARCH_ID_KEY,
  UNIFIED_SEARCH_SUGGESTION_SEARCH_ID_KEY,
} from 'shared/constants'

export type SearchEvent = {
  event_name: string
  properties: {
    event_type: string
    event_location: string
    search_term: string
    search_id?: string
  }
}

export enum ResultType {
  TYPE_AHEAD_SUGGESTION = 'type_ahead_suggestion',
  SECTION_HEADING = 'section_heading',
  SUGGESTED_RESULT_TEXT = 'suggested_result_text',
  HEADING = 'heading',
  AD_BANNER = 'ad_banner',
  TEXT = 'text',
}

export type SearchPreviewIdKey =
  | typeof UNIFIED_SEARCH_POST_SEARCH_ID_KEY
  | typeof UNIFIED_SEARCH_PRODUCT_SEARCH_ID_KEY
  | typeof UNIFIED_SEARCH_REGISTRY_SEARCH_ID_KEY
  | typeof UNIFIED_SEARCH_SUGGESTION_SEARCH_ID_KEY

export type SearchPreviewIds = {
  [key in SearchPreviewIdKey]?: string
}

export type GenericResult = {
  title: string
  url: string
  type: ResultType
  search_preview_ids?: SearchPreviewIds
  tracking_event?: SearchEvent
}

export type SectionHeading = GenericResult & {
  cta: string
}

export type AdBanner = Pick<
  GenericResult,
  'url' | 'type' | 'tracking_event'
> & {
  cta: string
  text: string
}

export type TypeAheadSuggestion = GenericResult & {
  query: string
}

export type Heading = Pick<SectionHeading, 'title' | 'type'>

export type Text = {
  text: string
  type: ResultType
}

export type SearchResult =
  | GenericResult
  | TypeAheadSuggestion
  | SectionHeading
  | Heading
  | Text
  | AdBanner
