/* google tags
Info on remarketing => https://support.google.com/google-ads/answer/2476688?hl=en
Info on conversion tracking => https://support.google.com/tagmanager/answer/6105160?hl=en
*/
export const gtagEvents = {
  // adds visitors to remarketing lists to target ads to them later
  addRemarketing: (product) =>
    gtag('event', 'page_view', {
      send_to: 'AW-620572918',
      value: Number(product.price.current).toFixed(2),
      items: [
        {
          id: product.id,
          google_business_vertical: 'retail',
        },
      ],
    }),

  // begin tracking conversion (called on product view)
  trackConversion: () =>
    gtag('event', 'conversion', {
      send_to: 'AW-620572918/aNqQCPWL-doBEPbh9KcC',
    }),

  // confirm conversion (called on add to cart)
  confirmConversion: () =>
    gtag('event', 'conversion', {
      send_to: 'AW-620572918/e2_hCIm36toBEPbh9KcC',
    }),
}
