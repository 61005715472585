import { FC } from 'react'

import LegacyFooter from './legacy_components/LegacyFooter'
import LegacyAppBar from './legacy_components/LegacyAppBar'

const Homepage: FC = () => (
  <>
    <LegacyAppBar />
    <div>hello world</div>
    <LegacyFooter />
  </>
)

export default Homepage
