import { StyledSubHeading } from '../../../components/headings'

const ProductContentSection = ({ title, children }) => (
  <section className="mbxl">
    <StyledSubHeading className="text-center text-bold">
      {title}
    </StyledSubHeading>
    {children}
  </section>
)

export default ProductContentSection
