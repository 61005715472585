/* eslint-disable react/destructuring-assignment */
import { Children, forwardRef, isValidElement, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash'
import classNames from 'classnames'
import BSButton from 'react-bootstrap/lib/Button'
import { DropdownButton as BSDropdownButton } from 'components/DropdownButton/DropdownButton'
import Spinner from 'shared/spinner'
import { useTracking } from 'lib/analytics'
import { childrenOfType } from 'airbnb-prop-types'
import css from './buttons.scss'
import { ShippingIcon, ShippingIconInverted } from './icons'

export const VARIANTS = {
  DANGER: 'danger',
  DEFAULT: 'default',
  INFO: 'info',
  LINK: 'link',
  PRIMARY: 'primary',
  SUCCESS: 'success',
  LIGHT: 'light', // will throw a warning until we migrate to react-bootstrap v1.0.0
}

const sizes = ['small', 'large']

const variantProp = PropTypes.oneOf(values(VARIANTS))
variantProp.values = values(VARIANTS)

const sizeProp = PropTypes.oneOf(sizes)
sizeProp.values = sizes

const validateText = (props, propName, componentName) => {
  const prop = props[propName]
  const children = Children.toArray(prop)
  const text = children.find((child) => typeof child === 'string')
  if (!text && !props['aria-label']) {
    return new Error(
      `Component '${componentName}' must have discernable text. Please pass a value for the 'aria-label' prop or include text in children.`
    )
  }
  return null
}

export const Button = ({
  size,
  empty,
  variant,
  type,
  style,
  children,
  href,
  id,
  className: passedClass,
  onClick,
  disabled,
  submitting,
  block,
  target,
  rel,
  pill,
  circle,
  newWindow,
  selected,
  title,
  role,
  ...rest
}) => {
  const tracker = useTracking()

  const handleClick = (e) => {
    if (onClick) {
      onClick(e)
    }
    try {
      // will only trigger if an event have been define for the context
      // @track({event: track.XXX})
      tracker.trackEvent()
    } catch (e) {
      console.log('tracking error', e)
    }
  }

  const className = classNames(passedClass, {
    'btn-empty': !!empty,
    'btn-pill': !!pill,
    'btn-circle': !!circle,
    'btn-selected': !!selected,
  })
  const btnProps = {
    'aria-label': rest['aria-label'],
    bsStyle: variant,
    bsSize: size,
    'data-external-tracking-identifier':
      rest['data-external-tracking-identifier'],
    style,
    href,
    title,
    id,
    onClick: handleClick,
    block,
    type,
    className,
    disabled,
    target: newWindow ? '_blank' : target,
    rel: newWindow ? `${rel} noopener noreferrer` : rel,
    role,
  }

  return (
    <BSButton {...btnProps}>
      {submitting ? <Spinner inverse={!empty} size="small" /> : children}
    </BSButton>
  )
}

Button.propTypes = {
  size: sizeProp,
  empty: PropTypes.bool,
  variant: variantProp,
  type: PropTypes.string,
  style: PropTypes.object,
  children: validateText,
  href: PropTypes.string, // used when you want the button to actually be a link
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool, // add a submitting state to your button (disabled + animation)
  block: PropTypes.bool,
  target: PropTypes.string,
  rel: PropTypes.string,
  pill: PropTypes.bool,
  circle: PropTypes.bool,
  newWindow: PropTypes.bool,
  selected: PropTypes.bool,
  title: PropTypes.string,
  role: PropTypes.string,
}

Button.defaultProps = {
  rel: '',
}

export const CloseButton = ({ className, style, onClick }) => (
  <button
    aria-label="Close"
    className={classNames('btn close', className)}
    style={style}
    type="button"
    onClick={onClick}
  >
    <span aria-hidden="true">&times;</span>
  </button>
)

CloseButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
}

export const SaveButton = ({ className, children, ...rest }) => (
  <div className={classNames(css.saveButton, className)}>
    <InfoButton
      pill
      className="mtm"
      style={{ minWidth: 160 }}
      type="submit"
      {...rest}
    >
      {children || 'Save'}
    </InfoButton>
  </div>
)

SaveButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
}

export const DeleteButton = ({ className, ...rest }) => (
  <InfoButton
    circle
    empty
    aria-label="Remove this gift from your registry"
    className={classNames(className, 'btn-circle btn-md flex center')}
    style={{ borderColor: '#f2f2f2', borderWidth: 2 }}
    {...rest}
  >
    <div className={css.deleteIcn} />
  </InfoButton>
)

DeleteButton.propTypes = {
  className: PropTypes.string,
}

DeleteButton.defaultProps = {
  className: undefined,
}

export const DropdownButton = (props) => {
  const className = classNames(
    props.className,
    'btn-pill',
    props.selected ? 'btn-selected' : 'btn-empty'
  )
  const btnProps = {
    pullRight: props.pullRight,
    bsStyle: props.variant,
    bsSize: props.size,
    className,
    id: props.id,
    title: props.title,
    key: props.key,
  }

  return <BSDropdownButton {...btnProps}>{props.children}</BSDropdownButton>
}

export const InfoButton = (props) => (
  <Button variant={VARIANTS.INFO} {...props} />
)
export const LinkButton = (props) => (
  <Button variant={VARIANTS.LINK} {...props} />
)
export const DangerButton = (props) => (
  <Button variant={VARIANTS.DANGER} {...props} />
)
export const DefaultButton = (props) => (
  <Button variant={VARIANTS.DEFAULT} {...props} />
)
export const PrimaryButton = (props) => (
  <Button variant={VARIANTS.PRIMARY} {...props} />
)
export const SecondaryButton = (props) => (
  <Button variant={VARIANTS.SUCCESS} {...props} />
)
export const LightButton = (props) => {
  // This is a temporary fix until we update react-bootstrap
  const customClassName = props.className
    ? `${props.className} btn-light`
    : 'btn-light'
  const customProps = { ...props, className: customClassName }

  return <Button {...customProps} />
}

const icons = {
  shipping: ShippingIcon,
  shippingInverted: ShippingIconInverted,
}

export const FabButton = ({
  children,
  className,
  icon = 'shippingInverted',
  ...rest
}) => {
  const Icon = icons[icon]

  return (
    <Button
      className={classNames(className, css.buttonFab, 'flex align-center')}
      {...rest}
    >
      {children}
      <Icon />
    </Button>
  )
}

export const InfoDropdownButton = (props) => (
  <DropdownButton variant={VARIANTS.INFO} {...props} />
)

export const ButtonGroup = forwardRef(({ children, className }, ref) => {
  // We only want to render a group if we have more than one child element.
  // This is just a safety mechanism to handle the case where a developer did not conform to the component's spec
  if (Children.toArray(children).length === 1) {
    return children
  }

  return (
    <div className={classNames(css.buttonGroup, className)} ref={ref}>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return null
        }

        return cloneElement(child, {
          ...child.props,
          className: classNames(
            child.props.className,
            css.buttonGroupItemMarginFix
          ),
        })
      })}
    </div>
  )
})

const buttonTypes = [
  InfoButton,
  DangerButton,
  DefaultButton,
  PrimaryButton,
  SecondaryButton,
  LightButton,
]

ButtonGroup.propTypes = {
  children: childrenOfType(...buttonTypes).isRequired,
  className: PropTypes.string,
}

ButtonGroup.defaultProps = {
  className: undefined,
}
