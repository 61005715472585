import { compact } from 'lodash'
import { cast, Instance, SnapshotIn, types } from 'mobx-state-tree'
import PhysicianModel, {
  PhysicianModelIn,
  PhysicianModelType,
} from './PhysicianModel'

const PhysicianSearchInputModel = types.model({
  firstName: types.string,
  lastName: types.string,
  city: types.string,
  stateName: types.string,
})

const PhysicianStateModel = types
  .model({
    all: types.maybeNull(types.array(PhysicianModel)),
    selected: types.safeReference(PhysicianModel),
    searchInput: types.maybeNull(PhysicianSearchInputModel),
  })
  .views((self) => ({
    get searchInputDisplayText() {
      const name = compact([
        self.searchInput?.firstName,
        self.searchInput?.lastName,
      ]).join(' ')
      const address = compact([
        self.searchInput?.city,
        self.searchInput?.stateName,
      ]).join(', ')
      return `"${name}, ${address}"`
    },
  }))
  .actions((self) => {
    const setAll = (physicians: PhysicianModelIn[]) => {
      self.all = cast(physicians)
    }

    const select = (physician: PhysicianModelType) => {
      self.selected = physician
    }

    const setSearchInput = (inputs: PhysicianSearchInputIn) => {
      self.searchInput = inputs
    }

    const deselect = () => {
      self.selected = undefined
    }

    return { setAll, select, setSearchInput, deselect }
  })

export default PhysicianStateModel
type PhysicianSearchInputType = Instance<typeof PhysicianSearchInputModel>
type PhysicianSearchInputIn = SnapshotIn<PhysicianSearchInputType>
