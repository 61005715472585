import { Typography } from '@material-ui/core'
import AlertDialog from 'baby-ui/compounds/AlertDialog'
import Link from 'baby-ui/foundation/Link/Link'

interface ReplacementPartsModalProps {
  open: boolean
  onClose(): void
}
export const ReplacementPartsModal = ({
  open,
  onClose,
}: ReplacementPartsModalProps) => (
  <AlertDialog closeOnBackdropClick open={open} title="" onClose={onClose}>
    <Typography gutterBottom style={{ color: '#6E3264' }} variant="h6">
      Get FREE replacement parts through insurance for up to a year!
    </Typography>
    <Typography gutterBottom style={{ fontWeight: 500 }} variant="body2">
      Old parts can negatively affect the safety, efficiency, and reliability of
      your pump. When you order your pump through Babylist:
    </Typography>
    <ul>
      <Typography gutterBottom component="li" variant="body2">
        We'll remind you when it's time to replace your parts
      </Typography>
      <Typography gutterBottom component="li" variant="body2">
        If you're still pumping, you can select your flange size
      </Typography>
      <Typography gutterBottom component="li" variant="body2">
        We'll bill insurance on your behalf and your order is always free
      </Typography>
    </ul>
    <Link
      href="https://healthbabylist.zendesk.com/hc/en-us/articles/22819765227291-Can-I-receive-replacement-parts"
      style={{ fontSize: 12, color: '#666666' }}
      target="_blank"
      underline="always"
    >
      Terms and conditions apply
    </Link>
  </AlertDialog>
)
