/**
 * Formats a number amount to USD
 * @param amount The input number amount
 * @returns A string of the number amount formatted as USD
 */
export default function formatAsUSD(amount: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount)
}
