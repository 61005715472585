import { useState } from 'react'
import { Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useMutation } from '@tanstack/react-query'
import { useDMEStore } from '../../store'
import createCustomerPhysicianDetails from '../../requests/createCustomerPhysicianDetails'
import useStyles from './styles'
import PhysicianForm, { PhysicianFormFactory } from '../../forms/PhysicianForm'

export interface PhysicianDetailsFormProps {
  nextAction: () => void
}

export default observer(({ nextAction }: PhysicianDetailsFormProps) => {
  const store = useDMEStore()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const { mutate: createPhysicianDetailsMutation } = useMutation({
    mutationFn: createCustomerPhysicianDetails,
    onSuccess: nextAction,
    onError: nextAction,
  })

  const onSubmit = (data: any) => {
    const { firstName, lastName, stateName, city, phoneNumber } = data
    const searchInputs = { firstName, lastName, city }
    setIsLoading(true)
    store.physician.setSearchInput({
      ...searchInputs,
      stateName,
    })
    createPhysicianDetailsMutation({
      firstName,
      lastName,
      addressState: stateName,
      addressCity: city,
      phoneNumber,
    })
  }

  const defaultValues = PhysicianFormFactory.deriveDefaultValues(store)

  return (
    <>
      <Typography gutterBottom className={classes.disclaimer} variant="body1">
        Please confirm the information below is accurate — don't forget to check
        for typos! Adding a phone number will help us get a prescription from
        your healthcare provider.
      </Typography>
      <PhysicianForm
        defaultValues={defaultValues}
        loading={isLoading}
        mode="details"
        onSubmit={onSubmit}
      />
      <Typography
        className={classes.emailNotice}
        color="textSecondary"
        component="p"
        variant="caption"
      >
        We will email you if we need more information.
      </Typography>
    </>
  )
})
