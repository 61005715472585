import classnames from 'classnames'
import css from './bubble-count.scss'

interface BubbleProps {
  count: number
}
function BubbleCount({ count }: BubbleProps) {
  return <div className={classnames(css.root, 'circle-mask')}>+{count}</div>
}
export default BubbleCount
