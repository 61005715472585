import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

interface PdpViewedEventMetadata {
  productId: number
  productPrice: number
}

export const pdpViewedEvent =
  (tracker: any) =>
  ({ productId, productPrice }: PdpViewedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.pdpViewed,
      productPrice,
      productType: track.ProductType.PRODUCT,
      productIds: [productId],
    })
    tracker.trackEvent(payload)
  }
