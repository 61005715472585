import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { ReplacementPartsCatalogCategoryProduct } from 'bl-health/types'
import { FragmentController } from 'bl-health/components/ReplacementPartsCatalogStrategy/utils/useReplacementPartsCatalogStrategy'
import css from './ReplacementPartsCatalogStrategy.styles.scss'

type Catalog = ReplacementPartsCatalogCategoryProduct

export interface PartsFragmentProps {
  controller: FragmentController<Catalog>
}

export default function PartsFragment({ controller }: PartsFragmentProps) {
  const { isLoading, data, select, selected, error, setError } = controller

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <Skeleton
          className={css.DropdownDownSkeleton}
          data-testid="skeleton"
          height={56}
          variant="rect"
        />
      </Grid>
    )
  }

  if (!data) return null

  return (
    <Grid item xs={12}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="flangeSizeDropdown">Flange size</InputLabel>
        <Select
          fullWidth
          native
          className={css.Dropdown}
          error={!!error || !!selected?.availabilityErrorMessage}
          id="flangeSizeDropdown"
          label="Flange size"
          name="flangeSizeDropdown"
          style={{ background: 'white' }}
          value={selected?.id || ''}
          variant="outlined"
          onChange={(e) => {
            const { value } = e.target
            const option = data?.find(({ id }) => id.toString() === value)
            select(option)
            setError(option?.availabilityErrorMessage)
          }}
        >
          <option disabled aria-label="None" />
          {data?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.attributes?.size}
            </option>
          ))}
        </Select>
        <FormHelperText error>
          {error || selected?.availabilityErrorMessage}
        </FormHelperText>
      </FormControl>
    </Grid>
  )
}
