// Stub Polyfill for matchMedia which is used by enquire.js
// we don't actually care about properly polyfilling it, we just don't want
// an error thrown in IE 9 about it

export default function () {
  return {
    matches: false,
    addListener() {},
    removeListener() {},
  }
}
