import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      overflow: 'scroll',
      display: 'flex',
      flexDirection: 'column',
    },
  })
)
