import { FC } from 'react'
import BabyUIThemeProvider from 'baby-ui/foundation/BabyUIThemeProvider/BabyUIThemeProvider'
import { DMETheme } from 'bl-health/styles'

const LegacyMuiWrapper: FC = ({ children }) => {
  const theme = new DMETheme()

  return <BabyUIThemeProvider theme={theme}>{children}</BabyUIThemeProvider>
}

export default LegacyMuiWrapper
