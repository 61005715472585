import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface ErrorThrownEventMetadata {
  error: string
}

export const errorThrownEvent =
  (tracker: any) =>
  ({ error }: ErrorThrownEventMetadata) => {
    const payload = decorateEventPayload({ event: track.errorThrown, error })
    tracker.trackEvent(payload)
  }
