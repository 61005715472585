import React from 'react'
import { ReplacementPartsHeroProduct as HeroProduct } from 'bl-health/types'

export interface ProductModelSelectorHook {
  onSelect: (item?: HeroProduct) => void
  options: HeroProduct[]
}

export default function useProductModelSelector({
  options,
  onSelect,
}: ProductModelSelectorHook) {
  const [selectedOption, setSelectedOption] = React.useState<HeroProduct>()
  const [outlinedOption, setOutlinedOption] = React.useState<HeroProduct>()

  function selectOption(option: HeroProduct) {
    setSelectedOption(option)
    setOutlinedOption(option)
    onSelect(option)
  }

  function outlineItemAbove() {
    if (!outlinedOption) return

    const nextItemIndex = options.indexOf(outlinedOption) - 1

    if (nextItemIndex < 0) return

    setOutlinedOption(options.at(nextItemIndex))
  }

  function outlineItemBelow() {
    if (!outlinedOption) return

    const nextItemIndex = options.indexOf(outlinedOption) + 1

    if (nextItemIndex >= options.length) return

    setOutlinedOption(options.at(nextItemIndex))
  }

  function handleOnKeyDown(e: React.KeyboardEvent) {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        outlineItemAbove()
        break
      case 'ArrowDown':
        e.preventDefault()
        outlineItemBelow()
        break
      case 'Enter':
        selectOption(outlinedOption as HeroProduct)
        break
      default:
        break
    }
  }

  return options.map((item) => ({
    ...item,
    outlined: item.id === outlinedOption?.id,
    selected: item.id === selectedOption?.id,
    outline: () => setOutlinedOption(item),
    select: () => selectOption(item),
    handleKeyboard: handleOnKeyDown,
  }))
}
