import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { ThemeConstants } from '../../styles'

export default function InsurancePickerFormSkeleton() {
  return (
    <Grid container spacing={ThemeConstants.BASE_SPACING}>
      <Grid item style={{ width: '100%' }} xs={12}>
        <Skeleton height={40} variant="rect" />
      </Grid>

      <Grid item style={{ width: '100%' }} xs={12}>
        <Skeleton height={40} variant="rect" />
      </Grid>

      <Grid item style={{ width: '100%' }} xs={12}>
        <Skeleton height={42} variant="rect" />
      </Grid>
    </Grid>
  )
}
