import { Typography } from '@material-ui/core'
import useStyles from './styles'

const PLPBannerWidget = () => {
  const classes = useStyles()

  return (
    <div className={classes.bannerContainer}>
      <Typography className={classes.heading} component="h2" variant="h4">
        Pumps available through your insurance
      </Typography>

      <Typography className={classes.subText}>
        Explore top-quality pumps, handpicked by our team of experts. Once you
        make your selection, we'll verify your insurance and reach out to your
        healthcare provider for a prescription.
      </Typography>
    </div>
  )
}

export default PLPBannerWidget
