import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    linkButton: {
      display: 'none',
      padding: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: '16px',
      lineHeight: '31.5px',
      textTransform: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
      },
      '&:hover': {
        background: 'none',
      },
    },
    icon: {
      marginLeft: '2px',
      marginBottom: '-8px',
    },
  })
)
