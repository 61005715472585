import { CURRENT_USER_LOCAL_STORAGE_KEY } from 'shared/hooks/useCurrentUser/useCurrentUser'
import { CurrentUser } from '../../../global'

/**
 * Get the current user from localStorage. For use outside of react hook flow.
 */
export const getCurrentUser = (): CurrentUser | undefined => {
  if (typeof window !== 'undefined') {
    const currentUserString = window.localStorage.getItem(
      CURRENT_USER_LOCAL_STORAGE_KEY
    )

    if (!currentUserString) {
      return undefined
    }

    try {
      return JSON.parse(currentUserString)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  return undefined
}
