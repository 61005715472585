import { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core'
import Button from 'baby-ui/foundation/Button'
import TextField from 'baby-ui/foundation/TextField'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import createOTPVerification from '../../requests/createOTPVerification'
import createOTP from '../../requests/createOTP'
import { apiErrorResponseAdapter } from '../../utils'
import { Error } from '../../utils/apiErrorResponseAdapter'
import { useDMEStore } from '../../store'
import {
  displayTimeRemaining,
  useResendCountdown,
} from './EmailVerificationWidget.utils'
import { formSubmittedEvent } from '../../events'
import { STORE_NAME } from '../../constants'
import PaperAirplaneIcon from '../../icons/PaperAirplaneIcon'

interface EmailVerificationWidgetProps {
  email?: string
  customerId?: number
  open: boolean
  onClose: () => void
  onFormSubmitted: (customerIdentifier: APIResponse.CustomerIdentifier) => void
  generateCodeOnOpen?: boolean
}

const EmailVerificationWidget = ({
  email,
  customerId,
  open,
  onClose,
  onFormSubmitted,
  generateCodeOnOpen = false,
}: EmailVerificationWidgetProps) => {
  const theme = useTheme()
  const store = useDMEStore()
  const tracker = useTracking()
  const [otpCode, setOTPCode] = useState('')
  const [error, setError] = useState('')
  const [initialCodeSent, setInitialCodeSent] = useState(false)
  const { timeRemaining, resetCountdown } = useResendCountdown()

  const { mutate: submitCreateOTP, isLoading: isCreateOTPLoading } =
    useMutation({
      mutationFn: createOTP,
      onSuccess: () => {
        resetCountdown()
      },
      onError: (e) => {
        const formattedError = apiErrorResponseAdapter(e as Error)
        const message =
          formattedError.message ||
          'Something went wrong sending a new code. Please try again.'
        store.ui.globalComponents.Snackbar.show(message)
      },
    })

  const generateOTP = () => submitCreateOTP({ email, customerId })

  useEffect(() => {
    if (!open) {
      setOTPCode('')
      setError('')
    }
  }, [setOTPCode, setError, open])

  useEffect(() => {
    if (open && timeRemaining === undefined) {
      resetCountdown()
    }
  }, [open, timeRemaining, resetCountdown, generateCodeOnOpen, initialCodeSent])

  useEffect(() => {
    if (open && generateCodeOnOpen && !initialCodeSent) {
      generateOTP()
      setInitialCodeSent(true)
    }
  }, [
    open,
    generateCodeOnOpen,
    initialCodeSent,
    generateOTP,
    submitCreateOTP,
    setInitialCodeSent,
  ])

  useEffect(() => {
    if (open) {
      tracker.trackEvent({
        event: track.customerVerificationModalViewed,
        storeName: STORE_NAME,
      })
    }
  }, [open])

  const handleOTPVerificationSuccess = (
    response: APIResponse.CustomerIdentifier
  ) => {
    formSubmittedEvent(tracker)({
      formName: track.FormName.CUSTOMER_VERIFICATION_FORM,
      status: track.Status.SUCCESS,
    })
    onFormSubmitted(response)
  }

  const handleOTPVerificationFailed = (e: Error) => {
    const formattedError = apiErrorResponseAdapter(e as Error)
    let message = 'Something went wrong. Please try again.'
    if (formattedError.otp === 'invalid') {
      message = 'Incorrect code. Please double check your email.'
    } else if (formattedError.otp === 'attempt limit exceeded') {
      message =
        "You've tried too many incorrect codes. Tap 'Resend code' for a new one."
    } else if (formattedError.message) {
      message = formattedError.message
    }
    formSubmittedEvent(tracker)({
      formName: track.FormName.CUSTOMER_VERIFICATION_FORM,
      status: track.Status.FAIL,
      errorMessage: formattedError.message
        ? formattedError.message
        : JSON.stringify(e),
    })
    setError(message)
  }

  const handleClose = () => {
    tracker.trackEvent({
      event: track.customerVerificationModalDismissed,
      storeName: STORE_NAME,
    })
    onClose()
  }

  const handleSubmit = () => {
    submitOTPVerification({
      email,
      customerId,
      otp: otpCode,
    })
  }

  const handleResend = () => {
    generateOTP()
  }

  const { mutate: submitOTPVerification, isLoading } = useMutation({
    mutationFn: createOTPVerification,
    onSuccess: handleOTPVerificationSuccess,
    onError: handleOTPVerificationFailed,
  })

  const emailDisplay = email ? <strong>{email}</strong> : 'your email'

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid container direction="row" wrap="nowrap">
          <Box flex={1} mr={1} style={{ overflowWrap: 'anywhere' }}>
            <Typography color="textPrimary" variant="h6">
              Verify your email
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Please enter the verification code we've sent to {emailDisplay}
            </Typography>
          </Box>
          <Box margin="auto">
            <PaperAirplaneIcon />
          </Box>
        </Grid>

        <Box
          bgcolor={theme.palette.grey[100]}
          borderRadius={5}
          fontSize={12}
          mb={1}
          mt={2}
          p={1}
        >
          Codes expire after 5 minutes. Having trouble locating the email? Check
          your spam folder.
        </Box>

        {timeRemaining !== undefined && (
          <ResendCode
            disabled={isCreateOTPLoading}
            timeRemaining={timeRemaining}
            onClick={handleResend}
          />
        )}

        <Box mt={2}>
          <TextField
            fullWidth
            required
            error={!!error}
            helperText={error}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
            label="Enter code"
            name="code"
            onChange={(e) => {
              setOTPCode(e.target.value)
            }}
          />

          <Box mt={2}>
            <LoadingButton
              fullWidth
              color="primary"
              disabled={!otpCode}
              loading={isLoading}
              variant="contained"
              onClick={handleSubmit}
            >
              Next
            </LoadingButton>
          </Box>
        </Box>

        <Button
          fullWidth
          style={{ fontSize: 13, color: theme.palette.grey[600] }}
          variant="text"
          onClick={handleClose}
        >
          Go back
        </Button>
      </DialogContent>
    </Dialog>
  )
}

interface ResendCodeProps {
  disabled: boolean
  timeRemaining: number
  onClick: () => void
}

const ResendCode = ({ disabled, timeRemaining, onClick }: ResendCodeProps) => (
  <Grid
    container
    alignItems="center"
    direction="row"
    justifyContent="flex-start"
    style={{ minHeight: 35 }}
  >
    {timeRemaining > 0 ? (
      <Typography color="textSecondary" variant="caption">
        You can request another code in {displayTimeRemaining(timeRemaining)}
      </Typography>
    ) : (
      <>
        <Typography
          component="span"
          display="inline"
          style={{
            fontSize: 13,
            lineHeight: 1.75,
          }}
        >
          Didn't receive a code?
        </Typography>
        <Button
          color="primary"
          disabled={disabled}
          style={{
            fontSize: 13,
          }}
          variant="text"
          onClick={onClick}
        >
          Resend code
        </Button>
      </>
    )}
  </Grid>
)

export default EmailVerificationWidget
