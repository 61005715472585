import { Box, Grid, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import css from './ReplacementPartsCatalogStrategy.styles.scss'

export default function ReplacementPartsCatalogStrategySkeleton() {
  return (
    <div className={css.ReplacementPartsCatalogStrategy}>
      <Box m="auto" maxWidth={400} paddingBottom={20}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={css.ReplacementPartsCatalogStrategy__hero}>
              <p className={css.ReplacementPartsCatalogStrategy__hero__title}>
                <Skeleton
                  className={css.DropdownDownSkeleton}
                  data-testid="skeleton"
                  height={62}
                  variant="text"
                />
              </p>
              <Typography variant="body2">
                <Skeleton data-testid="skeleton" variant="text" />
                <Skeleton data-testid="skeleton" variant="text" />
                <Skeleton data-testid="skeleton" variant="text" />
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom variant="body2">
              <Skeleton data-testid="skeleton" variant="text" />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
