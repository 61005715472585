import TrackVisibility from 'react-on-screen'
import EnhancedProductCard, {
  EnhancedProductCardProps,
} from 'baby-ui/compounds/EnhancedProductCard/EnhancedProductCard'
import { observer } from 'mobx-react-lite'
import { useDMEStore } from '../../store'

export interface ProductCardWidgetProps extends EnhancedProductCardProps {
  scrollStatus?: 'stopped' | 'scrolling' | 'pending'
}

export const ProductCardWidget = observer(
  ({
    scrollStatus,
    featureIndicatorProductCardIndex,
    onFeatureIndicatorClose,
    onFeatureIndicatorOpen,
    ...rest
  }: ProductCardWidgetProps) => {
    const store = useDMEStore()

    return (
      <TrackVisibility>
        {({ isVisible }) => {
          const shouldShowFeatureIndicator =
            isVisible && scrollStatus === 'stopped'

          return (
            <EnhancedProductCard
              {...rest}
              displayFeatureIndicator={
                shouldShowFeatureIndicator && !store.ui.hasSeenFeatureIndicator
              }
              featureIndicatorProductCardIndex={
                featureIndicatorProductCardIndex
              }
              onFeatureIndicatorClose={onFeatureIndicatorClose}
              onFeatureIndicatorOpen={onFeatureIndicatorOpen}
            />
          )
        }}
      </TrackVisibility>
    )
  }
)
