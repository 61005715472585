import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    desktopView: {
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
    },
    link: {
      display: 'inline-flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    arrow: {
      paddingLeft: theme.spacing(1),
      width: '20px',
      height: 'auto',
      '&:hover path, path': {
        fill: theme.palette.primary.main,
      },
      [theme.breakpoints.up('sm')]: {
        '&:hover path, path': {
          fill: theme.palette.getContrastText(theme.palette.primary.main),
        },
      },
    },
  })
)
