interface ColorNodeOptions {
  light?: string
  dark?: string
}

export default class BabyUIPaletteColorNode {
  public readonly main: string

  public readonly light?: string

  public readonly dark?: string

  constructor(main: string, { light, dark }: ColorNodeOptions = {}) {
    this.main = main
    this.light = light
    this.dark = dark
  }
}
