import { rootStore } from 'bl-health/store'
import { STORE_NAME } from '../constants'

const commonEventPayload = { storeName: STORE_NAME }

export const decorateEventPayload = (payload: any) => ({
  ...commonEventPayload,
  ...payload,
  strategy: rootStore?.context?.strategy,
})
