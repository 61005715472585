import useStyles from '../InsuranceVerificationOverlay.styles'

export const Vector = () => {
  const classes = useStyles()
  return (
    <svg
      className={classes.vector}
      fill="none"
      height="92"
      viewBox="0 0 166 92"
      width="166"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.477 0C26.2681 0 -13.3213 37.5038 -16.9796 84.9115C-17.2802 88.7322 -14.2233 92 -10.3646 92H28.7236C31.53 92 33.8853 89.9388 34.2361 87.1738C36.6415 67.0142 53.7802 51.329 74.477 51.329C94.0211 51.329 110.408 65.3049 114.217 83.8055C115.169 88.5312 119.278 92 124.089 92H160.221C163.578 92 166.234 89.1344 165.984 85.7661C162.776 37.9563 122.936 0 74.477 0Z"
        fill="#EEE8E9"
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default Vector
