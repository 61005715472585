import { Grid, Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import DateTextField from 'baby-ui/compounds/DateTextField'
import Checkbox from 'baby-ui/foundation/CheckBox'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import Alert from '@material-ui/lab/Alert'
import { UpdateDueDateFormSchema } from './UpdateDueDateFormSchema'
import { ThemeConstants } from '../../styles'
import patchDueDate from '../../requests/updateDueDate'
import parseApiErrorResponse, {
  FormError,
} from './updateDueDateApiErrorResponse'

interface FormValues {
  dueDate: string
}
interface UpdateDueDateFormProps {
  customerId: number | null
  orderId: string
  onSuccess(order: APIResponse.DME.Order): void
}

export default function UpdateDueDateForm({
  customerId,
  orderId,
  onSuccess,
}: UpdateDueDateFormProps) {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: UpdateDueDateFormSchema,
  })
  const [formError, setFormError] = useState<FormError | null>(null)

  const onError = (e: any) => {
    const formattedError = parseApiErrorResponse(e)
    setFormError(formattedError)
  }

  const { mutate: updateDueDate, isLoading } = useMutation({
    mutationFn: patchDueDate,
    onSuccess,
    onError,
  })

  const onSubmit = (data: FormValues) => {
    const payload = {
      customerId,
      dueDate: data.dueDate,
      id: orderId,
    }

    updateDueDate(payload)
  }

  const formErrorMessage = formError?.message

  return (
    <form onSubmit={handleSubmit(onSubmit as any)}>
      <Grid
        container
        spacing={ThemeConstants.BASE_SPACING}
        style={{ marginBottom: ThemeConstants.BASE_SPACING }}
      >
        {formErrorMessage && (
          <Grid item xs={12}>
            <Alert severity="error" variant="standard">
              <span
                dangerouslySetInnerHTML={{
                  __html: formErrorMessage,
                }}
              />
            </Alert>
          </Grid>
        )}
        <Grid container item spacing={ThemeConstants.BASE_SPACING}>
          <Controller
            required
            as={DateTextField}
            control={control}
            day={{
              placeholder: 'DD',
              fullWidth: true,
            }}
            error={errors.dueDate || !!formError?.dueDate}
            helperText={errors.dueDate?.message || formError?.dueDate}
            month={{
              label: 'Due Date',
              placeholder: 'MM',
              fullWidth: true,
            }}
            name="dueDate"
            year={{
              placeholder: 'YYYY',
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item>
          <Controller
            required
            as={Checkbox}
            control={control}
            label={
              <Typography color="textSecondary" variant="body2">
                My new due date is accurate. I may be billed if my insurance
                can't verify this date.
              </Typography>
            }
            name="dueDateConfirmation"
          />
        </Grid>
      </Grid>
      <LoadingButton
        fullWidth
        color="primary"
        loading={isLoading}
        type="submit"
        variant="contained"
      >
        Save
      </LoadingButton>
    </form>
  )
}
