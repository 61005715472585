import { Box } from '@material-ui/core'
import Link from 'baby-ui/foundation/Link/Link'
import { useState } from 'react'
import { Card, ContentBlock } from '../components'
import UpdateDueDateWidget from '../../../widgets/UpdateDueDateWidget/UpdateDueDateWidget'

interface UpdateDueDateCardProps {
  email: string
  orderId: string
  onSuccess(order: APIResponse.DME.Order): void
}
export default function UpdateDueDateCard({
  email,
  orderId,
  onSuccess,
}: UpdateDueDateCardProps) {
  const [updateDueDateWidgetOpen, setUpdateDueDateWidgetOpen] = useState(false)

  return (
    <Card>
      <UpdateDueDateWidget
        email={email}
        open={updateDueDateWidgetOpen}
        orderId={orderId}
        onClose={() => setUpdateDueDateWidgetOpen(false)}
        onSuccess={onSuccess}
      />
      <ContentBlock title="Has your due date changed?">
        <Box mb={2}>
          If your healthcare provider has notified you that your due date is
          sooner than expected, you can update your due date to receive your
          pump sooner.
        </Box>
      </ContentBlock>
      <Link
        component="button"
        style={{ fontWeight: 590, fontSize: '14px' }}
        type="button"
        underline="none"
        variant="chevronIcon"
        onClick={() => setUpdateDueDateWidgetOpen(true)}
      >
        Update your due date
      </Link>
    </Card>
  )
}
