import { ThemeProvider, ThemeProviderProps } from '@material-ui/core'
import { useMemo } from 'react'
import BabyUITheme from 'baby-ui/lib/BabyUITheme'
import { generateTheme } from 'baby-ui/utils'

export interface BabyUIThemeProviderProps extends ThemeProviderProps {
  theme: BabyUITheme
}

export default function BabyUIThemeProvider({
  children,
  theme,
}: BabyUIThemeProviderProps) {
  const muiTheme = useMemo(
    () => generateTheme(theme),
    [theme.isDarkModeEnabled]
  )
  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
}
