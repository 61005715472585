import Snackbar, { SnackbarProps } from 'baby-ui/compounds/Snackbar'
import { observer } from 'mobx-react-lite'
import { useDMEStore } from '../../store'

export default observer(() => {
  const {
    ui: { globalComponents },
  } = useDMEStore()

  return (
    <Snackbar
      message={globalComponents.Snackbar.message}
      open={globalComponents.Snackbar.open}
      severity={globalComponents.Snackbar.severity as SnackbarProps['severity']}
      onClose={(_, reason) => {
        if (reason !== 'clickaway') {
          globalComponents.Snackbar.close()
        }
      }}
    />
  )
})
