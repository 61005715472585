export const Chat = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    style={{ marginRight: '2px' }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66669 7.33317H12.3334M5.66669 10.6665H9.00002M17.3334 8.99984C17.3334 13.6022 13.6024 17.3332 9.00002 17.3332C8.05055 17.3332 7.2363 17.1979 6.48025 16.9275C5.76568 16.6719 5.40838 16.5441 5.27129 16.5118C4.00636 16.2144 3.48161 17.0813 2.39139 17.2629C1.85592 17.3521 1.37947 16.9137 1.42391 16.3727C1.46276 15.8997 1.78993 15.4522 1.92047 14.998C2.19187 14.0535 1.8236 13.3375 1.43452 12.4976C0.941809 11.434 0.666687 10.249 0.666687 8.99984C0.666687 4.39746 4.39765 0.666504 9.00002 0.666504C13.6024 0.666504 17.3334 4.39746 17.3334 8.99984Z"
      stroke="#F9F9F9"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
