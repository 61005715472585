/**
 * This function is used to reset the anonymousId of a user
 * if their current anonymousId looks like the old format (BL-xxxxx).
 * These were created using a predeterministic value based on a md5
 * hash of the Gift Giver's email. We need to stop doing this because
 * anonymousIds are saved to localStorage indefinitely.
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/
 *
 * We can remove this function once we are confident that the majority of
 * Gift Givers have been reset and BL-XXXX anonymousIds are no longer being
 * seen in event tracking data.
 *
 * Consider removing this function one year after this release.
 *
 * @returns {void}
 */
export const resetDeprecatedGiftGiverAnonymousId = (): void => {
  if (typeof window === 'undefined') return

  window?.analytics?.ready(() => {
    const anonymousId = window.analytics.user().anonymousId()
    if (anonymousId?.startsWith('BL-')) {
      window.analytics.user().reset()
    }
  })
}
