/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import propTypes from 'prop-types'
import Image from 'components/image'
import classNames from 'classnames'

import BubbleCount from './bubble-count'

export const GreenCheckmarkIcon = ({
  className,
  svgClassName,
  width,
  height,
}) => (
  <span
    aria-label="Green Checkmark Icon"
    className={className}
    dangerouslySetInnerHTML={{
      __html: `
      <svg class="${svgClassName || null}" width="${width || 20}px" height="${
        height || 16
      }px" viewBox="0 0 20 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="GreenCheckmark-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="web---Registry---Transfer-success-modal" transform="translate(-745.000000, -293.000000)" fill="#66BC97">
            <g id="Group" transform="translate(685.000000, 293.000000)">
              <polygon id="" points="77.8700745 0 67.3724203 11.3371914 62.1587082 5.7923543 60 7.71428573 67.4714331 15.4285714 80 1.89989221"></polygon>
            </g>
          </g>
        </g>
      </svg>
    `,
    }}
    role="img"
  />
)

export const PinkCheckmarkIcon = ({ className }) => (
  <span
    aria-label="Pink Checkmark Icon"
    className={className}
    dangerouslySetInnerHTML={{
      __html: `
      <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 5.78125L6 10.2812L15 1.78125" stroke="#FF7AAB" stroke-width="4"/>
</svg>
    `,
    }}
    role="img"
  />
)

export const WhiteCheckmarkIcon = ({
  className,
  svgClassName,
  width,
  height,
}) => (
  <span
    aria-label="White Checkmark Icon"
    className={className}
    dangerouslySetInnerHTML={{
      __html: `
    <svg class="${svgClassName || ''}" width="${width || 20}px" height="${
      height || 20
    }px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <rect id="WhiteCheckmark-path-1" x="43" y="406" width="936" height="298"></rect>
            <mask id="WhiteCheckmark-mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="936" height="298" fill="white">
                <use xlink:href="#WhiteCheckmark-path-1"></use>
            </mask>
        </defs>
        <g id="WhiteCheckmark-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="" transform="translate(-110.000000, -486.000000)">
                <use id="" stroke="#E6E6E6" mask="url(#WhiteCheckmark-mask-2)" stroke-width="2" xlink:href="#WhiteCheckmark-path-1"></use>
                <g id="" transform="translate(111.000000, 487.000000)">
                    <ellipse id="Oval-34" stroke="#66BC97" stroke-width="2" fill="#66BC97" cx="20" cy="20" rx="20" ry="20"></ellipse>
                    <polygon id="Page-1" fill="#FFFFFF" points="30.4440895 12 17.8469044 25.8585614 11.5904499 19.080563 9 21.4299283 17.9657197 30.8598567 33 14.3224247"></polygon>
                </g>
            </g>
        </g>
    </svg>
    `,
    }}
    role="img"
    style={{ width: `${width}px`, height: `${height}px` }}
  />
)

export const OnesieIcon = ({ className, width, height }) => (
  <span
    aria-label="Bodysuit Icon"
    className={className}
    dangerouslySetInnerHTML={{
      __html: `
      <svg width="${width || 37}px" height="${
        height || 48
      }px" viewBox="0 0 37 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path d="M26.088149,0 L23.3641864,0 C23.3812786,0.294800176 23.3893749,0.594080597 23.3893749,0.896049166 C23.3893749,6.33954785 20.5700557,10.75259 17.0922355,10.75259 C13.6144154,10.75259 10.7950961,6.33954785 10.7950961,0.896049166 C10.7950961,0.594080597 10.804092,0.294800176 10.8211843,0 L8.0963221,0 L0,0 L0,12.5446883 L5.2895971,12.5446883 L3.34108225,36.8114918 C3.71981021,36.7622091 4.10573489,36.7380158 4.49795672,36.7380158 C9.16323743,36.7380158 12.9981953,40.2747219 13.4488906,44.8024583 L20.7346809,44.8024583 C21.1862757,40.2747219 25.0221332,36.7380158 29.6865144,36.7380158 C30.0787362,36.7380158 30.4655605,36.7622091 30.8442884,36.8114918 L28.894874,12.5446883 L34.1844711,12.5446883 L34.1844711,0 L26.088149,0 L26.088149,0 Z" id="Onesie-path-1"></path>
            <path d="M6.29713941,1.20877032 C5.69441321,0.799275856 4.96484463,0.560030729 4.17950139,0.560030729 C2.10144538,0.560030729 0.415611201,2.23922687 0.415611201,4.30910044 C0.415611201,6.37987006 6.29713941,12.0966637 6.29713941,12.0966637 C6.29713941,12.0966637 12.1786676,6.37987006 12.1786676,4.30910044 C12.1786676,2.23922687 10.493733,0.560030729 8.41477743,0.560030729 C7.62943419,0.560030729 6.9007652,0.799275856 6.29713941,1.20877032 L6.29713941,1.20877032 Z" id="Onesie-path-3"></path>
        </defs>
        <g id="Onesie-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Registry---GG---Steps-1-2-3-FINAL" transform="translate(-383.000000, -437.000000)">
                <g id="Group-24" transform="translate(384.000000, 439.000000)">
                    <g id="Group-3" transform="translate(0.026055, 0.000000)">
                        <mask id="Onesie-mask-2" fill="white">
                            <use xlink:href="#Onesie-path-1"></use>
                        </mask>
                        <g id="Clip-2"></g>
                        <polygon id="Fill-1" fill="#F2F2F2" mask="url(#Onesie-mask-2)" points="-4.49795672 49.2827041 38.6824278 49.2827041 38.6824278 -4.48024583 -4.49795672 -4.48024583"></polygon>
                    </g>
                    <g id="Group-21">
                        <g id="Group-16">
                            <path d="M5.31556238,12.5446883 L3.36614794,36.8114918 C3.74577549,36.7631052 4.13170017,36.7380158 4.523922,36.7380158 C9.18830312,36.7380158 13.0241606,40.2747219 13.4757555,44.8024583 L20.7606462,44.8024583 C21.212241,40.2747219 25.0480985,36.7380158 29.7124796,36.7380158 C30.1047015,36.7380158 30.4906261,36.7631052 30.8702537,36.8114918 L28.9208393,12.5446883 L34.2104364,12.5446883 L34.2104364,0 L26.1141143,0 L23.3892521,0 C23.4063443,0.294800176 23.4153402,0.594080597 23.4153402,0.896049166 C23.4153402,6.33954785 20.596021,10.75259 17.1182008,10.75259 C13.6403807,10.75259 10.8210614,6.33954785 10.8210614,0.896049166 C10.8210614,0.594080597 10.8300573,0.294800176 10.8471496,0 L8.12228738,0 L0.025965278,0 L0.025965278,12.5446883 L5.31556238,12.5446883 L5.31556238,12.5446883 Z" id="Stroke-4" stroke="#314E55" stroke-width="2.025" stroke-linecap="round" stroke-linejoin="round"></path>
                            <g id="Group-8" transform="translate(10.821151, 15.232836)">
                                <mask id="Onesie-mask-4" fill="white">
                                    <use xlink:href="#Onesie-path-3"></use>
                                </mask>
                                <g id="Clip-7"></g>
                                <polygon id="Fill-6" fill="#F27D74" mask="url(#Onesie-mask-4)" points="-4.08234552 16.5769096 16.6766243 16.5769096 16.6766243 -3.9202151 -4.08234552 -3.9202151"></polygon>
                            </g>
                        </g>
                        <path d="M11.2365826,19.5421155 C11.2365826,17.4713458 12.9215172,15.7921497 15.0004728,15.7921497 C15.7858161,15.7921497 16.5153847,16.0322909 17.1181109,16.4417854 C17.7217367,16.0322909 18.4504056,15.7921497 19.2357489,15.7921497 C21.3147045,15.7921497 22.9996391,17.4713458 22.9996391,19.5421155 C22.9996391,21.6128851 17.1181109,27.3296788 17.1181109,27.3296788 C17.1181109,27.3296788 11.2365826,21.6128851 11.2365826,19.5421155 L11.2365826,19.5421155 Z" id="Stroke-9" stroke="#314E55" stroke-width="2.025" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    `,
    }}
    role="img"
  />
)

export const RegistryProfileIcon = ({ className = '', width, height }) => (
  <img
    aria-label="Registry Profile Icon"
    className={className}
    height={height}
    src="//images.babylist.com/image/upload/v1643224057/avatar-blank_t0rdtg.png"
    width={width}
  />
)

export const RegistryProfileAltIcon = ({
  className,
  svgClassName,
  width,
  height,
}) => (
  <span
    aria-label="Registry Profile Icon"
    className={className}
    dangerouslySetInnerHTML={{
      __html: `
      <svg class="${svgClassName || null}" width="${width || 57}px" height="${
        height || 85
      }px" viewBox="0 0 57 85" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
              <polygon id="RegistryProfileAl-path-1" points="56.978022 0 0.000149942163 0 0.000149942163 84.9992544 56.978022 84.9992544"></polygon>
          </defs>
          <g id="RegistryProfileAlt-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-6-Copy-3">
                  <polygon id="Rectangle-4" fill="#9ECDB8" points="2.8021978 60.2472527 2.8021978 26.1538462 22.8846154 58.8461538 43.9010989 47.1703297 54.6428571 29.4230769 54.6428571 55.5769231 45.3021978 75.1923077 28.021978 85 14.9450549 77.9945055"></polygon>
                  <g id="RegistryProfileAlt-Page-1">
                      <g id="Group-3">
                          <mask id="RegistryProfileAlt-Page-1-mask-2" fill="white">
                              <use xlink:href="#RegistryProfileAl-path-1"></use>
                          </mask>
                          <g id="Clip-2"></g>
                          <path d="M45.335163,72.1210088 C44.0861447,71.4171491 42.8071381,70.7803947 41.4501615,70.1078509 C36.7854608,67.7919737 31.597462,65.204693 25.3358772,58.7103947 C25.9251499,58.5314474 26.5204203,58.3644298 27.1351832,58.1929386 C31.3170701,57.025307 36.0552425,55.6996053 43.00956,49.1426754 C48.3430027,44.1157456 51.704706,38.056886 53.6029738,33.8292544 C53.8683715,36.7401316 53.9793287,39.6629386 53.9793287,42.4992544 C53.9793287,54.2844298 50.6296207,64.8751316 45.335163,72.1210088 M28.4891609,82.0167982 C14.4335826,82.0167982 2.9989932,64.2905702 2.9989932,42.4992544 C2.9989932,37.9256579 3.28538274,33.1283772 4.05458603,28.5160088 C6.52863172,35.0356579 11.7256271,47.1682895 19.0862879,56.1708333 C27.5730143,66.5512719 34.524333,70.0034649 40.1126774,72.7771491 C41.2762286,73.3542544 42.3738052,73.9089912 43.4308975,74.4875877 C39.2280187,79.2177632 34.0670094,82.0167982 28.4891609,82.0167982 M28.4891609,2.98171053 C43.8912199,2.98171053 50.3927121,14.4716228 52.7767925,27.6928509 C52.7573,27.7405702 52.7228133,27.7748684 52.7078191,27.8255702 C52.6763313,27.9374123 49.3806025,39.0276754 40.9463558,46.9774123 C34.5303307,53.0288158 30.3589397,54.1934649 26.3239961,55.3208333 C25.2833975,55.6116228 24.211311,55.9322368 23.1287286,56.3110088 C22.5649461,55.6667982 21.9966653,55.0002193 21.4148897,54.2903947 C11.4497335,42.1010965 5.34708748,23.2251316 5.28711061,23.0357456 C5.28111293,23.0163596 5.26611871,23.0029386 5.2586216,22.9835526 C8.29345098,11.8589912 14.9523824,2.98171053 28.4891609,2.98171053 M28.4891609,-0.000745614035 C9.85135006,-0.000745614035 0.000149942163,14.6967982 0.000149942163,42.4992544 C0.000149942163,65.9339035 12.7797205,84.9992544 28.4891609,84.9992544 C44.1971019,84.9992544 56.9781719,65.9339035 56.9781719,42.4992544 C56.9781719,14.6967982 47.1269718,-0.000745614035 28.4891609,-0.000745614035" id="Fill-1" fill="#535353" mask="url(#RegistryProfileAlt-Page-1-mask-2)"></path>
                      </g>
                      <path d="M28.489011,10.4445554 C35.8640984,10.4445554 41.8631369,16.4435939 41.8631369,23.8186813 C41.8631369,31.1937687 35.8640984,37.1928072 28.489011,37.1928072 C21.1139236,37.1928072 15.1148851,31.1937687 15.1148851,23.8186813 C15.1148851,16.4435939 21.1139236,10.4445554 28.489011,10.4445554 M28.489011,40.1648352 C37.5016858,40.1648352 44.8351648,32.8313561 44.8351648,23.8186813 C44.8351648,14.8060065 37.5016858,7.47252747 28.489011,7.47252747 C19.4763362,7.47252747 12.1428571,14.8060065 12.1428571,23.8186813 C12.1428571,32.8313561 19.4763362,40.1648352 28.489011,40.1648352" id="Fill-4" fill="#535353"></path>
                      <path d="M34.5604396,28.021978 C37.135348,28.021978 39.2307692,26.1360989 39.2307692,23.8186813 C39.2307692,23.0438736 38.5348901,22.4175824 37.6739927,22.4175824 C36.8130952,22.4175824 36.1172161,23.0438736 36.1172161,23.8186813 C36.1172161,24.5920879 35.4197802,25.2197802 34.5604396,25.2197802 C33.7010989,25.2197802 33.003663,24.5920879 33.003663,23.8186813 C33.003663,23.0438736 32.3077839,22.4175824 31.4468864,22.4175824 C30.585989,22.4175824 29.8901099,23.0438736 29.8901099,23.8186813 C29.8901099,26.1360989 31.9855311,28.021978 34.5604396,28.021978" id="Fill-6" fill="#535353"></path>
                      <path d="M27.0879121,23.8186813 C27.0879121,23.0438736 26.392033,22.4175824 25.5311355,22.4175824 C24.6702381,22.4175824 23.974359,23.0438736 23.974359,23.8186813 C23.974359,24.5920879 23.2769231,25.2197802 22.4175824,25.2197802 C21.5582418,25.2197802 20.8608059,24.5920879 20.8608059,23.8186813 C20.8608059,23.0438736 20.1649267,22.4175824 19.3040293,22.4175824 C18.4431319,22.4175824 17.7472527,23.0438736 17.7472527,23.8186813 C17.7472527,26.1360989 19.842674,28.021978 22.4175824,28.021978 C24.9924908,28.021978 27.0879121,26.1360989 27.0879121,23.8186813" id="Fill-8" fill="#535353"></path>
                  </g>
              </g>
          </g>
      </svg>
    `,
    }}
    role="img"
  />
)

export const ShoppingCartIcon = ({
  className,
  svgClassName,
  width,
  height,
}) => (
  <span
    aria-label="Shopping Cart Icon"
    className={className}
    dangerouslySetInnerHTML={{
      __html: `
      <svg class="${svgClassName || null}" width="${width || 52}px" height="${
        height || 47
      }px" viewBox="0 0 52 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
              <path d="M0,2.92689637 C0,4.5435188 1.31764706,5.85379274 2.94117647,5.85379274 C4.56568627,5.85379274 5.88235294,4.5435188 5.88235294,2.92689637 C5.88235294,1.31027394 4.56568627,0 2.94117647,0 C1.31764706,0 0,1.31027394 0,2.92689637 L0,2.92689637 Z" id="ShoppingCart-path-1"></path>
              <path d="M0,2.92689637 C0,4.5435188 1.31666667,5.85379274 2.94117647,5.85379274 C4.56568627,5.85379274 5.88235294,4.5435188 5.88235294,2.92689637 C5.88235294,1.31027394 4.56568627,0 2.94117647,0 C1.31666667,0 0,1.31027394 0,2.92689637 L0,2.92689637 Z" id="ShoppingCart-path-3"></path>
          </defs>
          <g id="ShoppingCart-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Registry---GG---Steps-1-2-3-FINAL" transform="translate(-607.000000, -437.000000)">
                  <g id="Group-19" transform="translate(608.000000, 439.000000)">
                      <g id="Page-1">
                          <g id="Group-3" transform="translate(6.000000, 38.066498)">
                              <mask id="ShoppingCart-mask-2" fill="white">
                                  <use xlink:href="#ShoppingCart-path-1"></use>
                              </mask>
                              <g id="Clip-2"></g>
                              <polygon id="Fill-1" fill="#F27D74" mask="url(#ShoppingCart-mask-2)" points="-4.90196078 10.7319533 10.7843137 10.7319533 10.7843137 -4.87816061 -4.90196078 -4.87816061"></polygon>
                          </g>
                          <path d="M8.82352941,43.586717 C10.4480392,43.586717 11.7647059,42.2764431 11.7647059,40.6598206 C11.7647059,39.0431982 10.4480392,37.7329243 8.82352941,37.7329243 C7.19901961,37.7329243 5.88235294,39.0431982 5.88235294,40.6598206 C5.88235294,42.2764431 7.19901961,43.586717 8.82352941,43.586717 L8.82352941,43.586717 Z" id="Stroke-4" stroke="#314E55" stroke-width="2.025" stroke-linecap="round" stroke-linejoin="round"></path>
                          <g id="Group-8" transform="translate(30.392157, 37.675349)">
                              <mask id="ShoppingCart-mask-4" fill="white">
                                  <use xlink:href="#ShoppingCart-path-3"></use>
                              </mask>
                              <g id="Clip-7"></g>
                              <polygon id="Fill-6" fill="#F27D74" mask="url(#ShoppingCart-mask-4)" points="-4.90196078 10.7319533 10.7843137 10.7319533 10.7843137 -4.87816061 -4.90196078 -4.87816061"></polygon>
                          </g>
                          <path d="M36.2745098,40.6022456 C36.2745098,38.9856232 34.9578431,37.6753493 33.3333333,37.6753493 C31.7088235,37.6753493 30.3921569,38.9856232 30.3921569,40.6022456 C30.3921569,42.2188681 31.7088235,43.529142 33.3333333,43.529142 C34.9578431,43.529142 36.2745098,42.2188681 36.2745098,40.6022456 Z" id="Stroke-9" stroke="#314E55" stroke-width="2.025" stroke-linecap="round" stroke-linejoin="round"></path>
                          <g id="Group-36" stroke="#314E55" stroke-width="2.025" stroke-linejoin="round">
                              <polygon id="Stroke-14" stroke-linecap="round" points="0 4.57610637 42.1568627 4.57610637 40.8235294 25.168585 1.96078431 25.168585"></polygon>
                              <polyline id="Stroke-16" stroke-linecap="round" points="40.1960784 29.1658139 40.1960784 33.2499889 3.20980392 33.2499889"></polyline>
                              <polyline id="Stroke-18" stroke-linecap="round" points="42.0846078 6.80364055 42.7404902 0.000114402659 49.6208824 0.000114402659"></polyline>
                              <path d="M0.980392157,11.4402659 L41.2245098,11.4402659" id="Stroke-20" stroke-linecap="square"></path>
                              <path d="M2.94117647,25.168585 L40.2441176,25.168585" id="Stroke-22" stroke-linecap="square"></path>
                              <path d="M1.96078431,18.3044255 L40.1960784,18.3044255" id="Stroke-24" stroke-linecap="square"></path>
                              <path d="M7.84313725,4.57610637 L7.84313725,25.168585" id="Stroke-26" stroke-linecap="square"></path>
                              <path d="M14.7058824,4.57610637 L14.7058824,25.168585" id="Stroke-28" stroke-linecap="square"></path>
                              <path d="M21.5686275,4.57610637 L21.5686275,25.168585" id="Stroke-30" stroke-linecap="square"></path>
                              <path d="M28.4313725,4.57610637 L28.4313725,25.168585" id="Stroke-32" stroke-linecap="square"></path>
                              <path d="M35.2941176,4.57610637 L35.2941176,25.168585" id="Stroke-34" stroke-linecap="square"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
      `,
    }}
    role="img"
  />
)

export const UploadPhotoIcon = ({ className, width, height }) => (
  <Image
    alt="Upload Photo Icon"
    className={className}
    height={height}
    lazyLoad={false}
    src="https://images.babylist.com/image/upload/v1550267720/icons/upload-profile-image.png"
    width={width}
  />
)

export const UploadPhotoHoverIcon = ({
  className,
  svgClassName,
  width,
  height,
}) => (
  <span
    aria-label="Upload Photo Icon (Hovered)"
    className={className}
    dangerouslySetInnerHTML={{
      __html: `
      <svg class="${svgClassName || null}" width="${width || 182}px" height="${
        height || 182
      }px" viewBox="0 0 182 182" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
              <rect id="UploadPhotoHoverIcon-path-1" x="0" y="0" width="182" height="182"></rect>
              <mask id="UploadPhotoHoverIcon-mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="182" height="182" fill="white">
                  <use xlink:href="#UploadPhotoHoverIcon-path-1"></use>
              </mask>
          </defs>
          <g id="UploadPhotoHoverIcon-Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-213.000000, -211.000000)">
                  <g id="Group-14" transform="translate(213.000000, 211.000000)">
                      <use id="Rectangle-6" stroke="#B3B3B3" mask="url(#UploadPhotoHoverIcon-mask-2)" stroke-width="2" fill="#D3D3D3" xlink:href="#UploadPhotoHoverIcon-path-1"></use>
                      <g id="Group-16" transform="translate(1.000000, 1.000000)">
                          <path d="M90,180 C139.705627,180 180,139.705627 180,90 C180,40.2943725 139.705627,0 90,0 C40.2943725,0 0,40.2943725 0,90 C0,139.705627 40.2943725,180 90,180 Z" id="Oval-3" fill="#F2F2F2"></path>
                          <text id="Upload-photo" font-size="14" font-weight="normal" fill="#666666">
                              <tspan x="41.913" y="128">Upload photo</tspan>
                          </text>
                          <g id="UploadPhotoHoverIcon-Page-1" opacity="0.8" transform="translate(63.000000, 61.000000)" stroke="#037695" stroke-width="2" stroke-linejoin="round">
                              <path d="M14.6,9 L3.994,9 C1.79,9 0,10.79 0,12.997 L0,38.003 C0,40.202 1.788,42 3.994,42 L48.006,42 C50.21,42 52,40.211 52,38.003 L52,12.997 C52,10.798 50.212,9 48.006,9 L37.4,9 L35,0 L17,0 L14.6,9 L14.6,9 Z" id="Stroke-1" stroke-linecap="round"></path>
                              <path d="M21,4 L31,4" id="Stroke-3" stroke-linecap="square"></path>
                              <path d="M26,37 C32.627,37 38,31.627 38,25 C38,18.373 32.627,13 26,13 C19.373,13 14,18.373 14,25 C14,31.627 19.373,37 26,37 L26,37 Z" id="Stroke-5" stroke-linecap="round"></path>
                              <path d="M26,21.5412 C24.294,19.7702 22.206,19.0302 20.75,19.8712 C18.837,20.9752 18.741,24.3912 20.536,27.5002 C21.975,29.9922 24.192,31.5302 26,31.4742 C27.808,31.5302 30.025,29.9922 31.464,27.5002 C33.259,24.3912 33.163,20.9752 31.25,19.8712 C29.794,19.0302 27.706,19.7702 26,21.5412 L26,21.5412 Z" id="Stroke-7" stroke-linecap="round"></path>
                          </g>
                          <g id="Group-15" transform="translate(68.000000, 20.000000)" fill="#037695">
                              <rect id="Rectangle-2" x="11" y="20" width="19" height="15"></rect>
                              <polygon id="Rectangle-3" transform="translate(20.420310, 20.420310) rotate(-135.000000) translate(-20.420310, -20.420310) " points="34.8406204 6 34.8406204 34.8406204 6 34.8406204"></polygon>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
      `,
    }}
    role="img"
  />
)

export const TikTokIcon = ({ className, width, height, ...rest }) => (
  <span
    aria-label="TikTok Icon"
    className={className}
    dangerouslySetInnerHTML={{
      __html: `
      <svg
        width="${width}px"
        height="${height}px"
        viewBox="0 0 448 512"
        aria-hidden="true"
        focusable="false"
        data-prefix="fab"
        data-icon="tiktok"
        role="img" xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path>
      </svg>
    `,
    }}
    role="img"
  />
)

/*
  Alert icons
*/
export const CheckmarkIcon = (props) => (
  <Image
    alt="Checkmark Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1613760756/icon-check-info_yfgp5t.svg"
    {...props}
  />
)
export const SuccessIcon = (props) => (
  <Image
    alt="Success Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1592946431/success-alert_cyu2op.svg"
    {...props}
  />
)

export const DangerIcon = (props) => (
  <Image
    alt="Danger Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1592946431/danger-alert_byxbb3.svg"
    {...props}
  />
)

export const InfoIcon = (props) => (
  <Image
    alt="Info Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1592946431/info-alert_b32mke.svg"
    {...props}
  />
)

export const InfoIconOutline = (props) => (
  <Image
    alt="Info Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1622051119/icon-icon-o_jvrd0m.svg"
    {...props}
  />
)

/*
  Badge Icons
*/
export const GroupGiftOnIcon = (props) => (
  <Image
    alt="Group Gift On Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1593557823/group_gift_off_copy_at1nd9.svg"
    {...props}
  />
)

export const GroupGiftOffIcon = (props) => (
  <Image
    alt="Group Gift Off Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1593557797/group_gift_on_copy_mftbbg.svg"
    {...props}
  />
)

export const FavoriteOnIcon = (props) => (
  <Image
    alt="Group Gift On Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1593558355/must_have_on_copy_leznzh.svg"
    {...props}
  />
)

export const FavoriteOffIcon = (props) => (
  <Image
    alt="Group Gift On Icon"
    lazyLoad={false}
    src="//images.babylist.com/image/upload/v1593558253/must_have_off_copy_s5js0e.svg"
    {...props}
  />
)

/*
  Registry setup icons
*/

export const RegistryIcon = ({
  alt,
  className,
  fileName,
  height,
  role,
  width,
}) => (
  <Image
    alt={alt}
    className={className}
    height={height}
    lazyLoad={false}
    role={role}
    src={`//images.babylist.com/image/upload/q_auto,f_auto/registry-icons/${fileName}`}
    width={width}
  />
)

export const ConsolidatedShippingIcon = ({
  className = null,
  width = 58,
  height = 58,
}) => (
  <RegistryIcon
    alt="Simple Shipping Icon"
    className={className}
    fileName="simple_shipping.svg"
    width={width}
    height={height}
  />
)

export const ConsolidatedShippingLeafIcon = ({
  className,
  width = 16,
  height = 18,
}) => (
  <RegistryIcon
    className={className}
    width={width}
    height={height}
    fileName="leaf.svg"
  />
)

export const StrollerIcon = ({ className }) => (
  <RegistryIcon alt="Stroller Icon" fileName="stroller.svg" />
)

export const InsertCardIcon = ({ className }) => (
  <RegistryIcon alt="Mail Icon" fileName="insert_card_orders.svg" />
)

export const DiscountStatusIcon = ({ className }) => (
  <RegistryIcon alt="Sale Tag Icon" fileName="discount_status.svg" />
)

export const PersonalizeIcon = ({ className }) => (
  <RegistryIcon
    alt="Personalization Icon"
    fileName="personalize_registry.svg"
  />
)

export const ShowerIcon = ({ className }) => (
  <RegistryIcon
    alt="Heart Shaped Balloons Icon"
    fileName="shower_information.svg"
  />
)

export const ShippingIcon = (props) => (
  <RegistryIcon
    alt="Shipping Icon"
    fileName="shipping_address.svg"
    {...props}
  />
)

export const ShippingIconInverted = (props) => (
  <RegistryIcon alt="Shipping Icon" fileName="Shipping_Icon.png" {...props} />
)

export const BabylistStoreIcon = ({ className }) => (
  <RegistryIcon alt="Store Icon" fileName="add_from_babylist_store.svg" />
)

export const AddAnyStoreIcon = ({ className, width, height }) => (
  <RegistryIcon
    alt="Multiple Stores Icon"
    className={className}
    fileName="add_from_any_store.svg"
    height={height}
    width={width}
  />
)

export const PartnerIcon = ({ className }) => (
  <RegistryIcon alt="Trailer Icon" fileName="partner_name.svg" />
)

export const LinkRegistyIcon = ({ className }) => (
  <RegistryIcon alt="Link Icon" fileName="add_other_registry.svg" />
)

export const CashFundIcon = ({ className }) => (
  <RegistryIcon alt="Piggy Bank Icon" fileName="add_cash_fund.svg" />
)

export const PresentIcon = ({ className }) => (
  <RegistryIcon
    alt="Gift Icon"
    className={className}
    fileName="hbb_status.svg"
  />
)
PresentIcon.propTypes = {
  className: propTypes.string,
}
PresentIcon.defaultProps = {
  className: null,
}

export const ShoppingBagIcon = ({ className }) => (
  <RegistryIcon
    alt="Shopping Bag with Checkmark"
    fileName="added_to_cart.svg"
  />
)

export const AddToCartFromAnyStoreIcon = ({ className }) => (
  <RegistryIcon
    alt="Babylist Heart with Stars Icon"
    className={className}
    fileName="add_to_cart_from_any_store.svg"
    role="presentation"
  />
)

export const OtherStoresIcon = ({ className }) => (
  <RegistryIcon
    alt="Amazon, Target, and Buy Buy Baby logos"
    className={className}
    fileName="other_stores_2.svg"
    role="presentation"
  />
)

export const OtherStoresMobileIcon = ({ className }) => (
  <RegistryIcon
    alt="Amazon, Target, and Buy Buy Baby logos"
    className={className}
    fileName="other_stores_mobile.png"
    role="presentation"
  />
)

export const ReturnToBabylistIcon = ({ className, height }) => (
  <RegistryIcon
    alt="Babylist Heart with Stars Moved Icon"
    className={className}
    fileName="return_to_babylist.svg"
    height={height}
    role="presentation"
  />
)

export const BabylistStoreHeartIcon = ({ className }) => (
  <RegistryIcon
    alt="Babylist Heart with Stars Icon"
    className={className}
    fileName="babylist_store.svg"
    role="presentation"
  />
)

// Navigation and Action Icons

export const ExpandIcon = (props) => (
  <Image
    alt="Expand"
    src="//images.babylist.com/image/upload/v1555011400/expand_uwjz3k.svg"
    {...props}
    lazyLoad={false}
  />
)

export const CollapseIcon = (props) => (
  <Image
    alt="Collapse"
    src="//images.babylist.com/image/upload/v1567706489/collapse_pgx9yq.svg"
    {...props}
    lazyLoad={false}
  />
)

export const OrganizeIcon = (props) => (
  <Image
    alt="Organize"
    src="//images.babylist.com/image/upload/v1565732265/organize_qhvtit.svg"
    {...props}
    lazyLoad={false}
  />
)

export const FilterIcon = (props) => (
  <Image
    alt="Filter"
    src="//images.babylist.com/image/upload/v1565734282/filter_aho5e7.svg"
    {...props}
    lazyLoad={false}
  />
)

export const CopyIcon = (props) => (
  <Image
    alt="Copy"
    src="//images.babylist.com/image/upload/v1569361174/icn-copy_fdmqnl.svg"
    {...props}
    lazyLoad={false}
  />
)

export const CloseIcon = (props) => (
  <Image
    alt="Close"
    src="//images.babylist.com/image/upload/v1571250723/close_tcdxki.svg"
    {...props}
    lazyLoad={false}
  />
)

export const DeleteIcon = (props) => (
  <Image
    alt="Delete"
    src="//images.babylist.com/image/upload/v1569442702/Delete_tsxl2v.svg"
    {...props}
    lazyLoad={false}
  />
)

export const ChevronUpIcon = (props) => (
  <Image
    alt="Up Arrow"
    src="//images.babylist.com/image/upload/v1569442976/chevron-up_k6xboh.svg"
    {...props}
    lazyLoad={false}
  />
)

export const ChevronDownIcon = (props) => (
  <Image
    alt="Down Arrow"
    src="//images.babylist.com/image/upload/v1569442980/chevron-down_tzyuhx.svg"
    {...props}
    lazyLoad={false}
  />
)

export const RegistryDiscountIcon = (props) => (
  <Image
    alt="Registry Discount"
    src="//images.babylist.com/image/upload/v1612563396/discountStatus_kvihxm.svg"
    {...props}
    lazyLoad={false}
  />
)

export const LeavingSiteIcon = (props) => (
  <Image
    alt="Leaving Site"
    src="//images.babylist.com/image/upload/v1613764832/icon-leaving-site_fvt3f6.svg"
    {...props}
    lazyLoad={false}
  />
)

export const MoveIcon = (props) => (
  <Image
    alt="Move"
    src="//images.babylist.com/image/upload/v1569445445/Move_osjhov.svg"
    {...props}
    lazyLoad={false}
  />
)

export const ArrowRightIcon = ({ className, width }) => (
  <Image
    alt="Arrow Right"
    className={className}
    src="//images.babylist.com/image/upload/v1620934801/icons/arrow_right.svg"
    width={width}
  />
)

export const ArrowDownIcon = ({ className, height }) => (
  <Image
    alt="Arrow Down"
    className={className}
    height={height}
    src="//images.babylist.com/image/upload/v1621029196/icons/arrow_down_mobile.svg"
  />
)

// Share Icons

export const EmailIcon = (props) => (
  <Image
    alt="Mail Icon"
    src="//images.babylist.com/image/upload/v1555015252/email_nakch5.svg"
    {...props}
    lazyLoad={false}
  />
)

// Group Gift Icons
export const GiftBoxIcon = (props) => (
  <Image
    alt="Gift Box Icon"
    src="https://images.babylist.com/image/upload/v1595367518/registry-icons/funded-icon.svg"
    {...props}
    lazyLoad={false}
  />
)

// Feed Icons

export const GuideIcon = (props) => (
  <Image
    alt="Guide Icon"
    src="//images.babylist.com/image/upload/v1568065693/icon-guide_3x_wl8uin.png"
    {...props}
    lazyLoad={false}
  />
)

export const PromotionIcon = (props) => (
  <Image
    alt="Promotion icon"
    src="//images.babylist.com/image/upload/v1627065848/promoted_ptkths.svg"
    {...props}
    lazyLoad={false}
  />
)

export const CelebrateIcon = (props) => (
  <Image
    alt="Confetti Popper Icon"
    src="//images.babylist.com/image/upload/v1568833637/celebrate_y94jsj.svg"
    {...props}
    lazyLoad={false}
  />
)

export const ChecklistIcon = (props) => (
  <Image
    alt="Clipboard with Checkmark"
    src="//images.babylist.com/image/upload/v1568844788/addOtherRegistry_3x_nlxxoq.png"
    {...props}
    lazyLoad={false}
  />
)

export const StoreOfferIcon = ({ storeIconName, className, style }) => (
  <span
    className={classNames(
      'icon-offer',
      `icon-offer-${storeIconName}`,
      className
    )}
    style={style}
  />
)

// Reg overview icons mobile

export const ShopRegistryOutlinedIcon = (props) => (
  <Image
    alt="Price tag icon"
    src="//images.babylist.com/image/upload/v1616630492/discountStatus_q9qruq.svg"
    {...props}
    lazyLoad={false}
  />
)

export const GuestViewOutlinedIcon = (props) => (
  <Image
    alt="Personalize registry icon"
    src="//images.babylist.com/image/upload/v1616631222/personalizeRegistry_ioqff9.svg"
    {...props}
    lazyLoad={false}
  />
)

export const ChevronLeftIcon = (props) => (
  <Image
    alt="Previous item"
    src="//images.babylist.com/image/upload/v1628618584/Back_xoa4px.svg"
    {...props}
    lazyLoad={false}
  />
)

export const ChevronRightIcon = (props) => (
  <Image
    alt="Next item"
    src="//images.babylist.com/image/upload/v1628621280/Back_qansqp.svg"
    {...props}
    lazyLoad={false}
  />
)

export const InstagramIcon = (props) => (
  <Image
    alt="Instagram icon"
    src="//images.babylist.com/image/upload/v1638561496/store_logos/instagram.svg"
    {...props}
    lazyLoad={false}
  />
)

export const FacebookIcon = (props) => (
  <Image
    alt="Facebook icon"
    src="//images.babylist.com/image/upload/v1638562057/store_logos/facebook.svg"
    {...props}
    lazyLoad={false}
  />
)

StoreOfferIcon.propTypes = {
  storeIconName: propTypes.string,
  className: propTypes.string,
  style: propTypes.object,
}

export const StoreOfferIconStack = ({
  storeOfferIconNames,
  className,
  maxIconsToDisplay,
}) => {
  const bubbleCountNumber = storeOfferIconNames.length - maxIconsToDisplay
  const iconsToDisplay =
    bubbleCountNumber > 0
      ? storeOfferIconNames.slice(0, -Math.abs(bubbleCountNumber))
      : storeOfferIconNames

  return (
    <div
      aria-label={`Buying options from ${storeOfferIconNames.join(',')}.`}
      role="img"
      style={{ display: 'flex' }}
    >
      {iconsToDisplay.map((name, i) => (
        <StoreOfferIcon
          className={classNames(className, 'faux-border-white')}
          key={name}
          storeIconName={name}
          style={{ marginLeft: i === 0 ? '0' : '-4px' }}
        />
      ))}
      {bubbleCountNumber > 0 && (
        <span style={{ marginLeft: '-4px' }}>
          <BubbleCount count={bubbleCountNumber} />
        </span>
      )}
    </div>
  )
}

export const OpenInNewWindowIcon = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 16 15"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M8.29047 3.00791H2.18503V13.0554H12.2325V6.94996H14.002V13.1524C14.002 14.0761 13.2532 14.8249 12.3295 14.8249H2.08808C1.16435 14.8249 0.415527 14.0761 0.415527 13.1524V2.91096C0.415527 1.98724 1.16435 1.23841 2.08808 1.23841H8.29047V3.00791ZM9.82015 4.41949L12.5159 1.72373H10.7696C10.3418 1.72373 9.9951 1.377 9.9951 0.94927C9.9951 0.521544 10.3418 0.174805 10.7696 0.174805H14.3856C14.8134 0.174805 15.1601 0.521544 15.1601 0.94927V4.56534C15.1601 4.99306 14.8134 5.3398 14.3856 5.3398C13.9579 5.3398 13.6112 4.99306 13.6112 4.56534V2.81899L10.9154 5.51475C10.613 5.8172 10.1226 5.8172 9.82015 5.51475C9.5177 5.21231 9.5177 4.72194 9.82015 4.41949Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

StoreOfferIconStack.propTypes = {
  storeOfferIconNames: propTypes.arrayOf(propTypes.string),
  className: propTypes.string,
  maxIconsToDisplay: propTypes.number,
}

StoreOfferIconStack.defaultProps = {
  storeOfferIconNames: [],
  maxIconsToDisplay: 3,
}

/*
  Utilities
*/
export const formatStoreNameToIconName = (storeName) =>
  storeName.replace(/\s+/g, '').toLowerCase()
