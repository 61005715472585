import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactOnScreenTrackVisibility from 'react-on-screen'
import { useTracking } from './track'

export const useTrackImpression = (
  properties,
  shouldFireEvent,
  eventFireCondition
) => {
  const tracker = useTracking()

  useEffect(() => {
    if (shouldFireEvent) {
      tracker.trackEvent(properties)
    }
  }, [eventFireCondition]) // Defaults to null, in which case the event will only fire once.
}

export const TrackImpression = ({
  children,
  eventProperties,
  shouldFireEvent = true,
  eventFireCondition = null,
}) => {
  useTrackImpression(eventProperties, shouldFireEvent, eventFireCondition)
  return children
}

export const TrackVisibility = ({ children, eventProperties }) => {
  const tracker = useTracking()
  const eventHasFired = useRef(false)

  return (
    <ReactOnScreenTrackVisibility partialVisibility once>
      {({ isVisible }) => {
        if (isVisible && !eventHasFired.current) {
          eventHasFired.current = true
          tracker.trackEvent(eventProperties)
        }
        return children
      }}
    </ReactOnScreenTrackVisibility>
  )
}

TrackVisibility.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  eventProperties: PropTypes.object.isRequired,
}

TrackImpression.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  eventProperties: PropTypes.object.isRequired,
  shouldFireEvent: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  eventFireCondition: PropTypes.any,
}
