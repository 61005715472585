import BabyUITheme from 'baby-ui/lib/BabyUITheme'
import BabyUIPaletteColorNode from 'baby-ui/lib/BabyUIPaletteColorNode'

export default class DMETheme extends BabyUITheme {
  protected htmlBaseFontSize = 10

  private readonly colorCream = '#F9F6F3'

  private readonly colorEggplant = '#6E3264'

  private readonly colorPoppy = '#E05F31'

  protected getHTMLFontSize(): number {
    return this.htmlBaseFontSize
  }

  protected getPrimaryColor() {
    return new BabyUIPaletteColorNode(this.colorEggplant)
  }

  protected getSecondaryColor() {
    return new BabyUIPaletteColorNode(this.colorPoppy)
  }

  protected getBackgroundColor() {
    return { contrast: this.colorCream, default: '#fff' }
  }
}
