import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  IconButton,
} from '@material-ui/core'
import { useCallback, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import useStyles from './UpdateDueDateWidget.styles'
import EmailVerificationWidget from '../EmailVerificationWidget/EmailVerificationWidget'
import UpdateDueDateForm from '../../forms/UpdateDueDateForm/UpdateDueDateForm'
import { useDMEStore } from '../../store'

interface UpdateDueDateWidgetProps {
  open: boolean
  email: string
  orderId: string
  onClose(): void
  onSuccess(order: APIResponse.DME.Order): void
}

const UpdateDueDateWidget = ({
  open,
  onClose,
  email,
  orderId,
  onSuccess,
}: UpdateDueDateWidgetProps) => {
  const classes = useStyles()
  const store = useDMEStore()
  const [otpRequired, setOtpRequired] = useState(true)
  const [customerId, setCustomerId] = useState<null | number>(null)
  const handleEmailVerificationOnClose = () => {
    setOtpRequired(true)
    onClose()
  }

  const handleOTPSuccess = useCallback(
    ({ id }: APIResponse.CustomerIdentifier) => {
      setCustomerId(id)
      setOtpRequired(false)
    },
    [setCustomerId, setOtpRequired]
  )

  const handleDueDateUpdateSuccess = useCallback(
    (order: APIResponse.DME.Order) => {
      store.ui.globalComponents.Snackbar.show(
        'Due date successfully updated',
        'success'
      )
      onSuccess(order)
      onClose()
    },
    [store]
  )

  if (otpRequired && open) {
    return (
      <EmailVerificationWidget
        generateCodeOnOpen
        email={email}
        open={otpRequired}
        onClose={handleEmailVerificationOnClose}
        onFormSubmitted={handleOTPSuccess}
      />
    )
  }

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      title="Update your due date"
    >
      <DialogContent style={{ paddingBottom: '16px' }}>
        <Grid container direction="column" wrap="nowrap">
          <Box className={classes.header}>
            <Typography color="textPrimary" variant="h6">
              Update your due date
            </Typography>
            <IconButton
              aria-label="close"
              className={classes.icon}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            className={classes.bottomPadding}
            color="textSecondary"
            variant="body2"
          >
            If your healthcare provider has given you a new due date, please
            enter it below.
          </Typography>
        </Grid>
        <UpdateDueDateForm
          customerId={customerId}
          orderId={orderId}
          onSuccess={handleDueDateUpdateSuccess}
        />
      </DialogContent>
    </Dialog>
  )
}

export default UpdateDueDateWidget
