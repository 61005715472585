import {
  Button,
  Box,
  useTheme,
  ButtonProps,
  useMediaQuery,
} from '@material-ui/core'
import { ExternalIconSvg } from '../index'
import useStyles from './styles'
import { HTMLAttributeAnchorTarget } from 'react'

interface LinkButtonProps extends ButtonProps {
  className?: string
  icon?: 'external' | 'none'
  underline?: boolean
  target?: HTMLAttributeAnchorTarget
}

export const LinkButton = ({
  children,
  className,
  underline = false,
  icon = 'none',
  href,
  target,
  ...rest
}: LinkButtonProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const bottomBorder = `2px solid ${theme.palette.primary.main}`
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const buttonProps = {
    ...rest,
    target: (target ?? isMobile) ? '_self' : '_blank',
  }

  return (
    <Button
      className={`${classes.linkButton} ${className}`}
      href={href}
      {...buttonProps}
    >
      <Box borderBottom={underline ? bottomBorder : 0}>
        {children}
        {icon === 'external' && <ExternalIconSvg className={classes.icon} />}
      </Box>
    </Button>
  )
}
