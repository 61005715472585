import React from 'react'

const useClickAway = <ElementType extends HTMLElement>(
  onClickAway: () => void
) => {
  const elementRef = React.useRef<ElementType>(null)

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      elementRef.current &&
      !elementRef.current.contains(event.target as Node)
    ) {
      onClickAway()
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return elementRef
}

export default useClickAway
