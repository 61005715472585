import { Box, Grid, Typography } from '@material-ui/core'
import BrandsFragment from 'bl-health/components/ReplacementPartsCatalogStrategy/BrandsFragment'
import PartsFragment from 'bl-health/components/ReplacementPartsCatalogStrategy/PartsFragment'
import ProductsFragment from 'bl-health/components/ReplacementPartsCatalogStrategy/ProductsFragment'
import ActionFragment from 'bl-health/components/ReplacementPartsCatalogStrategy/ActionFragment'
import { useLayout } from 'bl-health/widgets/Layout'
import useReplacementPartsCatalogStrategy from './utils/useReplacementPartsCatalogStrategy'
import css from './ReplacementPartsCatalogStrategy.styles.scss'
import ReplacementPartsCatalogStrategySkeleton from './ReplacementPartsCatalogStrategySkeleton'
import ReplacementPartsCatalogStrategyError from './ReplacementPartsCatalogStrategyError'

export default function ReplacementPartsCatalogStrategy() {
  const {
    brandsController,
    productsController,
    partsController,
    showErrorBlock,
  } = useReplacementPartsCatalogStrategy()
  const layout = useLayout()

  if (brandsController.isLoading) {
    return <ReplacementPartsCatalogStrategySkeleton />
  }

  if (showErrorBlock) {
    return <ReplacementPartsCatalogStrategyError />
  }

  return (
    <div className={css.ReplacementPartsCatalogStrategy}>
      <Box m="auto" maxWidth={400}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {layout?.milkBagsForPartsBuyers ? (
              <>
                <p className={css.ReplacementPartsCatalogStrategy__hero__title}>
                  Select the brand and model of your pump
                </p>
                <Typography gutterBottom variant="body2">
                  You qualify for breast pump replacement parts 30 days after
                  your baby arrives. We'll notify you at checkout if you're
                  eligible to receive milk storage bags, too!
                </Typography>
              </>
            ) : (
              <div className={css.ReplacementPartsCatalogStrategy__hero}>
                <p className={css.ReplacementPartsCatalogStrategy__hero__title}>
                  Thanks for the info!
                </p>
                <Typography color="primary" variant="body1">
                  To qualify for breast pump replacement parts, you must wait
                  30+ days after your baby arrives.
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {layout?.milkBagsForPartsBuyers ? null : (
              <Typography gutterBottom variant="body2">
                Select the brand and model of your pump to confirm your
                eligibility for free new parts.
              </Typography>
            )}
          </Grid>

          <BrandsFragment controller={brandsController} />

          <ProductsFragment
            controller={productsController}
            key={brandsController.selected?.brand}
          />

          <PartsFragment
            controller={partsController}
            key={productsController.selected?.id}
          />

          <ActionFragment
            brandsController={brandsController}
            key={`${productsController.selected?.id}-${partsController.selected?.id}`}
            partsController={partsController}
            productsController={productsController}
          />
        </Grid>
      </Box>
    </div>
  )
}
