import { Instance, types } from 'mobx-state-tree'
import { pick } from 'lodash'
import InsuranceStateModel from './InsuranceStateModel'
import { LOCAL_STORAGE_KEY } from '../constants'
import PhysicianStateModel from './PhysicianStateModel'
import UserStateModel from './UserStateModel'
import CartStateModel from './CartStateModel'
import UIStateModel from './UIStateModel'
import ContextStateModel from './ContextStateModel'

/**
 * @deprecated We should use async/await with any `store.model.action`.
 * Example: app/assets/javascripts/dme/widgets/ProductListingWidget/CardActionWidget.tsx:17
 */
export interface EscapingActions<T = undefined> {
  onSuccess?: T extends undefined ? () => void : (data: T) => void
}

const RootStateModel = types
  .model({
    cart: types.optional(CartStateModel, {}),
    insurance: types.optional(InsuranceStateModel, {}),
    physician: types.optional(PhysicianStateModel, {}),
    user: types.optional(UserStateModel, {}),
    ui: types.optional(UIStateModel, {}),
    context: types.optional(ContextStateModel, {}),
  })
  .actions((self) => {
    function saveSnapshotToLocalStorage() {
      // Only pick data that we need to load initially
      const saveData = pick(self, ['insurance', 'cart', 'ui'])
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({
          ...saveData,
          ui: { hasSeenFeatureIndicator: saveData.ui.hasSeenFeatureIndicator },
        })
      )
    }

    function deleteSnapshotFromLocalStorage() {
      localStorage.removeItem(LOCAL_STORAGE_KEY)
    }

    function resetPhysician() {
      self.physician = PhysicianStateModel.create({})
    }

    return {
      resetPhysician,
      deleteSnapshotFromLocalStorage,
      saveSnapshotToLocalStorage,
    }
  })

export default RootStateModel
export type RootStateModelType = Instance<typeof RootStateModel>
