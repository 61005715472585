import { Instance, types } from 'mobx-state-tree'
import Cookies from 'js-cookie'
import GlobalComponentsStateModel from './GlobalComponentsStateModel'

const UIStateModel = types
  .model({
    error: types.maybeNull(types.map(types.frozen())),
    isLoading: types.optional(types.boolean, false),
    globalComponents: types.optional(GlobalComponentsStateModel, {
      Snackbar: {},
      Overlay: {},
    }),
    hasSeenFeatureIndicator: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const sawFeatureIndicator = () => {
      self.hasSeenFeatureIndicator = true
      Cookies.set('has_seen_feature_indicator', 'true')
    }

    return { sawFeatureIndicator }
  })

export default UIStateModel
export type UIStateModelType = Instance<typeof UIStateModel>
