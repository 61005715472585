import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import { getTracesSampleRate } from 'lib/get-traces-sample-rate'
import { httpClientIntegration } from '@sentry/integrations'

const windowIsDefined = typeof window !== 'undefined'

const environment = windowIsDefined && window?.__BL_ANALYTICS__?.environment
const release = windowIsDefined && window?.__BL_ANALYTICS__?.app_version

export const startSentry = () => {
  try {
    Sentry.init({
      dsn: 'https://5ce88c302ad84037b02be55edebe6500@o1363869.ingest.sentry.io/4505189536497664',
      environment,
      initialScope: (scope) => {
        scope.setTag('application', 'babylist-health')
        return scope
      },
      // @ts-ignore
      integrations: [httpClientIntegration(), new BrowserTracing()],
      release,
      tracesSampler: (samplingContext) => {
        const {
          // @ts-ignore
          location: { host },
          transactionContext: { name, op },
        } = samplingContext

        return getTracesSampleRate(host, name, op)
      },
      tracePropagationTargets: [
        /^https:\/\/babylist\.test/,
        /^https:\/\/www\.babylist\.test/,
        /^https:\/\/(?:staging\d*\.)?babylist\.com/,
        /^https:\/\/canary\.babylist\.com/,
        /^https:\/\/www\.babylist\.com/,
        /^https:\/\/babylist\.com/,
      ],
    })
  } catch (error) {
    console.error('Error initializing Sentry', error)
  }
}
