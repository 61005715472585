import * as yup from 'yup'
import { removeAllNonDigitChar } from 'baby-ui/utils'
import { date } from '../CommonValidationSchemas/DateValidationSchema'

export const UpdateDueDateFormSchema = yup.object().shape({
  dueDate: date.test({
    name: 'is-due-date-valid',
    test(value, ctx) {
      if (!removeAllNonDigitChar(value || '')) {
        return ctx.createError({ message: 'Due date is required' })
      }

      return true
    },
  }),
})
