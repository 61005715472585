import fetch from 'lib/fetch'
import { VERIFY_CUSTOMER_INSURANCE_ENDPOINT } from './constants'
import { RequestMethod } from './RequestMethod'

interface VerifyOrderInsurancePayload {
  customerInsuranceId: string
}

export default function verifyCustomerInsurance(
  payload: VerifyOrderInsurancePayload
): Promise<APIResponse.Checkout> {
  return fetch(
    VERIFY_CUSTOMER_INSURANCE_ENDPOINT(payload.customerInsuranceId),
    {
      method: RequestMethod.POST,
    }
  )
}
