import { Box, Container, Divider, Typography } from '@material-ui/core'

export interface ProductDetailsSectionProps {
  /**
   * "What we love about it" section content
   */
  whatWeLove?: string
  /**
   * "About this product" section content
   */
  about?: string
  /**
   * "Description" section content
   */
  description?: string
  /**
   * "Specs" section content
   */
  specs?: string
}

export default function ProductDetailsSection({
  whatWeLove,
  about,
  description,
  specs,
}: ProductDetailsSectionProps) {
  return (
    <Box>
      {whatWeLove && (
        <Box pb={4}>
          <Typography
            gutterBottom
            align="center"
            color="primary"
            component="h5"
            variant="body1"
          >
            WHAT WE LOVE ABOUT IT
          </Typography>
          <Container maxWidth="md">
            <Typography
              align="center"
              component="p"
              dangerouslySetInnerHTML={{ __html: whatWeLove }}
              variant="h5"
            />
          </Container>
        </Box>
      )}
      {about && (
        <Box pb={3}>
          <Typography
            gutterBottom
            align="center"
            color="primary"
            component="h2"
            variant="h5"
          >
            About This Product
          </Typography>
          <Divider />
          <Container maxWidth="md">
            <Typography
              paragraph
              component="p"
              dangerouslySetInnerHTML={{ __html: about }}
              variant="body1"
            />
          </Container>
        </Box>
      )}
      {description && (
        <Box pb={3}>
          <Typography
            gutterBottom
            align="center"
            color="primary"
            component="h2"
            variant="h5"
          >
            Overview
          </Typography>
          <Divider />
          <Container maxWidth="md">
            <Typography
              paragraph
              component="p"
              dangerouslySetInnerHTML={{ __html: description }}
              variant="body1"
            />
          </Container>
        </Box>
      )}
      {specs && (
        <Box pb={3}>
          <Typography
            gutterBottom
            align="center"
            color="primary"
            component="h2"
            variant="h5"
          >
            Details
          </Typography>
          <Divider />
          <Container maxWidth="md">
            <Typography
              paragraph
              component="p"
              dangerouslySetInnerHTML={{ __html: specs }}
              variant="body1"
            />
          </Container>
        </Box>
      )}
    </Box>
  )
}
