import { Factory } from 'fishery'
import { Offer } from 'src/types/offer'

export default Factory.define<Offer>(({ sequence }) => ({
  id: sequence,
  storeName: 'babylist.com',
  storeDisplayName: 'Babylist',
  storeIconName: 'babylist',
  price: 20,
  priceDetails: {
    listPrice: {
      price: '20',
    },
  },
  url: 'https://babylist.com',
  isBabylist: true,
}))
