import PropTypes from 'prop-types'
import { InfoButton } from 'components/buttons'
import { TurnToSnippet, updateSku } from './turnto-snippet'
import css from './review-button.scss'

const ReviewIcon = () => (
  <svg
    className={css.Icon}
    fill="none"
    height="15"
    viewBox="0 0 15 15"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.125 0H1.875C0.84082 0 0 0.84082 0 1.875V10.3125C0 11.3467 0.84082 12.1875 1.875 12.1875H4.6875V14.6484C4.6875 14.8564 4.85742 15 5.03906 15C5.10938 15 5.18262 14.9795 5.24707 14.9297L8.90625 12.1875H13.125C14.1592 12.1875 15 11.3467 15 10.3125V1.875C15 0.84082 14.1592 0 13.125 0ZM13.5938 10.3125C13.5938 10.5703 13.3828 10.7812 13.125 10.7812H8.4375L8.0625 11.0625L6.09375 12.5391V10.7812H1.875C1.61719 10.7812 1.40625 10.5703 1.40625 10.3125V1.875C1.40625 1.61719 1.61719 1.40625 1.875 1.40625H13.125C13.3828 1.40625 13.5938 1.61719 13.5938 1.875V10.3125Z"
      fill="#6E3264"
    />
  </svg>
)

export const ReviewButton = ({ sku }) => {
  if (!sku) return null
  const handleClick = () => {
    updateSku(sku)
    window.TurnToCmd('reviewsList.writeReview')
  }
  return (
    <>
      <TurnToSnippet sku="" pageId="gift-tracker-review-submission" />
      <InfoButton
        empty
        pill
        size="small"
        onClick={handleClick}
        style={{ fontWeight: 'normal' }}
        className={`
          ${css.Button}
          mtm ptm flex justify-center align-center"
        `}
      >
        <ReviewIcon />
        Write A Review
      </InfoButton>
    </>
  )
}

ReviewButton.propTypes = { sku: PropTypes.string.isRequired }
