import * as React from 'react'

export interface StarburstBadgeProps {
  bgcolor?: string
  className?: string
  curvedLabel?: string
  label: React.ReactElement
}

export default function StarburstBadge({
  bgcolor = '#E35F32',
  className,
  curvedLabel,
  label,
}: StarburstBadgeProps) {
  return (
    <svg
      className={className}
      fill="none"
      height="151"
      viewBox="0 0 151 151"
      width="151"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.1793 1.94516C73.7641 -0.414928 77.2359 -0.414923 78.8208 1.94516L88.5404 16.4193C89.6555 18.08 91.826 18.6616 93.622 17.781L109.277 10.1058C111.829 8.85429 114.836 10.5902 115.028 13.4265L116.209 30.8213C116.344 32.8171 117.933 34.4059 119.929 34.5414L137.323 35.7217C140.16 35.9142 141.896 38.9209 140.644 41.4734L132.969 57.128C132.088 58.924 132.67 61.0945 134.331 62.2096L148.805 71.9293C151.165 73.5141 151.165 76.9859 148.805 78.5708L134.331 88.2904C132.67 89.4055 132.088 91.576 132.969 93.372L140.644 109.027C141.896 111.579 140.16 114.586 137.323 114.778L119.929 115.959C117.933 116.094 116.344 117.683 116.209 119.679L115.028 137.073C114.836 139.91 111.829 141.646 109.277 140.394L93.622 132.719C91.826 131.838 89.6555 132.42 88.5404 134.081L78.8207 148.555C77.2359 150.915 73.7641 150.915 72.1792 148.555L62.4596 134.081C61.3445 132.42 59.174 131.838 57.378 132.719L41.7234 140.394C39.1709 141.646 36.1642 139.91 35.9717 137.073L34.7914 119.679C34.6559 117.683 33.0671 116.094 31.0714 115.959L13.6765 114.778C10.8402 114.586 9.1043 111.579 10.3558 109.027L18.031 93.372C18.9116 91.576 18.33 89.4055 16.6693 88.2904L2.19516 78.5707C-0.164928 76.9859 -0.164923 73.5141 2.19516 71.9292L16.6693 62.2096C18.33 61.0945 18.9116 58.924 18.031 57.128L10.3558 41.4734C9.10429 38.9209 10.8402 35.9142 13.6765 35.7217L31.0713 34.5414C33.0671 34.4059 34.6559 32.8171 34.7914 30.8214L35.9717 13.4265C36.1642 10.5902 39.1709 8.8543 41.7234 10.1058L57.378 17.781C59.174 18.6616 61.3445 18.08 62.4596 16.4193L72.1793 1.94516Z"
        fill={bgcolor}
      />
      {curvedLabel && (
        <>
          <path d="M 31 77 a 46 46.5 0 0 1 91.5 0" id="circle" />
          <text>
            <textPath
              fill="white"
              fontSize="9"
              fontWeight="bold"
              href="#circle"
              startOffset="51"
            >
              {curvedLabel}
            </textPath>
          </text>
        </>
      )}
      <foreignObject height="151" width="151" x="0" y="0">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          {label}
        </div>
      </foreignObject>
    </svg>
  )
}
