import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'
import { STORE_NAME } from '../constants'

export interface PhysicianSearchCompletedEventMetadata {
  resultCount: number
}

export const physicianSearchCompletedEvent =
  (tracker: any) =>
  ({ resultCount }: PhysicianSearchCompletedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.physicianSearchCompleted,
      storeName: STORE_NAME,
      resultCount,
    })
    tracker.trackEvent(payload)
  }
