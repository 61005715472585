import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    bannerTextContainer: {
      alignItems: 'center',
      justifyContent: 'space-between',
      verticalAlign: 'middle',
    },
    closeIcon: {
      textAlign: 'right',
    },
    drawerContent: {
      height: '100%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.contrast,
      minHeight: '410px',
    },
    fixedBanner: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      position: 'sticky',
      bottom: 0,
      zIndex: theme.zIndex.modal,
    },
    heartIcon: {
      padding: '4px',
    },
    helpText: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)
