import { createHistory, createMemoryHistory } from 'history'
import {
  browserHistory,
  createMemoryHistory as reactRouterMemoryHistory,
} from 'react-router'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export const getHistory = ({ path = '', useRouter = false } = {}) => {
  if (!canUseDOM || process.env.NODE_ENV == 'test' || process.env.STORYBOOK) {
    const historyCreator = useRouter
      ? reactRouterMemoryHistory
      : createMemoryHistory
    return historyCreator(path)
  }

  return useRouter ? browserHistory : createHistory()
}
