import { createContext, useContext } from 'react'

import { ApplicationSettings } from 'bl-health/types'

const LayoutContext = createContext<ApplicationSettings | null>(null)
export const LayoutContextProvider = LayoutContext.Provider

export function useLayout() {
  return useContext(LayoutContext)
}
