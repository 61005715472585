export const Phone = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2519 17.6889L12.2607 17.6941C13.0475 18.195 13.9816 18.4126 14.9087 18.3109C15.8359 18.2092 16.7006 17.7944 17.3601 17.1348L17.9334 16.5615C18.1895 16.3051 18.3334 15.9575 18.3334 15.5952C18.3334 15.2328 18.1895 14.8853 17.9334 14.6289L15.5178 12.2147C15.2615 11.9587 14.9139 11.8148 14.5515 11.8148C14.1892 11.8148 13.8416 11.9587 13.5852 12.2147C13.4584 12.3417 13.3079 12.4424 13.1421 12.5111C12.9764 12.5798 12.7987 12.6152 12.6193 12.6152C12.4399 12.6152 12.2622 12.5798 12.0964 12.5111C11.9307 12.4424 11.7801 12.3417 11.6533 12.2147L7.78807 8.34875C7.532 8.09236 7.38817 7.7448 7.38817 7.38243C7.38817 7.02007 7.532 6.67251 7.78807 6.41612C7.91502 6.28933 8.01573 6.13875 8.08445 5.97301C8.15316 5.80726 8.18853 5.6296 8.18853 5.45018C8.18853 5.27075 8.15316 5.09309 8.08445 4.92734C8.01573 4.7616 7.91502 4.61103 7.78807 4.48423L5.37321 2.06641C5.11682 1.81034 4.76927 1.6665 4.4069 1.6665C4.04453 1.6665 3.69698 1.81034 3.44058 2.06641L2.86724 2.63975C2.20784 3.29931 1.79301 4.16396 1.6912 5.09103C1.58939 6.01809 1.80668 6.95217 2.30723 7.73911L2.31316 7.748C4.96018 11.6665 8.33399 15.041 12.2519 17.6889Z"
      stroke="#F9F9F9"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
