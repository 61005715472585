import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import * as React from 'react'
import { ErrorIcon, CheckIcon } from '../../icons'

export interface ContentBlockProps {
  title?: string
  variant?: 'warn' | 'success'
  testId?: string
}

export const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  children,
  variant,
  testId = 'content-block',
}: React.PropsWithChildren<ContentBlockProps>) => {
  const theme = useTheme()

  let icon = null
  if (variant === 'warn') {
    icon = (
      <ErrorIcon
        height={27}
        style={{ marginRight: theme.spacing(1) }}
        width={27}
      />
    )
  } else if (variant === 'success') {
    icon = (
      <CheckIcon
        height={27}
        style={{ marginRight: theme.spacing(1) }}
        themeColor="success"
        width={27}
      />
    )
  }

  return (
    <div data-testid={testId}>
      {title ? (
        <Box mb={1}>
          <Grid container alignItems="center" direction="row">
            {icon}
            <Typography
              style={{ fontWeight: theme.typography.fontWeightRegular }}
              variant="h6"
            >
              {title}
            </Typography>
          </Grid>
        </Box>
      ) : null}

      <Typography component="div" variant="body2">
        {children}
      </Typography>
    </div>
  )
}
