import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    heroContainer: {
      maxWidth: 'none',
      marginRight: 0,
      position: 'relative',
      height: '100%',
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      right: 0,
      top: 0,
      background: 'rgba(74, 36, 68, 0.9)',
      backdropFilter: 'blur(10px)',
      color: theme.palette.getContrastText(theme.palette.primary.main),
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(8),
      paddingHorizontal: theme.spacing(4),
    },
    mobileFormContainer: {
      paddingBottom: theme.spacing(4),
      background: theme.palette.background.contrast,
    },
    cta: {
      width: 360, // TODO: Change to 480 in style update
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
)
