import Radio from '@material-ui/core/Radio'
import { Divider, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import css from './ProductModelSelector.styles.scss'
import useProductModelSelector, {
  ProductModelSelectorHook,
} from './utils/useProductModelSelector'

export interface ProductModelSelectorProps extends ProductModelSelectorHook {
  label: string
  error?: boolean
}

export default function ProductModelSelector({
  options,
  label,
  onSelect,
  error,
}: ProductModelSelectorProps) {
  const items = useProductModelSelector({ options, onSelect })

  return (
    <div
      aria-expanded
      aria-activedescendant=""
      aria-controls="product-model-selector-list"
      aria-labelledby="product-model-selector-label"
      className={css.ProductModelSelector}
      role="combobox"
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      tabIndex={1}
    >
      <fieldset
        className={classNames(css.ProductModelSelector__root, {
          [css.ProductModelSelector__root_error]: error,
        })}
      >
        <legend className={css.ProductModelSelector__label}>
          <label
            htmlFor="product-model-selector-list"
            id="product-model-selector-label"
          >
            {label}
          </label>
        </legend>

        <ul
          aria-controls="product-model-selector-list"
          aria-labelledby="product-model-selector-label"
          className={css.ProductModelSelector__select}
          id="product-model-selector-list"
          role="listbox"
        >
          {items.map((item, i, array) => (
            <React.Fragment key={item.id}>
              <li
                aria-describedby={`product-model-selector-list-item-label-${item.id}`}
                aria-selected={item.selected}
                className={classNames(css.ProductModelSelector__select__item, {
                  [css.ProductModelSelector__select__item__outlined]:
                    item.outlined,
                })}
                role="option"
                tabIndex={0}
                onClick={item.select}
                onKeyDown={item.handleKeyboard}
              >
                <Radio
                  checked={item.selected}
                  color="primary"
                  id={`product-model-selector-list-item-${item.id}`}
                  value={item.id}
                  name={`select-option-${item.id}`}
                  // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                  tabIndex={1}
                />

                <Typography
                  className={css.ProductModelSelector__select__item__label}
                  id={`product-model-selector-list-item-label-${item.id}`}
                >
                  {item.shortName}
                </Typography>

                <img
                  alt={item.name}
                  className={css.ProductModelSelector__select__item__thumbnail}
                  src={item.images.thumbnailUrl}
                />
              </li>
              {i !== array.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </ul>
      </fieldset>
    </div>
  )
}
