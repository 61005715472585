import BabyUITheme from 'baby-ui/lib/BabyUITheme'
import { CSSProperties } from '@material-ui/styles/withStyles'
import { ThemeOptions } from '@material-ui/core'

declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    screenReadersOnly: CSSProperties
    listUnstyled: CSSProperties
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    contrast: string
  }

  interface PaletteOptions {
    background?: Partial<TypeBackground>
  }
}

declare module '@material-ui/core/styles/shape' {
  interface Shape {
    buttonRadius: number
  }

  // @ts-ignore
  type ShapeOptions = Partial<Shape>
}

export default function babyUIThemeToMuiThemeAdapter(theme: BabyUITheme) {
  const { primary, secondary, background } = theme.getColorPalette()

  return {
    palette: {
      type: theme.isDarkModeEnabled ? 'dark' : 'light',
      primary,
      secondary,
      background,
    },
    typography: theme.getTypographyStyles(),
    props: theme.getPropsOverrides(),
    mixins: {
      screenReadersOnly: theme.screenReadersOnlyStyle(),
      listUnstyled: theme.listClearStyle(),
    },
    shape: theme.getShape(),
  } as ThemeOptions
}
