import qs from 'query-string'
import { SortOrder } from 'baby-ui/lib/SortOrder'
import { FilterSettings } from './FilterSettings'
import ProductFilterSortCategory from './ProductFilterSortCategory'

export const stringify = (filterSettings: FilterSettings) => {
  // Search params don't support 2D arrays
  const settingsStr = JSON.stringify(filterSettings)
  return qs.stringify({ filterSettings: settingsStr })
}

export const parse = (searchQuery: string) => {
  const parsedSearchQuery = qs.parse(searchQuery)

  if (!parsedSearchQuery.filterSettings) return null

  try {
    const parsed = JSON.parse(parsedSearchQuery.filterSettings)
    if (!isValid(parsed)) return null
    return parsed
  } catch (e) {
    return null
  }
}

export const isValid = ({ filter, sort }: Partial<FilterSettings>) => {
  if (!filter || !sort) return false

  if (!Array.isArray(filter) || !filter.every(Array.isArray)) {
    return false
  }

  if (!Array.isArray(sort) || sort.length !== 2) return false

  if (!ProductFilterSortCategory.ALL_SORT_PATHS.includes(sort[0])) return false

  if (!Object.values(SortOrder).includes(sort[1])) return false

  return true
}
