import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { ThemeConstants } from '../../styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    doctorName: { fontWeight: 500, textTransform: 'uppercase' },
    defaultDoctorName: { fontWeight: 500 },
    doctorCardContainer: {
      flexGrow: 1,
      color: theme.palette.text.secondary,
      cursor: 'pointer',
      paddingBottom: 6,
      paddingTop: 6,
    },
    doctorList: theme.mixins.listUnstyled,
    selectableDoctor: {
      display: 'flex',
      padding: theme.spacing(2, 0),
      marginLeft: theme.spacing(-1),
    },
    radioButton: {
      alignSelf: 'start',
      position: 'relative',
      top: -6,
    },
    doctorNotShownText: {
      padding: theme.spacing(4, 0),
    },
    checkoutBttn: {
      margin: theme.spacing(2, 'auto', 2, 'auto'),
    },
    viewContainer: {
      paddingBottom: theme.spacing(ThemeConstants.BASE_SPACING),
      paddingTop: theme.spacing(ThemeConstants.BASE_SPACING),
    },
    noResultsWrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    textCenter: { textAlign: 'center' },
    disclaimer: {
      paddingBottom: theme.spacing(2),
      fontSize: 14,
    },
    subHeader: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 16,
      textAlign: 'center',
      paddingTop: theme.spacing(3),
    },
    emailNotice: {
      fontSize: 12,
      textAlign: 'center',
    },
  })
)
