import React from 'react'
import { Container, Theme, useMediaQuery } from '@material-ui/core'
import classnames from 'classnames'
import useStyles from './Banner.useStyles'

type BannerSimple = {
  adornmentPosition?: 'left' | 'right'
  adornmentUrl?: string
  children: React.ReactNode
  onAdornmentClick?: never
  adornmentAlt?: never
}

type BannerClickableAdornment = {
  adornmentPosition?: 'left' | 'right'
  adornmentUrl?: string
  children: React.ReactNode
  onAdornmentClick: () => void
  adornmentAlt: string
}

export type BannerProps = BannerSimple | BannerClickableAdornment

const Banner = ({
  children,
  adornmentPosition = 'left',
  adornmentUrl,
  onAdornmentClick,
  adornmentAlt,
}: BannerProps) => {
  const classes = useStyles({ adornmentUrl })
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const adornmentDecorator = {
    role: 'button',
    tabIndex: 0,
    onClick: onAdornmentClick,
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        onAdornmentClick?.()
      }
    },
    'aria-label': adornmentAlt,
  }
  const adornmentComponent = (
    <div
      className={classnames(classes.adornment, {
        [classes.pointerCursor]: !!onAdornmentClick,
        [classes.adornmentLeftAligned]: adornmentPosition === 'left',
        [classes.adornmentRightAligned]: adornmentPosition === 'right',
      })}
      {...(onAdornmentClick ? adornmentDecorator : {})}
    />
  )

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.contentRoot}>
          {adornmentUrl && adornmentComponent}
          <div
            className={classnames(classes.content, {
              [classes.contentPaddingLeft]: isMobile
                ? adornmentUrl && adornmentPosition === 'left'
                : !!adornmentUrl,
              [classes.contentPaddingRight]: isMobile
                ? adornmentUrl && adornmentPosition === 'right'
                : !!adornmentUrl,
            })}
          >
            {children}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Banner
