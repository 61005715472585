import useStyles from '../InsuranceVerificationOverlay.styles'

export const Arch = () => {
  const classes = useStyles()
  return (
    <svg
      className={classes.arch}
      fill="none"
      height="165"
      viewBox="0 0 136 165"
      width="136"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.9999 0C30.4626 0 1.00616e-05 32.499 1.00616e-05 72.5618L0 150.621C0 158.6 6.49202 165 14.399 165H120.352C129.008 165 136 158.018 136 149.374L136 72.5618C136.083 32.499 105.62 0 67.9999 0Z"
        fill="#EEE8E9"
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default Arch
