import { forwardRef } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { InputBase, InputBaseProps } from '@material-ui/core'
import classnames from 'classnames'
import useStyles from './PanelSearchField.styles'

const PanelSearchField = forwardRef<HTMLInputElement, InputBaseProps>(
  ({ classes = {}, className, ...rest }, ref) => {
    const css = useStyles()
    return (
      <InputBase
        className={classnames(css.input, className)}
        classes={{ input: classnames(css.inputRoot, classes.input) }}
        inputRef={ref}
        startAdornment={<SearchIcon color="primary" />}
        {...rest}
      />
    )
  }
)

export default PanelSearchField
