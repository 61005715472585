export type HeroImageUrl = {
  MOBILE: string
  DESKTOP: string
  WIDE: string
}

export const HERO_IMAGE_URL: HeroImageUrl = {
  MOBILE:
    '//images.babylist.com/image/upload/f_auto,q_auto/v1666813723/DME/NY-Cribs-Pump-46.jpg',
  DESKTOP:
    '//images.babylist.com/image/upload/f_auto,q_auto,h_1000/v1670540015/DME/img_hero_dme_high-res.png',
  WIDE: '//images.babylist.com/image/upload/f_auto,q_auto,h_1000/v1670540015/DME/img_hero_dme_high-res.png',
}

export const PARTS_HERO_IMAGE_URL: HeroImageUrl = {
  MOBILE:
    '//images.babylist.com/image/upload/v1707229381/DME/dme_replacement_parts_hero_image.png',
  DESKTOP:
    '//images.babylist.com/image/upload/v1707229381/DME/dme_replacement_parts_hero_image.png',
  WIDE: '//images.babylist.com/image/upload/v1707229381/DME/dme_replacement_parts_hero_image.png',
}
export const HERO_MASK =
  '//images.babylist.com/image/upload/v1664225578/hero-mask_kszb5j.svg'
export const THREE_STEPS_PARACHUTE_URL_IMG_URL =
  '//images.babylist.com/image/upload/v1682516479/DME/Package.svg'
export const THREE_STEPS_PORTRAIT_URL_IMG_URL =
  '//images.babylist.com/image/upload/v1682516479/DME/Paperwork.svg'
export const THREE_STEPS_PUMP_IMG_URL =
  '//images.babylist.com/image/upload/v1682516479/DME/Pump.svg'
export const PRESCRIPTION_FORM =
  '//images.babylist.com/image/upload/v1707859308/Breast-Pump-Prescription-Request-Form-2024.pdf'
export const THREE_STEPS_PUMP_BASE_IMG_URL =
  '//images.babylist.com/image/upload/v1707229380/DME/dme_pump.png'
