import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    linkWrapper: {
      cursor: 'pointer',
      color: theme.palette.common.white,
      [theme.breakpoints.up('xs')]: {
        '& $exclusionsApply': {
          marginLeft: theme.spacing(),
          fontSize: 14,
        },
        '&:hover': {
          '& $exclusionsApply, & $freePump': {
            textDecoration: 'underline',
          },
        },
      },
      [theme.breakpoints.down('xs')]: {
        '& $exclusionsApply': {
          marginLeft: theme.spacing(0.5),
          fontSize: 12,
        },
        '&:hover': {
          '& $exclusionsApply, & $freePump': {
            textDecoration: 'none',
          },
        },
      },
    },
    exclusionsApply: {},
    freePump: {},
  })
)
