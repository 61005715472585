import useStyles from '../InsuranceVerificationOverlay.styles'

export const Burst = () => {
  const classes = useStyles()
  return (
    <svg
      className={classes.burst}
      fill="none"
      height="160"
      viewBox="0 0 107 160"
      width="107"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.8715 2.02721L64.1322 22.1085L43.4479 10.9626C40.738 9.50299 37.4473 11.4555 37.4384 14.5374L37.3125 37.7989L13.8265 38.4884C10.7499 38.5793 8.87632 41.9155 10.4096 44.589L21.9309 64.7963L1.93612 77.1366C-0.682888 78.7535 -0.637342 82.5796 2.02722 84.1282L22.109 95.8678L10.9633 116.552C9.50363 119.262 11.4561 122.552 14.538 122.561L37.7987 122.687L38.4882 146.173C38.5791 149.25 41.9153 151.123 44.5888 149.59L64.7962 138.069L77.1368 158.064C78.7537 160.683 82.5798 160.637 84.1284 157.973L95.8681 137.891L116.552 149.037C119.262 150.496 122.553 148.544 122.562 145.462L122.688 122.201L146.174 121.511C149.25 121.42 151.124 118.084 149.591 115.411L138.069 95.2033L158.064 82.8632C160.683 81.2462 160.637 77.4201 157.973 75.8715L137.891 64.1319L149.037 43.4475C150.497 40.7377 148.544 37.447 145.462 37.4381L122.201 37.3122L121.512 13.8263C121.421 10.7497 118.085 8.87609 115.411 10.4093L95.2034 21.9308L82.8632 1.93612C81.2463 -0.682887 77.4202 -0.637341 75.8715 2.02721Z"
        fill="#EEE8E9"
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default Burst
