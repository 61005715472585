import { ReplacementPartsHeroProduct } from 'bl-health/types'
import { FormControl, FormHelperText, Grid } from '@material-ui/core'
import ProductModelSelector from 'bl-health/components/ProductModelSelector'
import { FragmentController } from 'bl-health/components/ReplacementPartsCatalogStrategy/utils/useReplacementPartsCatalogStrategy'
import css from './ReplacementPartsCatalogStrategy.styles.scss'

export interface ProductsFragmentProps {
  controller: FragmentController<ReplacementPartsHeroProduct>
}

export default function ProductsFragment({
  controller,
}: ProductsFragmentProps) {
  const { data, select, error } = controller

  if (!data) return null

  return (
    <Grid item data-testid="rp-products-fragment" xs={12}>
      <div className={css.ProductModelSelectorWrapper}>
        <ProductModelSelector
          error={!!error}
          label="Pump model"
          options={data}
          onSelect={select}
        />
        {!!error && (
          <FormControl fullWidth variant="outlined">
            <FormHelperText error={!!error}>{error}</FormHelperText>
          </FormControl>
        )}
      </div>
    </Grid>
  )
}
