import { ReactElement } from 'react'
import { HeroImageUrl } from 'bl-health/views/constants'
import useStyles from './styles'

interface HeroImageProps {
  heroImageUrl: HeroImageUrl
  children?: ReactElement | null
}
export const HeroImage = ({ heroImageUrl, children }: HeroImageProps) => {
  const classes = useStyles(heroImageUrl)

  return (
    <div className={classes.heroImageWrapper}>
      <div className={classes.heroImage}>{children}</div>
    </div>
  )
}
