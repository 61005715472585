import { Box, Grid, Link, Typography } from '@material-ui/core'
import Button from 'baby-ui/foundation/Button'
import { OpenInNewWindowIcon } from 'components/icons'
import {
  HEALTH_CONTACT_EMAIL,
  PUMP_PARTS_STORE_PAGE_URL,
} from 'bl-health/constants'
import css from './ReplacementPartsCatalogStrategy.styles.scss'

export default function ReplacementPartsCatalogStrategyError() {
  return (
    <div className={css.ReplacementPartsCatalogStrategy}>
      <Box m="auto" maxWidth={400} paddingBottom={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={css.ReplacementPartsCatalogStrategy__hero}>
              <p className={css.ReplacementPartsCatalogStrategy__hero__title}>
                We're sorry, we don't currently support replacement parts for
                your insurance.
              </p>
              <Typography variant="body2">
                It's likely your insurance covers replacement parts! Please
                contact them to discuss your options.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={css.ReplacementPartsCatalogStrategy__hero__title}
              color="primary"
              component="h2"
              style={{ fontSize: 20 }}
              variant="h5"
            >
              Need parts now?
            </Typography>

            <Typography gutterBottom variant="body2">
              We should have what you need on the Babylist Shop.
            </Typography>

            <Button
              color="inherit"
              endIcon={<OpenInNewWindowIcon />}
              href={PUMP_PARTS_STORE_PAGE_URL}
              style={{ textDecoration: 'underline', paddingLeft: 0 }}
              variant="text"
            >
              Shop for Pump Parts
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography
              className={css.ReplacementPartsCatalogStrategy__hero__title}
              color="primary"
              component="h2"
              style={{ fontSize: 20 }}
              variant="h5"
            >
              We're here to help.
            </Typography>

            <Typography gutterBottom variant="body2">
              Still have questions or think there's been an error? Email{' '}
              <Link
                color="inherit"
                href={`mailto:${HEALTH_CONTACT_EMAIL}`}
                style={{ textDecoration: 'underline' }}
              >
                {HEALTH_CONTACT_EMAIL}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
