import { CurrentUser } from '../../../global'
import { getCurrentUser } from '../getCurrentUser/getCurrentUser'

export type OnCurrentExternalLoginCallback = (
  currentUser: CurrentUser | undefined
) => unknown

/**
 * Function to register a callback to fire when the current user logs in from another window.
 * This allows listening for that event and executing a callback without having to involve normal cross-window
 * communication such as postMessage or broadcast.
 */
const onCurrentUserExternalLogin = (
  callback: OnCurrentExternalLoginCallback
): (() => void) => {
  if (typeof window !== 'undefined') {
    const CurrentUserCallback = (event: StorageEvent) => {
      if (event.key === 'currentUser' && !event.oldValue && event.newValue) {
        const currentUser = getCurrentUser()

        if (currentUser) {
          callback(currentUser)
        }
      }
    }

    window.addEventListener('storage', CurrentUserCallback)

    return () => {
      window.removeEventListener('storage', CurrentUserCallback)
    }
  }

  return () => ({})
}

export default onCurrentUserExternalLogin
