import { Typography, useMediaQuery } from '@material-ui/core'
import useStyles from '../../widgets/HeroCTAWidget/styles'

interface HeroCopyProps {
  title: string[]
  mobileTitle?: string[]
  subtitle: string[]
}

export const HeroCopy = ({ title, mobileTitle, subtitle }: HeroCopyProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const finalTitle = isMobile && mobileTitle ? mobileTitle : title

  return (
    <span className={classes.centerTextOnMobile}>
      <Typography component="h1" variant="h4">
        {finalTitle.map((line, index) => (
          <span key={index} style={{ fontWeight: 500 }}>
            {line}
            <br />
          </span>
        ))}
      </Typography>
      <Typography component="h2" variant="body1">
        {subtitle.map((line, index) => (
          <span key={index}>
            {line} {isMobile && <br />}
          </span>
        ))}
      </Typography>
    </span>
  )
}
