import * as React from 'react'
import useStyles from './PanelFooter.styles'

export interface PanelFooter {
  children: React.ReactNode
}

const PanelFooter = ({ children }: PanelFooter) => {
  const classes = useStyles()
  return <div className={classes.panelFooter}>{children}</div>
}

export default PanelFooter
