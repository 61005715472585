export type ReplacementPartsHeroProduct = APIResponse.HeroProduct
export type ReplacementPartsHeroProductCombined =
  APIResponse.CombinedHeroProduct
export type ReplacementPartsCatalogCategoryProduct =
  APIResponse.CatalogCategoryProduct

export interface ReplacementPartsCatalog {
  brand: string
  pumps: ReplacementPartsHeroProduct[]
}
