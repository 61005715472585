export const ExternalIconSvg = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8332 6.53046H6.46764C6.07702 6.53046 5.76036 6.84712 5.76036 7.23774C5.76036 7.62836 6.07702 7.94502 6.46764 7.94502L11.1256 7.94502L5.26023 13.8104C4.98402 14.0866 4.98402 14.5345 5.26023 14.8107C5.53644 15.0869 5.98427 15.0869 6.26048 14.8107L12.1259 8.94526L12.1259 13.6033C12.1259 13.9939 12.4426 14.3105 12.8332 14.3105C13.2238 14.3105 13.5405 13.9939 13.5405 13.6033V7.23774C13.5405 6.84712 13.2238 6.53046 12.8332 6.53046ZM5.40711 16.7857C5.01649 16.7857 4.69983 17.1024 4.69983 17.493C4.69983 17.8836 5.01649 18.2003 5.40711 18.2003H12.4799C12.8705 18.2003 13.1872 17.8836 13.1872 17.493C13.1872 17.1024 12.8705 16.7857 12.4799 16.7857H5.40711Z"
        fill="#6E3264"
      />
    </svg>
  )
}
