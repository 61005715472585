import { ApplicationStrategy } from 'bl-health/types'

export default function createNameSpacedPath(
  pathname: string,
  strategy?: ApplicationStrategy
) {
  if (strategy === ApplicationStrategy.Parts) {
    const paths = pathname.split('/').filter((path) => path)
    const first = paths.shift()
    return `/${first}/parts/${paths.join('/')}`
  }

  return pathname
}
