import fetch from 'lib/fetch'
import { CUSTOMER_PHYSICIAN_ENDPOINT } from './constants'
import { RequestMethod } from './RequestMethod'

export type CustomerPhysicianPayload = Pick<
  APIResponse.DME.Physician,
  'npi'
> & {
  npiLastUpdatedAt: string
  hasRx: boolean
}

export default function createCustomerPhysician(
  payload: CustomerPhysicianPayload
): Promise<APIResponse.DME.Physician[]> {
  return fetch(CUSTOMER_PHYSICIAN_ENDPOINT, {
    method: RequestMethod.POST,
    body: JSON.stringify(payload),
  })
}
