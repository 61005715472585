import { Link } from '@material-ui/core'
import { PRESCRIPTION_GUIDE } from 'bl-health/constants'
import { PRESCRIPTION_FORM } from '../../constants'
import { ContentBlock, ContactLink } from '../components'
import css from './PrescriptionInvalidReasons.styles.scss'

interface PrescriptionInvalidReasonsProps {
  prescriptionInvalidReasons: string[]
}

export default function PrescriptionInvalidReasons({
  prescriptionInvalidReasons,
}: PrescriptionInvalidReasonsProps) {
  return (
    <ContentBlock
      testId="prescription-invalid-reasons"
      title="Invalid Rx"
      variant="warn"
    >
      <p className={css.prescription_invalid_reasons__p}>
        We've received an invalid prescription form and are working to sort it
        out with your provider.
      </p>
      Why your prescription is invalid:
      <ul>
        {prescriptionInvalidReasons.map((reason) => (
          <li key={reason}>{reason}</li>
        ))}
      </ul>
      <p className={css.prescription_invalid_reasons__p}>
        If you'd like to expedite this you can take our{' '}
        <Link
          download
          className={css.prescription_invalid_reasons__link}
          href={PRESCRIPTION_FORM}
          target="_blank"
        >
          prescription form
        </Link>{' '}
        to your provider and send it back to us at <ContactLink variant="rx" />{' '}
      </p>
      <p className={css.prescription_invalid_reasons__p}>
        Need guidance with the form?{' '}
        <Link
          className={css.prescription_invalid_reasons__link}
          href={PRESCRIPTION_GUIDE}
          target="_blank"
        >
          See example
        </Link>
      </p>
      Need to update your personal information with us? Please email{' '}
      <ContactLink variant="health" />
    </ContentBlock>
  )
}
