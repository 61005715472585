import fetch from 'lib/fetch'
import { ReplacementPartsCatalog } from 'bl-health/types'
import { REPLACEMENT_PARTS_CATALOG_ENDPOINT } from 'bl-health/requests/constants'
import { RequestMethod } from './RequestMethod'

type Response = { data: ReplacementPartsCatalog[] }

export default async function getReplacementPartsCatalog(): Promise<Response> {
  return fetch(REPLACEMENT_PARTS_CATALOG_ENDPOINT, {
    method: RequestMethod.GET,
  })
}
