import { values } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import css from './spinner.scss'

const SIZES = {
  SMALL: 'small',
  LARGE: 'large',
}

const Spinner = ({ className, inverse, size }) => {
  let spinnerClass = classNames([css.BLSpinner], {
    [css.BLSpinnerSm]: size == SIZES.SMALL,
    [css.BLSpinnerLg]: size == SIZES.LARGE,
  })

  return (
    <span
      className={className}
      dangerouslySetInnerHTML={{
        __html: `
      <svg class="${
        spinnerClass || null
      }" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1" width="64" height="64" viewBox="0 0 128 128" xml:space="preserve"><rect x="0" y="0" width="100%" height="100%" fill="transparent"/><g><path d="M75.4 126.63a11.43 11.43 0 0 1-2.1-22.65 40.9 40.9 0 0 0 30.5-30.6 11.4 11.4 0 1 1 22.27 4.87h.02a63.77 63.77 0 0 1-47.8 48.05v-.02a11.38 11.38 0 0 1-2.93.37z" ${
        inverse ? 'fill="#fff"' : 'fill="#a5a5a5"'
      } fill-opacity="1"/><animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite"/></g></svg>
      `,
      }}
    />
  )
}

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(values(SIZES)),
}

export default Spinner
