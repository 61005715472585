import './dme.scss'
import { useEffect } from 'react'
import {
  IndexRoute,
  Route,
  RouteComponentProps,
  Router,
  withRouter,
} from 'react-router'
import { getHistory } from 'lib/history'
import {
  useTracking,
  withContextualizedTracking,
} from 'lib/babylistHealthAnalytics'
import { createNameSpacedPath } from 'bl-health/utils'
import { ApplicationContext, ApplicationSettings } from 'bl-health/types'
import ReplacementPartsIneligibleView from 'bl-health/views/ReplacementPartsIneligibleView/ReplacementPartsIneligibleView'
import {
  customerInformationFormViewedEvent,
  physicianSearchViewedEvent,
} from 'bl-health/events'
import { HomeViewProps } from './views/HomeView'
import CustomerInformationView from './views/CustomerInformationView'
import { LayoutContainer, LayoutPresenter } from './widgets/Layout'
import {
  CUSTOMER_INFORMATION_PATH,
  EXISTING_CUSTOMER_PATH,
  INELIGIBLE_PARTS_PATH,
  ORDER_PATH,
  PHYSICIAN_RESULT_PATH,
  SEARCH_PHYSICIAN_PATH,
  SUBSCRIPTIONS_PATH,
} from './constants'
import { GuidesViewProps } from './views/GuidesView'
import PhysicianSearchView from './views/PhysicianSearchView'
import NotificationWidget from './widgets/NotificationWidget'
import PhysicianResultView from './views/PhysicianResultView'
import OrderView from './views/OrderView'
import ReplacementPartsView, {
  CustomerInsuranceProps,
} from './views/ReplacementPartsView'
import ReplacementPartsExistingCustomerView from './views/ReplacementPartsExistingCustomerView'

export interface DMEAppProps {
  homeView: HomeViewProps
  guidesView: GuidesViewProps
  settings: ApplicationSettings
  pdpProps: APIResponse.GenericProduct[]
  orderProps?: APIResponse.DME.Order
  catalogCategoryProps?: APIResponse.CatalogCategory
  customerInsuranceProps?: CustomerInsuranceProps
  subscriptionProps?: APIResponse.DME.Subscription
  invalidPartsOrderProps?: APIResponse.DME.SubscriptionError
  partsIneligibility?: APIResponse.DME.SubscriptionIneligibilityErrorType
  context: ApplicationContext
}
interface OrderRouteParams {
  id: string
}

type RouteComponentWithoutParams = RouteComponentProps<
  Record<string, never>,
  Record<string, never>
>

const DMEApp = ({
  settings,
  orderProps,
  catalogCategoryProps,
  customerInsuranceProps,
  subscriptionProps,
  invalidPartsOrderProps,
  partsIneligibility,
  context,
}: DMEAppProps) => {
  const tracker = useTracking()
  const history = getHistory({
    path: window.location.pathname,
    useRouter: true,
  })

  const firePhysicianSearchViewedEvent = () => {
    physicianSearchViewedEvent(tracker)()
  }

  const fireCustomerInformationFormViewedEvent = () => {
    customerInformationFormViewedEvent(tracker)()
  }

  useEffect(() => {
    history.listen(() => {
      window.scrollTo(0, 0)
    })
  }, [])

  return (
    <LayoutContainer context={context} settings={settings}>
      <Router history={history}>
        <Route component={LayoutPresenter}>
          <Route
            path={createNameSpacedPath(
              CUSTOMER_INFORMATION_PATH,
              context?.strategy
            )}
          >
            <IndexRoute
              component={(props: RouteComponentWithoutParams) => (
                <CustomerInformationView
                  {...props}
                  onComponentDidMount={fireCustomerInformationFormViewedEvent}
                />
              )}
            />
            <Route
              component={(props: RouteComponentWithoutParams) => (
                <PhysicianSearchView
                  {...props}
                  onComponentDidMount={firePhysicianSearchViewedEvent}
                />
              )}
              path={createNameSpacedPath(
                SEARCH_PHYSICIAN_PATH,
                context?.strategy
              )}
            />
          </Route>
          <Route
            component={PhysicianResultView}
            path={createNameSpacedPath(
              PHYSICIAN_RESULT_PATH,
              context?.strategy
            )}
          />
          <Route
            component={(
              routeProps: RouteComponentProps<
                OrderRouteParams,
                OrderRouteParams
              >
            ) =>
              subscriptionProps &&
              catalogCategoryProps &&
              customerInsuranceProps ? (
                <ReplacementPartsView
                  {...routeProps}
                  heroProducts={catalogCategoryProps.data.heroProducts}
                  insurance={customerInsuranceProps}
                  subscription={subscriptionProps}
                  subscriptionError={invalidPartsOrderProps}
                  onComponentDidMount={() => undefined}
                />
              ) : null
            }
            path={SUBSCRIPTIONS_PATH}
          />
          <Route
            component={ReplacementPartsExistingCustomerView}
            path={EXISTING_CUSTOMER_PATH}
          />
          <Route
            component={(
              routeProps: RouteComponentProps<
                OrderRouteParams,
                OrderRouteParams
              >
            ) =>
              partsIneligibility ? (
                <ReplacementPartsIneligibleView
                  {...routeProps}
                  error={partsIneligibility}
                />
              ) : null
            }
            path={INELIGIBLE_PARTS_PATH}
          />
        </Route>
        <Route
          component={(
            routeProps: RouteComponentProps<OrderRouteParams, OrderRouteParams>
          ) =>
            orderProps ? (
              <OrderView
                {...routeProps}
                order={orderProps}
                onComponentDidMount={() => undefined}
              />
            ) : null
          }
          path={ORDER_PATH}
        />
      </Router>
      <NotificationWidget />
    </LayoutContainer>
  )
}

const DMEAppWithDebugging = withRouter(DMEApp)
export default withContextualizedTracking()(DMEAppWithDebugging)
