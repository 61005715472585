import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import LoadingButton from 'baby-ui/compounds/LoadingButton'
import useStyles from './EmailForm.styles'
import PaperAirplaneIcon from '../../icons/PaperAirplaneIcon'

interface EmailFormConfirmationProps {
  cta: string
  onClick?: () => void
  disabled?: boolean
}

export const EmailFormConfirmation = ({
  cta,
  onClick,
  disabled,
}: EmailFormConfirmationProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isAboveSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <div className={classes.confirmationContainer}>
      {isAboveSmallScreen && <PaperAirplaneIcon />}
      <Box className={classes.confirmationTextBox} pt={6}>
        <Typography className={classes.confirmationText} variant="body1">
          <strong>We are super pumped for you.</strong>
        </Typography>
        <Typography className={classes.confirmationText} variant="body1">
          <strong>Goodness is coming to your inbox!</strong>
        </Typography>
      </Box>
      {!isAboveSmallScreen && (
        <Box pb={4} pt={2}>
          <PaperAirplaneIcon />
        </Box>
      )}
      <Box className={classes.confirmationButtonContainer} px={2} py={4}>
        <LoadingButton
          fullWidth
          className={classes.confirmationButton}
          color="secondary"
          disabled={disabled}
          type="button"
          variant="contained"
          onClick={onClick}
        >
          {cta}
        </LoadingButton>
      </Box>
    </div>
  )
}

export default EmailFormConfirmation
