import {
  Box,
  Container,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { HERO_IMAGE_URL } from 'bl-health/views/constants'
import { PARTS_HOME_PATH } from 'bl-health/constants'
import InsurancePickerWidget from '../InsurancePickerWidget'
import StarburstBadge from '../../../baby-ui/compounds/StarburstBadge'
import useStyles from './styles'
import { HeroCopy, HeroImage } from '../../components'

const heroCopy = {
  title: [
    'You pick the pump.',
    "We'll help you get it for free through insurance.",
  ],
  subtitle: [],
}

export default function HeroBannerWidget() {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  return (
    <>
      <div>
        <HeroImage heroImageUrl={HERO_IMAGE_URL}>
          {isDesktop ? (
            <Container className={classes.heroContainer}>
              <Box
                className={classes.overlay}
                color="primary.contrastText"
                p={6}
                pl={8}
                pt={9}
              >
                <Box pr={4}>
                  <HeroCopy {...heroCopy} />
                </Box>
                <Box py={3}>
                  <InsurancePickerWidget />
                </Box>
                <Typography align="left" variant="body2">
                  If you're already pumping, we can help you get{' '}
                  <Link
                    className={classes.disclaimerLink}
                    color="inherit"
                    href={PARTS_HOME_PATH}
                  >
                    free replacement parts
                  </Link>{' '}
                  through insurance, too!
                </Typography>
              </Box>
            </Container>
          ) : null}
        </HeroImage>

        <Container className={classes.starburstContainer}>
          <StarburstBadge
            className={classes.starburst}
            label={
              <Box color="primary.contrastText">
                <Typography
                  style={{ lineHeight: 1.1, fontSize: 24, fontWeight: 'bold' }}
                  variant="body1"
                >
                  YOU
                  <br />
                  GOT
                  <br />
                  THIS!
                </Typography>
              </Box>
            }
          />
        </Container>

        {!isDesktop && (
          <Container className={classes.mobileFormContainer}>
            <Box pt={4}>
              <HeroCopy {...heroCopy} />
            </Box>
            <Box pt={3}>
              <InsurancePickerWidget />
            </Box>
          </Container>
        )}
      </div>
    </>
  )
}
