import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface BannerClickedEventMetadata {
  bannerName: string
}

export const bannerClickedEvent =
  (tracker: any) =>
  ({ bannerName }: BannerClickedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.bannerClicked,
      bannerName,
    })
    tracker.trackEvent(payload)
  }
