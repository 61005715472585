import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { track, useTracking } from 'lib/babylistHealthAnalytics'
import EmailVerificationWidget from 'bl-health/widgets/EmailVerificationWidget/EmailVerificationWidget'
import { instanceOfOTPRequiredResponse } from 'bl-health/utils'
import useMilkBagsCartAddOn from 'bl-health/views/PhysicianResultView/PhysicianSelectorWidget/useMilkBagsCartAddOn'
import PreCheckoutMilkBagWidget from 'bl-health/widgets/PreCheckoutWidget/PreCheckoutMilkBagWidget'
import {
  milkBagsOfferedEvent,
  preCheckoutModalCompletedEvent,
} from 'bl-health/events'
import { UI } from '../../../global'
import { SUBSCRIPTIONS_PATH } from '../../constants'
import ReplacementPartsForm from '../../forms/ReplacementPartsForm'
import { configureView } from '../../utils/ViewConfiguration'
import ReplacementPartsOrderBlocker, {
  ReplacementPartsOrderBlockerProps,
} from './ReplacementPartsOrderBlocker'
import InsuranceVerificationFailed from './InsuranceVerificationFailed/InsuranceVerificationFailed'
import getSubscriptionProducts from '../../requests/getSubscriptionProducts'
import useCheckout from '../../forms/ReplacementPartsForm/useCheckout'
import { verifyOrderInsurance } from '../../requests'
import { insuranceVerificationErrorTypeAdapter } from '../../forms/ReplacementPartsForm/ReplacementPartsForm.utils'
import apiErrorResponseAdapter, {
  Error,
} from '../../utils/apiErrorResponseAdapter'
import { SUBSCRIPTION_ERROR_TYPES } from '../../forms/ReplacementPartsForm/errorTypes'
import { errorThrownEvent } from '../../events/errorThrownEvent'
import useMinWaitTimer from '../../shared/useMinWaitTimer'
import { useDMEStore } from '../../store'

export interface CustomerInsuranceProps {
  id: APIResponse.CustomerInsurance['id']
}
export interface ReplacementPartsViewProps extends UI.LifeCycle {
  heroProducts: APIResponse.HeroProduct[]
  insurance: CustomerInsuranceProps
  subscription: APIResponse.DME.Subscription
  subscriptionError?: APIResponse.DME.SubscriptionError
}

const interceptableErrors = [
  'INSURANCE_VERIFICATION_FAILED_INACTIVE',
  'INSURANCE_VERIFICATION_FAILED_INVALID',
  'INSURANCE_UNSUPPORTED',
  'INSURANCE_LIMIT_REACHED',
  'NO_PARTS_AVAILABLE',
]

const takeoverErrorType = (
  e: APIResponse.DME.SubscriptionErrorType | null
): ReplacementPartsOrderBlockerProps['error'] | null =>
  e &&
  e !== 'INSURANCE_VERIFICATION_FAILED_INACTIVE' &&
  e !== 'INSURANCE_VERIFICATION_FAILED_INVALID' &&
  e !== 'INSURANCE_UNSUPPORTED' &&
  e !== 'INSURANCE_LIMIT_REACHED' &&
  e !== 'NO_PARTS_AVAILABLE'
    ? e
    : null

const isProductIncompatibilityError = (
  e: ReturnType<typeof apiErrorResponseAdapter>
) =>
  // TODO: Make this less brittle. We shouldn't depend on the human-readable error message,
  // but the API response does not contain any other information about the error.
  e.message.match(/not compatible with your insurance/)

const ReplacementPartsView: UI.ViewComponent<ReplacementPartsViewProps> =
  observer(
    ({
      heroProducts: initialHeroProducts,
      insurance,
      subscription,
      subscriptionError,
      onComponentDidMount,
    }: ReplacementPartsViewProps) => {
      const tracker = useTracking()
      const store = useDMEStore()

      const [error, setError] =
        useState<APIResponse.DME.SubscriptionErrorType | null>(
          subscriptionError?.error || null
        )
      const [formErrorMessage, setFormErrorMessage] = useState('')

      const [customerInsuranceId, setCustomerInsuranceId] = useState<
        APIResponse.CustomerInsurance['id']
      >(insurance.id)

      const [showPreCheckoutMilkBagModal, setShowPreCheckoutMilkBagModal] =
        useState(false)
      const milkBagsCartAddOn = useMilkBagsCartAddOn()

      const firePreCheckoutModalCompletedEvent = () => {
        preCheckoutModalCompletedEvent(tracker)({
          freeGiftSelected: store.cart.checkoutPayload.includeFreeGift,
          milkBagsSelected: store.cart.checkoutPayload.includeMilkBags,
          manualReviewRequired: store.insurance.manualReviewRequired,
        })
      }

      const fireCheckout = () => {
        firePreCheckoutModalCompletedEvent()
        checkout()
      }

      const { data: heroProducts = [], refetch: refetchProducts } = useQuery({
        queryKey: ['subscriptionHeroProducts'],
        queryFn: getSubscriptionProducts.bind(null, subscription.id),
        refetchOnWindowFocus: false,
        initialData: initialHeroProducts,
      })
      const { mutate: verifyInsurance, isLoading: isInsuranceLoading } =
        useMutation({
          mutationFn: verifyOrderInsurance,
          onSuccess: () => {
            timedOverlay.wait(() => {
              goToMilkBagOrCheckout()
            })
          },
          onError: (e?: APIResponse.StandardError) => {
            timedOverlay.wait(() => {
              const adaptedError = insuranceVerificationErrorTypeAdapter(e)
              setError(adaptedError)
            })
          },
        })
      const timedOverlay = useMinWaitTimer(
        5000,
        () => {
          store.ui.globalComponents.Overlay.show('insuranceVerification')
        },
        () => {
          store.ui.globalComponents.Overlay.close('insuranceVerification')
        }
      )

      const checkoutErrorHandler = (e: Error) => {
        if (instanceOfOTPRequiredResponse(e) && e.otpRequired) {
          setOTPWidget({
            open: true,
            email: e.customerEmail,
            customerId: e.customerId,
          })
          return
        }

        const adaptedError = apiErrorResponseAdapter(e)
        errorThrownEvent(tracker)({
          error: adaptedError.message,
        })

        if (SUBSCRIPTION_ERROR_TYPES.includes(adaptedError.message)) {
          setError(
            adaptedError.message as APIResponse.DME.SubscriptionErrorType
          )
        } else {
          if (isProductIncompatibilityError(adaptedError)) {
            refetchProducts()
          }
          setFormErrorMessage(adaptedError.message)
        }
      }

      const [otpWidget, setOTPWidget] = useState({
        open: false,
        customerId: 0,
        email: '',
      })

      const {
        checkout,
        setCheckoutParams,
        isLoading: isCheckoutLoading,
        isNavigating,
      } = useCheckout(
        {
          customerInsuranceId,
          subscriptionId: subscription.id,
          addOns:
            store.cart.includeMilkBags && milkBagsCartAddOn.addOn
              ? [milkBagsCartAddOn.addOn]
              : undefined,
        },
        checkoutErrorHandler
      )

      const goToMilkBagOrCheckout = () => {
        if (milkBagsCartAddOn.addOn) {
          setShowPreCheckoutMilkBagModal(true)
        } else {
          fireCheckout()
        }
      }

      useEffect(() => {
        onComponentDidMount()
      }, [])

      const handleInsuranceFormSuccess = ({
        id,
      }: APIResponse.CustomerInsurance) => {
        refetchProducts()
        setCustomerInsuranceId(id)
        setError(null)
        goToMilkBagOrCheckout()
      }

      const handleCheckoutFormSubmit = (params: {
        productId: number
        aobAcknowledged: boolean
      }) => {
        // The price of the selected product isn't particularly valuable for the UX logic,
        // but we need to pass it to checkout for event tracking purposes.
        // See `addToCartEvent` within the `useCheckout` hook.
        const selectedProduct = heroProducts
          .map(
            (heroProduct) =>
              heroProduct.products || heroProduct.availableProducts
          )
          .flat()
          .find((p) => p.id === params.productId)
        const productPrice = Number(selectedProduct?.price || 0)

        setCheckoutParams({
          productId: params.productId,
          productPrice,
          aobAcknowledged: params.aobAcknowledged,
        })
        timedOverlay.start()
        verifyInsurance({ customerInsuranceId })
      }

      if (error && interceptableErrors.includes(error)) {
        return (
          <InsuranceVerificationFailed
            customerId={subscription.dmeCustomerId}
            error={error}
            subscriptionId={subscription.id}
            onSuccess={handleInsuranceFormSuccess}
          />
        )
      }

      const takeoverError = takeoverErrorType(error)
      if (takeoverError) {
        return (
          <ReplacementPartsOrderBlocker
            error={takeoverError}
            subscription={subscription}
          />
        )
      }

      return (
        <>
          <EmailVerificationWidget
            generateCodeOnOpen
            customerId={otpWidget.customerId}
            email={otpWidget.email}
            open={otpWidget.open}
            onClose={() => {
              setOTPWidget((prevState) => ({
                ...prevState,
                open: false,
              }))
            }}
            onFormSubmitted={({ id }) => {
              if (id === otpWidget.customerId) {
                goToMilkBagOrCheckout()
                setOTPWidget((prevState) => ({
                  ...prevState,
                  open: false,
                }))
              }
            }}
          />
          <ReplacementPartsForm
            formErrorMessage={formErrorMessage}
            heroProducts={heroProducts}
            loading={isInsuranceLoading || isCheckoutLoading || isNavigating}
            onError={setError}
            onSubmit={handleCheckoutFormSubmit}
          />
          <PreCheckoutMilkBagWidget
            finalCheckoutLoading={isCheckoutLoading}
            milkBagsCount={milkBagsCartAddOn.unitCount}
            open={showPreCheckoutMilkBagModal}
            onConfirm={fireCheckout}
            onShow={() => {
              milkBagsOfferedEvent(tracker)({
                location: track.EventLocation.PARTS_SUBSCRIPTION,
              })
            }}
          />
        </>
      )
    }
  )

export default configureView(ReplacementPartsView)({
  pathname: SUBSCRIPTIONS_PATH,
})
