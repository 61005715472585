import { Location } from 'react-router-dom-v5-compat'
import { Product } from 'src/types/product'
import { track, TrackImpression } from 'lib/analytics'
import { ReactNode } from 'react'
import {
  getSearchId,
  PRODUCT_SEARCH_INDEX_KEY,
} from 'shared/hooks/useSearchId/useSearchId'
import { getSearchPreviewIds } from 'components/global-nav/components/SearchBar/SearchBar.utils'
import { RegItem } from 'src/types/regItem'
import { TrackingHook } from 'react-tracking'
import { getCollaboratorRole } from '../../../../utils'
import { gtagEvents } from '../../../../store/components/product-details-page/helpers'
import { CurrentUser } from '../../../../global'

interface TrackPDPImpressionProps {
  activeProduct: Product
  children: ReactNode
}

interface TrackAddToCartProps {
  product: Product
  quantity: number
  price: number | undefined
  tracking: any
}

interface TrackAddToRegistryProps {
  currentUser: CurrentUser
  product: Product
  quantity: number
  regItem: RegItem
  tracking: any
}

interface TrackPDPContentInteractionArgs {
  isExpanded: boolean
  trackEvent: TrackingHook['trackEvent']
}

interface TrackPDPToolTipInteractionArgs {
  tooltipEventType: string
  trackEvent: TrackingHook['trackEvent']
}

interface TrackRegistryDiscountToggled {
  regDiscountApplied: boolean
  trackEvent: TrackingHook['trackEvent']
}

export const currentTrackingUrl = () =>
  typeof window === 'undefined' ? '' : window?.location?.href

export const trackPDPPageView = (location: Location) => {
  if (typeof window !== 'undefined' && typeof window.ga !== 'undefined') {
    window.ga('send', 'pageview', location.pathname)
  }
}

export const trackPDPPageChange = (activeProduct: Product) => {
  if (typeof window !== 'undefined' && activeProduct) {
    window.fbq('track', 'ViewContent', {
      content_type: 'product',
      content_ids: [activeProduct.id],
    })

    gtagEvents.addRemarketing(activeProduct)
    gtagEvents.trackConversion()
  }
}

export const trackAddToCart = ({
  product,
  quantity,
  price,
  tracking,
}: TrackAddToCartProps) => {
  if (typeof window === 'undefined') return null

  const newCartItem = { product, quantity, giftCardPrice: price }
  const searchId = getSearchId(PRODUCT_SEARCH_INDEX_KEY) || ''
  const searchPreviewIds = getSearchPreviewIds()
  const productSaleType =
    product.priceDetails?.saleAttributes?.saleType === 'active_sale'
      ? 'price_sale'
      : product.priceDetails?.saleAttributes?.saleType

  const payload = {
    event: track.addedToCart,
    cartTypes: [track.CartTypes.STORE],
    cartStores: [track.CartStores.BABYLIST_STORE],
    isFsaHsaEligible: product?.fsaHsaEligible,
    storeAdded: track.StoreAdded.BABYLIST,
    eventType: track.EventType.ADDED_TO_STORE,
    product,
    newCartItem,
    searchId,
    searchPreviewIds,
    subscribeToMarketingEmail: false,
    ...(productSaleType && { saleType: [productSaleType] }),
  }

  tracking.trackEvent(payload)

  if (typeof window.ga !== 'undefined') {
    // @ts-ignore
    window.ga('send', 'event', {
      eventCategory: 'Button',
      eventAction: 'Click',
      eventLabel: 'addToCart',
      eventValue: product.price.current,
    })
  }
  gtagEvents.confirmConversion()

  // track add to cart for lookalike audience targeting
  // content_ids and content_type required for dynamic ads
  // https://developers.facebook.com/docs/facebook-pixel/reference/
  // https://developers.facebook.com/docs/facebook-pixel/implementation/dynamic-ads
  window.fbq('track', 'AddToCart', {
    content_type: 'product',
    content_ids: [product.id],
  })
}

export const trackAddToRegistry = ({
  currentUser,
  product,
  quantity,
  regItem,
  tracking,
}: TrackAddToRegistryProps) => {
  const searchId = getSearchId(PRODUCT_SEARCH_INDEX_KEY)
  const searchPreviewIds = getSearchPreviewIds()
  const productSaleType =
    product.priceDetails?.saleAttributes?.saleType === 'active_sale'
      ? 'price_sale'
      : product.priceDetails?.saleAttributes?.saleType

  const payload = {
    event: track.addedToRegistry,
    collaboratorRole: getCollaboratorRole(currentUser),
    isFirstRegItem: regItem.isFirstRegItem,
    isFsaHsaEligible: product?.fsaHsaEligible,
    product,
    regItem,
    regItemQuantity: quantity,
    searchId,
    searchPreviewIds,
    email: currentUser?.email,
    userId: currentUser?.id,
    ...(productSaleType && { saleType: [productSaleType] }),
  }

  tracking.trackEvent(payload)
}

export const trackRegDiscountShopYourRegistryLinkClick = (
  trackEvent: TrackingHook['trackEvent']
) => {
  trackEvent({
    event: track.pageInteraction,
    interactionTarget:
      track.InteractionTarget.REG_DISCOUNT_SHOP_YOUR_REGISTRY_LINK,
    eventType: track.EventType.CLICK,
  })
}

export const trackRegDiscountBrowseShopLinkClick = (
  trackEvent: TrackingHook['trackEvent']
) => {
  trackEvent({
    event: track.pageInteraction,
    interactionTarget: track.InteractionTarget.REG_DISCOUNT_BROWSE_SHOP_LINK,
    eventType: track.EventType.CLICK,
  })
}

export const trackRegDiscountLearnMoreLinkClick = (
  trackEvent: TrackingHook['trackEvent']
) => {
  trackEvent({
    event: track.pageInteraction,
    interactionTarget:
      track.InteractionTarget.REG_DISCOUNT_BANNER_LEARN_MORE_LINK,
    eventType: track.EventType.CLICK,
  })
}

export const trackReviewTeaserReviewsQuickLink =
  (trackEvent: TrackingHook['trackEvent']) => () => {
    trackEvent({
      event: track.pageInteraction,
      interactionTarget: track.InteractionTarget.PDP_REVIEWS_QUICK_LINK,
      eventType: track.EventType.CLICK,
    })
  }

export const trackReviewTeaserQnaQuickLink =
  (trackEvent: TrackingHook['trackEvent']) => () => {
    trackEvent({
      event: track.pageInteraction,
      interactionTarget: track.InteractionTarget.PDP_QNA_QUICK_LINK,
      eventType: track.EventType.CLICK,
    })
  }

export const trackBackToTopClick = (trackEvent: TrackingHook['trackEvent']) => {
  trackEvent({
    event: track.pageInteraction,
    interactionTarget: track.InteractionTarget.PDP_BACK_TO_TOP,
    eventType: track.EventType.CLICK,
  })
}

export const trackImageThumbnailClick = (
  trackEvent: TrackingHook['trackEvent']
) => {
  trackEvent({
    event: track.pageInteraction,
    interactionTarget: track.InteractionTarget.PDP_IMAGE_THUMBNAIL,
    eventType: track.EventType.CLICK,
  })
}

export const trackImageSwipe = (trackEvent: TrackingHook['trackEvent']) => {
  trackEvent({
    event: track.pageInteraction,
    interactionTarget: track.InteractionTarget.PDP_IMAGE_THUMBNAIL,
    eventType: track.EventType.SWIPED,
  })
}

export const trackBreadcrumbLinkClick = (
  trackEvent: TrackingHook['trackEvent']
) => {
  trackEvent({
    event: track.pageInteraction,
    interactionTarget: track.InteractionTarget.PDP_BREADCRUMB_LINK,
    eventType: track.EventType.CLICK,
  })
}

export const trackBreadcrumbExpandClick = (
  trackEvent: TrackingHook['trackEvent']
) => {
  trackEvent({
    event: track.pageInteraction,
    interactionTarget: track.InteractionTarget.PDP_BREADCRUMB_EXPAND,
    eventType: track.EventType.CLICK,
  })
}

export const trackPdpContentDescriptionInteraction = ({
  trackEvent,
  isExpanded,
}: TrackPDPContentInteractionArgs) => {
  const interactionTarget = isExpanded
    ? track.InteractionTarget.PDP_COLLAPSE_DESCRIPTION
    : track.InteractionTarget.PDP_EXPAND_DESCRIPTION

  trackEvent({
    event: track.pageInteraction,
    interactionTarget,
    eventType: track.EventType.CLICK,
  })
}

export const trackPdpContentDetailsInteraction = ({
  trackEvent,
  isExpanded,
}: TrackPDPContentInteractionArgs) => {
  const interactionTarget = isExpanded
    ? track.InteractionTarget.PDP_COLLAPSE_DETAILS
    : track.InteractionTarget.PDP_EXPAND_DETAILS

  trackEvent({
    event: track.pageInteraction,
    interactionTarget,
    eventType: track.EventType.CLICK,
  })
}

export const trackTooltipClicked = ({
  trackEvent,
  tooltipEventType,
}: TrackPDPToolTipInteractionArgs) => {
  trackEvent({
    event: track.tooltipClicked,
    eventType: tooltipEventType,
  })
}

export const TrackPDPImpression = ({
  activeProduct,
  children,
}: TrackPDPImpressionProps) => {
  const searchId = getSearchId(PRODUCT_SEARCH_INDEX_KEY)
  const searchPreviewIds = getSearchPreviewIds()
  const productSaleType =
    activeProduct.priceDetails?.saleAttributes?.saleType === 'active_sale'
      ? 'price_sale'
      : activeProduct.priceDetails?.saleAttributes?.saleType

  const payload = {
    event: track.pdpViewed,
    inStockSoonMessage: activeProduct.babylistMerchandiseNotInStock,
    isFsaHsaEligible: activeProduct.fsaHsaEligible,
    product: activeProduct,
    searchId,
    searchPreviewIds,
    ...(productSaleType && { saleType: [productSaleType] }),
  }

  return (
    <TrackImpression
      /*
      // @ts-ignore */
      eventFireCondition={currentTrackingUrl()}
      eventProperties={payload}
    >
      <>{children}</>
    </TrackImpression>
  )
}

export const trackRegistryDiscountToggled = ({
  trackEvent,
  regDiscountApplied,
}: TrackRegistryDiscountToggled) => {
  trackEvent({
    event: track.registryDiscountToggled,
    regDiscountApplied: regDiscountApplied,
  })
}
