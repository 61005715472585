import { useEffect } from 'react'

export const useAutoRefresh = (interval) => {
  useEffect(() => {
    const refreshTimer = setInterval(() => {
      if (document.hidden) {
        window.location.reload()
      }
    }, interval)

    return () => clearInterval(refreshTimer)
  }, [])
}
