import { Instance, types } from 'mobx-state-tree'
import { setLastCartItemAdded } from 'bl-health/store/models/helpers'
import ProductStateModel from './ProductStateModel'
import UIStateModel from './UIStateModel'

const CartStateModel = types
  .compose(
    UIStateModel,
    types.model({
      product: types.optional(ProductStateModel, {}),
      lastCartItemAdded: types.maybe(types.string),
      includeFreeGift: types.optional(types.boolean, false),
      includeMilkBags: types.optional(types.boolean, false),
      aobAcknowledged: types.maybe(types.boolean),
    })
  )
  .actions((self) => {
    const setLastItemAdded = (
      cartResponse: APIResponse.Cart,
      productId: number
    ) => {
      // Set the last item added based on the cart item matching the  product that was added.
      // In theory this should match exactly 1 element in the array.
      const cartItems = cartResponse.items.filter(
        (item) => item.productId === productId
      )
      if (cartItems.length) {
        const { uuid } = cartItems[0]
        self.lastCartItemAdded = uuid
        setLastCartItemAdded(uuid)
      }
    }

    const setIncludeFreeGift = (shouldInclude: boolean) => {
      self.includeFreeGift = shouldInclude
    }

    const setIncludeMilkBags = (shouldInclude: boolean) => {
      self.includeMilkBags = shouldInclude
    }

    return {
      setLastItemAdded,
      setIncludeFreeGift,
      setIncludeMilkBags,
    }
  })
  .views((self) => ({
    get checkoutPayload() {
      return {
        aobAcknowledged: self.aobAcknowledged || undefined,
        includeMilkBags: self.includeMilkBags,
        includeFreeGift: self.includeFreeGift,
        cartItemIds: self.lastCartItemAdded
          ? [self.lastCartItemAdded]
          : undefined,
      }
    },
  }))
  .actions((self) => ({
    setAobAcknowledged: (acknowledged: boolean) => {
      self.aobAcknowledged = acknowledged
    },
  }))

export default CartStateModel
export type CartStateModelType = Instance<typeof CartStateModel>
