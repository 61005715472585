export const Email = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.66669"
      y="3.3335"
      width="16.6667"
      height="13.3333"
      rx="3.33333"
      stroke="#F9F9F9"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33331 4.1665L7.64296 8.47615C8.94471 9.7779 11.0553 9.7779 12.357 8.47615L16.6666 4.1665"
      stroke="#F9F9F9"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
