import BabyUIPaletteColorNode from 'baby-ui/lib/BabyUIPaletteColorNode'
import { TypeBackground } from '@material-ui/core/styles/createPalette'

export default class BabyUIColorPalette {
  public readonly primary: BabyUIPaletteColorNode

  public readonly secondary: BabyUIPaletteColorNode

  public readonly background: Partial<TypeBackground>

  constructor(
    primary: BabyUIPaletteColorNode,
    secondary: BabyUIPaletteColorNode,
    background: Partial<TypeBackground>
  ) {
    this.primary = primary
    this.secondary = secondary
    this.background = background
  }
}
