import { FC } from 'react'
import AppBar from 'baby-ui/compounds/AppBar'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import LegacyMobXWrapper from './LegacyMobXWrapper'
import LegacyMuiWrapper from './LegacyMuiWrapper'

const LegacyAppBar: FC = () => {
  const { flagValue: milkBagsForPartsBuyers } = useFeatureFlag(
    'dme_milk_bags_for_parts_buyers',
    false
  )

  return (
    <LegacyMobXWrapper>
      <LegacyMuiWrapper>
        <AppBar
          milkBagsForPartsBuyersOverride={milkBagsForPartsBuyers ?? false}
        />
      </LegacyMuiWrapper>
    </LegacyMobXWrapper>
  )
}

export default LegacyAppBar
