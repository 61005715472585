import HomeView, { HomeViewProps } from 'bl-health/views/HomeView'
import { withContextualizedTracking } from 'lib/babylistHealthAnalytics'
import { LayoutContainer, LayoutPresenter } from 'bl-health/widgets/Layout'
import { ApplicationProps } from 'bl-health/types'

export interface DMEHomePageProps extends ApplicationProps {
  homeView: HomeViewProps
}

const DMEHomePage = ({ homeView, settings, context }: DMEHomePageProps) => (
  <LayoutContainer context={context} settings={settings}>
    <LayoutPresenter>
      <HomeView guides={homeView.guides || []} />
    </LayoutPresenter>
  </LayoutContainer>
)

export default withContextualizedTracking()(DMEHomePage)
