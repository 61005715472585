import * as React from 'react'
import { Box, Grid, Link, useTheme } from '@material-ui/core'
import {
  DME_RETURN_POLICY_URL,
  PRIVACY_URL,
  TERMS_AND_CONDITIONS_PATH,
} from '../../constants'

export default function Footer() {
  const theme = useTheme()

  return (
    <Box
      borderTop={`1px solid ${theme.palette.grey[300]}`}
      mx={{ xs: 2, md: 8 }}
      my={2}
      pt={1}
    >
      <Grid container direction="row" justifyContent="flex-start">
        <FooterLink href={DME_RETURN_POLICY_URL}>Return Policy</FooterLink>
        <FooterLink href={PRIVACY_URL} target="_blank">
          Privacy policy
        </FooterLink>
        <FooterLink href={TERMS_AND_CONDITIONS_PATH}>
          Terms of service
        </FooterLink>
      </Grid>
    </Box>
  )
}

const FooterLink = ({
  href,
  children,
  target,
}: React.PropsWithChildren<{ href: string; target?: string }>) => {
  const theme = useTheme()

  return (
    <Box mr={2}>
      <Link
        href={href}
        style={{ fontWeight: theme.typography.fontWeightMedium }}
        target={target}
        variant="caption"
      >
        {children}
      </Link>
    </Box>
  )
}
