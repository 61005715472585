import { Skeleton } from '@material-ui/lab'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core'
import { ReplacementPartsCatalog } from 'bl-health/types'
import { FragmentController } from 'bl-health/components/ReplacementPartsCatalogStrategy/utils/useReplacementPartsCatalogStrategy'
import css from './ReplacementPartsCatalogStrategy.styles.scss'

export interface BrandsFragmentProps {
  controller: FragmentController<ReplacementPartsCatalog>
}

export default function BrandsFragment({ controller }: BrandsFragmentProps) {
  const { isLoading, data, error, select } = controller

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <Skeleton
          className={css.DropdownDownSkeleton}
          data-testid="skeleton"
          height={56}
          variant="rect"
        />
      </Grid>
    )
  }

  if (!data) return null

  const label = 'Pump brand'

  return (
    <Grid item xs={12}>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="brandsDropdown">{label}</InputLabel>
        <Select
          fullWidth
          native
          className={css.Dropdown}
          defaultValue=""
          error={!!error}
          id="brandsDropdown"
          label={label}
          name="brandsDropdown"
          variant="outlined"
          onChange={(e) => {
            const brand = data?.find(
              (option) => option.brand === e.target.value
            )
            select(brand)
          }}
        >
          <option disabled aria-label="None" />
          {data?.map((option) => (
            <option key={option.brand} value={option.brand}>
              {option.brand}
            </option>
          ))}
        </Select>
        <FormHelperText error={!!error}>{error}</FormHelperText>
      </FormControl>
    </Grid>
  )
}
