import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Link,
  Typography,
  Collapse,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core'
import {
  HOME_PATH,
  HEALTH_CONTACT_EMAIL,
  DME_ZENDESK_URL,
  SUPPORT_PHONE_NUMBER,
  FORMATTED_SUPPORT_PHONE_NUMBER,
} from 'bl-health/constants'
import { Email } from 'baby-ui/compounds/Footer/Email'
import { useState } from 'react'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import useStyles from './styles'
import { Chat } from './Chat'
import { Phone } from './Phone'

export interface NavGroup {
  heading: string
  href?: string
  links: Array<{
    href: string
    name: string
  }>
}

export interface FooterWidgetProps {
  navGroups: Array<NavGroup>
}

export default function FooterWidget({ navGroups }: FooterWidgetProps) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  )

  const handleToggle = (sectionName: string) => {
    const newOpenSections = {
      ...openSections,
      [sectionName]: !openSections?.[sectionName],
    }
    setOpenSections(newOpenSections)
  }

  return (
    <footer className={classes.root}>
      <Container disableGutters maxWidth={false}>
        <Box paddingTop={4} paddingX={4}>
          <Link aria-label="Visit homepage" href={HOME_PATH}>
            <img
              alt="Babylist Health Logo"
              src="//res.cloudinary.com/babylist/image/upload/v1707262769/DME/babylist_health_inverted.svg"
            />
          </Link>
        </Box>
        <Box p={4}>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item className={classes.supportGroup} md="auto" sm={12}>
              <Typography className={classes.navHeading} variant="h2">
                Support
              </Typography>
              <List>
                <ListItem disableGutters>
                  <Link
                    className={`${classes.navLink} ${classes.supportLink}`}
                    href={DME_ZENDESK_URL}
                  >
                    <Chat />
                    Help Center
                  </Link>
                </ListItem>
                <ListItem disableGutters>
                  <Grid direction="column">
                    <Link
                      className={`${classes.navLink} ${classes.supportLink}`}
                      href={`tel:${SUPPORT_PHONE_NUMBER}`}
                    >
                      <Phone />
                      {FORMATTED_SUPPORT_PHONE_NUMBER}
                    </Link>
                    <Box pl="28px" pt={1} width="100%">
                      <Typography className={classes.phoneHours}>
                        Weekdays
                      </Typography>
                      <Typography className={classes.phoneHours}>
                        9am-5pm Eastern
                      </Typography>
                    </Box>
                  </Grid>
                </ListItem>
                <ListItem disableGutters>
                  <Link
                    className={`${classes.navLink} ${classes.supportLink}`}
                    href={`mailto:${HEALTH_CONTACT_EMAIL}`}
                  >
                    <Email />
                    {HEALTH_CONTACT_EMAIL}
                  </Link>
                </ListItem>
              </List>
            </Grid>
            {navGroups.map((group: NavGroup) => (
              <Grid item key={group.heading} md="auto" xs={12}>
                {isMobile ? (
                  <>
                    <Button
                      className={classes.navHeadingButton}
                      onClick={() => handleToggle(group.heading)}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item sm={11}>
                          {group.heading}
                        </Grid>
                        {openSections?.[group.heading] && (
                          <Grid item className={classes.collapseIcon} sm={1}>
                            <ExpandLess />
                          </Grid>
                        )}
                        {!openSections?.[group.heading] && (
                          <Grid item className={classes.collapseIcon} sm={1}>
                            <ExpandMore />
                          </Grid>
                        )}
                      </Grid>
                    </Button>
                    <Collapse in={openSections?.[group.heading]}>
                      <List disablePadding component="div">
                        {group.links.map((item: any) => (
                          <ListItem dense disableGutters key={item.name}>
                            <Link
                              className={classes.navLink}
                              href={item.href}
                              target={item.target ?? '_self'}
                            >
                              {item.name}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <>
                    <Typography className={classes.navHeading} variant="h2">
                      {group.heading}
                    </Typography>
                    <List>
                      {group.links.map((item: any) => (
                        <ListItem dense disableGutters key={item.name}>
                          <Link
                            className={classes.navLink}
                            href={item.href}
                            target={item.target ?? '_self'}
                          >
                            {item.name}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                  </>
                )}
              </Grid>
            ))}
            <Grid item className={classes.copyrightGroup} xs={12}>
              <Box py={2}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={2}
                  xs={12}
                >
                  <Grid item md="auto" xs={12}>
                    1900 Powell St Suite 150
                    <br />
                    Emeryville, CA 94608
                  </Grid>
                  <Grid item md="auto" xs={12}>
                    <a
                      href="https://www.bbb.org/us/ca/emeryville/profile/baby-services/babylist-1116-897187/#sealclick"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="Babylist BBB Business Review"
                        src="https://seal-goldengate.bbb.org/seals/blue-seal-250-52-whitetxt-bbb-897187.png"
                        style={{ border: 0, height: '42px', width: '200px' }}
                      />
                    </a>
                  </Grid>
                </Grid>
              </Box>
              <div>&copy; 2011-{new Date().getFullYear()} Babylist, Inc</div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </footer>
  )
}
