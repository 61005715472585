import useStyles from '../InsuranceVerificationOverlay.styles'

export const Clover = () => {
  const classes = useStyles()
  return (
    <svg
      className={classes.clover}
      fill="none"
      height="110"
      viewBox="0 0 114 110"
      width="114"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2639 40.5C12.1835 40.4209 12.1033 40.3413 12.0234 40.2614C-3.91418 24.3239 -4.02098 -1.40927 11.7849 -17.2151C27.5908 -33.021 53.3239 -32.9142 69.2615 -16.9766C69.3414 -16.8967 69.4209 -16.8165 69.5001 -16.7361C69.5791 -16.8165 69.6586 -16.8966 69.7385 -16.9765C85.6761 -32.914 111.409 -33.0208 127.215 -17.215C143.021 -1.40914 142.914 24.324 126.977 40.2616C126.921 40.3171 126.865 40.3724 126.81 40.4275C126.785 40.4517 126.761 40.4759 126.736 40.5001C126.817 40.5792 126.897 40.6587 126.977 40.7386C142.914 56.6761 143.021 82.4093 127.215 98.2151C111.409 114.021 85.6761 113.914 69.7385 97.9766C69.6586 97.8967 69.5791 97.8166 69.5 97.7362C69.4209 97.8165 69.3414 97.8966 69.2616 97.9765C53.324 113.914 27.5908 114.021 11.785 98.215C-4.0209 82.4091 -3.91413 56.676 12.0234 40.7385C12.1033 40.6586 12.1835 40.5791 12.2639 40.5Z"
        fill="#EEE8E9"
        fillOpacity="0.5"
      />
    </svg>
  )
}

export default Clover
