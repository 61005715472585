import { InvalidPartsOrderCopyProps } from './types'
import { SimpleOrderBlocker } from './SimpleOrderBlocker'

export const AwaitingPumpDeliveryCopy = (
  _props: InvalidPartsOrderCopyProps
) => (
  <SimpleOrderBlocker
    content="We cannot send you replacement parts until your breast pump has been
        delivered and your insurance has paid the claim for your pump. We will
        email you when it's time to order."
    title="You can't order parts until your pump has been delivered."
  />
)
