import {
  getExpiringLocalStorage,
  setExpiringLocalStorage,
} from 'baby-ui/utils/expireLocalStorage'

export const SEARCH_ID_LOCAL_STORAGE_KEY = 'searchId'
export const SEARCH_ID_EXPIRATION_SECONDS = 1800 /* 30 minutes */

export const PRODUCT_SEARCH_INDEX_KEY = 'bl-hero-products'
export const GUIDE_SEARCH_INDEX_KEY = 'bl-guides'
export const REGISTRY_SEARCH_INDEX_KEY = 'bl-registry'

const generateLocalStorageKey = (indexName: string) =>
  `${indexName}_${SEARCH_ID_LOCAL_STORAGE_KEY}`

/**
 * Custom hook for accessing the searchId of the last search performed
 * on an index from localStorage
 */
export const useSearchId = (
  indexName: string
): [(value: string) => void, () => null | string] => {
  const localStorageKey = generateLocalStorageKey(indexName)

  const setSearchId = (value: string) => {
    setExpiringLocalStorage(
      localStorageKey,
      value,
      SEARCH_ID_EXPIRATION_SECONDS
    )
  }

  const getSearchId = () => getExpiringLocalStorage(localStorageKey)

  return [setSearchId, getSearchId]
}

/**
 * Get the searchId from localStorage. For use outside of react hook flow.
 */
export const getSearchId = (indexName: string): null | string =>
  getExpiringLocalStorage(generateLocalStorageKey(indexName))

/**
 * Set the searchId in localStorage. For use outside of react hook flow.
 */
export const setSearchId = (indexName: string, value: string): void =>
  setExpiringLocalStorage(
    generateLocalStorageKey(indexName),
    value,
    SEARCH_ID_EXPIRATION_SECONDS
  )
