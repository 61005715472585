import { track } from 'lib/babylistHealthAnalytics'
import { decorateEventPayload } from './decorateEventPayload'

export interface PumpBrandSelectedEventMetadata {
  brandName: string | undefined
}

export const pumpBrandSelectedEvent =
  (tracker: any) =>
  ({ brandName }: PumpBrandSelectedEventMetadata) => {
    const payload = decorateEventPayload({
      event: track.pumpBrandSelected,
      brandName,
    })
    tracker.trackEvent(payload)
  }
