import { getCurrentUser } from 'shared/utils/getCurrentUser/getCurrentUser'
import onCurrentUserLoaded from 'shared/utils/onCurrentUserLoaded/onCurrentUserLoaded'
import { CurrentUser } from '../../../global'

/**
 * This method will generate a promise that will resolve once we load the
 * current user info. The constant below can be used to wait until the
 * current user info is loaded before performing any dependent tasks.
 */
export const getCurrentUserLoadedPromise = () =>
  new Promise<CurrentUser | undefined>((resolve) => {
    const currentUser = getCurrentUser()
    if (currentUser) {
      resolve(currentUser)
    } else {
      onCurrentUserLoaded((user) => resolve(user))
    }
  })

export const currentUserLoadedPromise = getCurrentUserLoadedPromise()
