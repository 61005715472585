import { createStyles, makeStyles } from '@material-ui/core'

export default makeStyles((theme) =>
  createStyles({
    homeViewWrapper: {
      textAlign: 'center',
    },
    heroText: {
      textAlign: 'center',
      marginBottom: theme.spacing(2),
      fontWeight: 500,
    },
    illustratedStepsWrapper: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    guideSection: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  })
)
