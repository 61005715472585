import PropTypes from 'prop-types'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

const validateAltOrRole = (propVals, propName, componentName) => {
  const { alt, role } = propVals
  if (!alt && ['none', 'presentation'].indexOf(role) === -1) {
    return new Error(
      `Invalid props supplied to '${componentName}'. One of props 'alt' or 'role' is required.`
    )
  }
  if (propVals[propName] && typeof propVals[propName] !== 'string') {
    return new Error(
      `Invalid prop, ${propName} supplied to '${componentName}'. Expecting type 'string', received ${propVals[propName]}`
    )
  }
  return null
}

const getAlt = (alt) => {
  if (!alt) {
    return ''
  }
  if (alt[alt.length - 1] !== '.') {
    return `${alt}.` // period at the end forces the screen reader to pause before reading on
  }
  return alt
}

const Image = ({
  alt,
  className,
  height,
  lazyLoad,
  pinterest,
  cloudinaryId,
  placeholder,
  role,
  src,
  style,
  width,
  wrapperClassName,
}) => {
  const imgProps = {
    alt: getAlt(alt),
    className,
    height,
    nopin: pinterest ? undefined : 'nopin',
    role,
    src,
    style,
    width,
    loading: lazyLoad ? 'lazy' : 'auto',
  }

  // See https://cloudinary.com/documentation/image_transformations
  // Use camel-cased prop names
  const cloudinaryProps = {
    ...imgProps,
    cloudName: 'babylist',
    crop: 'scale',
    dpr: 'auto',
    fetchFormat: 'auto',
    height: height || undefined,
    publicId: cloudinaryId,
    responsive: true,
    quality: 'auto',
    width: width || 'auto',
  }

  let imageComponent = <img {...imgProps} />

  if (cloudinaryId) {
    imageComponent = <CloudinaryImage {...cloudinaryProps} />
  }

  if (lazyLoad === false) {
    return imageComponent
  }

  return (
    <LazyLoadComponent
      delayTime={150}
      placeholder={placeholder}
      wrapperClassName={wrapperClassName}
      width={imgProps.width}
      height={imgProps.height}
    >
      {imageComponent}
    </LazyLoadComponent>
  )
}

Image.propTypes = {
  alt: validateAltOrRole,
  role: validateAltOrRole,
  block: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lazyLoad: PropTypes.bool,
  pinterest: PropTypes.bool,
  placeholder: PropTypes.element,
  cloudinaryId: PropTypes.string,
  scrollPosition: PropTypes.object,
  src: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wrapperClassName: PropTypes.string,
}

Image.defaultProps = {
  lazyLoad: true,
}

export default Image
