import { createStyles, makeStyles } from '@material-ui/core'

const ERROR_COLOR = '#E57373'

export default makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('sm')]: {
        '& .MuiFormHelperText-root': {
          color: theme.palette.common.white,
        },
      },
    },
    errorStyle: {
      [theme.breakpoints.up('sm')]: {
        '& .MuiFormControl-root .MuiInputBase-root': {
          background: 'none !important',
        },
        '& .MuiFormControl-root label, & .MuiFormControl-root label.Mui-error.Mui-focused':
          {
            color: ERROR_COLOR,
          },
        '& .MuiFormControl-root .MuiFormHelperText-root.Mui-error': {
          color: ERROR_COLOR,
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: ERROR_COLOR,
        },
      },
    },
    privacyText: {
      color: theme.palette.common.white,
    },
    autoCompleteFieldBg: {
      [theme.breakpoints.up('sm')]: {
        borderRadius: theme.shape.borderRadius,
        '& .MuiFormLabel-root': {
          color: theme.palette.common.white,
        },
        '& .MuiFormControl-root label.Mui-focused': {
          color: theme.palette.common.white,
        },
        '& .MuiFormControl-root .MuiInputBase-root': {
          background: 'rgba(255, 255, 255, 0.15)',
        },
      },
    },
    mobileField: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiInputBase-root': {
          background: theme.palette.common.white,
        },
      },
    },
    emailField: {
      paddingBottom: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      '& label.Mui-focused': {
        color: theme.palette.common.white,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.common.white,
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.common.white,
      },
      '& .MuiOutlinedInput-input': {
        color: theme.palette.common.white,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: theme.palette.common.white,
          background: 'rgba(255, 255, 255, 0.15)',
        },
        '&:hover fieldset': {
          borderColor: theme.palette.common.white,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.common.white,
        },
      },
    },
    emailErrorField: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        '& .MuiFormLabel-root.Mui-error': {
          color: ERROR_COLOR,
        },
        '& .MuiInput-underline.Mui-error:after': {
          borderBottomColor: ERROR_COLOR,
        },
        '& .MuiFormHelperText-root.Mui-error': {
          color: ERROR_COLOR,
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: ERROR_COLOR,
        },
      },
    },
    resourcesText: {
      textAlign: 'center',
      maxWidth: 320,
      margin: 'auto',
    },
  })
)
