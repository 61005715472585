import { InvalidPartsOrderCopyProps } from './types'
import { SimpleOrderBlocker } from './SimpleOrderBlocker'

export const InsuranceStateUnsupportedCopy = (
  _props: InvalidPartsOrderCopyProps
) => (
  <SimpleOrderBlocker
    content="It's likely your insurance covers replacement parts! Please contact them
        to discuss your options."
    title="We're working hard to support your insurance for the replacement parts
        program, but we're not quite there yet."
  />
)
