// merge do not merge undefined keys
// merge({a: 1}, {a: undefined}) => {a: 1}
// TODO: use deepmerge!
import { merge, compact } from 'lodash'
import Cookies from 'js-cookie'
import { getProductType } from 'utils'
import { track } from './track'

/**
 * Extract trackable properties (id, slug, revision, type ...) from a post
 *
 * @param {post} Hash - post
 */
export const postEventProps = (post) => {
  if (post == undefined) {
    return {}
  }
  const {
    id: postId,
    slug: postSlug,
    revision: postRevision,
    type: postType,
  } = post
  return {
    postId,
    postSlug,
    postRevision,
    postType,
  }
}

/**
 * Extract trackable properties (id, createdAt, ...) from a user
 *
 * @param {user} Hash - user (from user responder v3)
 * @param {registryOverride} Hash - registry (from user responder v3) - optional override of registry
 * @param {familyOverride} Hash - family (from user responder v3) - optional override of family
 */
export const userEventProps = (user, registryOverride, familyOverride) => {
  if (user == undefined) {
    return {}
  }

  const {
    id: userId,
    email: userEmail,
    createdAt: userCreatedAtTime,
    family,
    currentRegistry: registry,
  } = user
  const userCreatedAt = (userCreatedAtTime || '').substring(0, 10)
  const { arrivalDate: familyArrivalDateTime } = familyOverride || family
  const familyArrivalDate = (familyArrivalDateTime || '').substring(0, 10)
  const { id: registryId, ownerId: registryOwnerId } =
    registryOverride || registry

  // logInCompleted takes a special `userId_` property for use in
  // the analytics.identify process.
  // https://www.avo.app/docs/workspace/tracking-plan#a-nameidentify-usera-identify-user
  return {
    userId_: `${userId}`,
    userCreatedAt,
    userEmail,
    arrivalDate: familyArrivalDate, // should be named userArrivalDate
    primaryRegistryId: registryId, // should be named userRegistryId?
    isPartner: registryOwnerId != userId,
  }
}

/**
 * Extract trackable properties (cartTypes, cartTotalValue) from a list of cartItems
 *
 * @param {cartItems} array - cart items
 */
export const cartItemsEventProps = (cartItems) => {
  if (cartItems == undefined) {
    return {}
  }

  const cartTypes = new Set()
  cartItems.forEach((cartItem) => {
    if (cartItem.type == 'store') {
      cartTypes.add(track.CartTypes.STORE)
      // reservations
    } else if (cartItem.isPurchasableOnBabylist) {
      cartTypes.add(track.CartTypes.STORE_RESERVATION)
    } else {
      cartTypes.add(track.CartTypes.EXTERNAL_RESERVATION)
    }
  })
  const cartTotal = cartItems.reduce((memo, cartItem) => {
    return memo + cartItem.quantity * cartItem.price
  }, 0)

  return {
    cartTypes: Array.from(cartTypes),
    cartTotalValue: cartTotal,
    productIds: cartItems.map((c) => c.productId),
    regItemIds: compact(cartItems.map((c) => c.regItemId)),
  }
}

/**
 * Extract trackable properties (id, ownerId, ...) from a registry
 *
 * @param {registry} Hash - registry
 */
export const registryEventProps = (registry, isRegistryOwner) => {
  if (registry == undefined) {
    return {}
  }

  const userId = Cookies.get('bl_logged_in_user')

  return {
    registryId: registry.id,
    registryOwnerId: registry.ownerId,
    isRegistryOwner:
      isRegistryOwner || Number(userId) === Number(registry.ownerId),
    listType:
      registry.type === null ? track.ListType.BABY_REGISTRY : registry.type,
  }
}

/**
 * Extract trackable properties (productIds, price) from a product
 * gift card have price depending of user input..
 *
 *
 * @param {product} Hash - product
 * @param {giftCardPrice} Float - gift card price
 */
export const productEventProps = (product, giftCardPrice) => {
  if (product == undefined) {
    return {}
  }
  const {
    id: productId,
    price: { current: productPrice },
    isElectronicGiftCard,
    isGiftCard,
    brand,
  } = product
  return {
    productIds: [productId],
    productPrice:
      (giftCardPrice && parseFloat(giftCardPrice)) ||
      (productPrice && parseFloat(productPrice)),
    productType: getProductType(isElectronicGiftCard || isGiftCard),
    productBrand: brand,
  }
}

/**
 * Extract trackable properties (productIds, qty, ...) from a cart item
 *
 * @param {cartItem} Hash - cart item
 */
// fix this
export const newCartItemEventProps = (cartItem) => {
  if (cartItem == undefined) {
    return {}
  }

  const { product, quantity: cartItemQuantity, giftCardPrice } = cartItem

  return {
    ...productEventProps(product, giftCardPrice),
    cartItemQuantity,
  }
}

/**
 * Extract trackable properties (productIds, qty, ...) from a cart item
 *
 * @param {cartItem} Hash - cart item
 */
export const cartItemEventProps = (cartItem) => {
  if (cartItem == undefined) {
    return {}
  }

  return {
    productIds: [cartItem.productId],
    cartItemQuantity: cartItem.quantity,
    productPrice: cartItem.price ? parseFloat(cartItem.price) : undefined,
    productType: getProductType(
      cartItem.isElectronicGiftCard || cartItem.isGiftCard
    ),
  }
}

/**
 * Extract trackable properties (regItem trackable properties, qty, ...) from a reservation
 *
 * @param {reservation} Hash - reservation
 */
export const reservationEventProps = (reservation, regItem, registry) => {
  if (reservation == undefined) {
    return {}
  }
  const {
    regItemId,
    productId,
    registryId,
    regItem: reservationRegItem,
  } = reservation

  // merge because merge({a: 1}, {a: undefined}) => {a: 1}, merge({a: undefined}, {a: 1}) => {a: 1}
  return merge(
    {
      ...regItemEventProps(regItem || reservationRegItem, registry),
      regItemIds: [regItemId],
      productIds: [productId],
    },
    {
      registryId,
    }
  )
}

/**
 * Extract trackable properties (productIds, regItem's registry trackable properties) from a regItem
 *
 * @param {regItem} Hash - regItem
 */
export const regItemEventProps = (regItem, registry) => {
  if (regItem == undefined) {
    return {}
  }

  const {
    price: regItemPrice,
    quantity: regItemQuantity,
    isElectronicGiftCard,
    isGiftCard,
    id: regItemId,
    productId,
    registryId,
    registry: regItemRegistry,
  } = regItem

  // merge because merge({a: 1}, {a: undefined}) => {a: 1}, merge({a: undefined}, {a: 1}) => {a: 1}
  return merge(
    {
      ...registryEventProps(registry || regItemRegistry),
      productType: getProductType(isElectronicGiftCard || isGiftCard),
      productPrice: regItemPrice && parseFloat(regItemPrice),
      regItemPrice: regItemPrice && parseFloat(regItemPrice),
      regItemQuantity: regItemQuantity && parseInt(regItemQuantity),
      regItemIds: [regItemId],
      productIds: [productId],
    },
    {
      registryId,
    }
  )
}
