import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    textOutlined: {
      textStroke: `4px ${theme.palette.primary.main}`,
      fontSize: theme.spacing(10),
      fontWeight: theme.typography.fontWeightBold,
      color: 'transparent',
      '&::selection': {
        textStroke: `4px ${theme.palette.primary.main}`,
        fontSize: theme.spacing(10),
        fontWeight: theme.typography.fontWeightBold,
        color: 'transparent',
      },
    },
    description: { textAlign: 'center', fontWeight: 'normal' },
    cta: { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) },
    root: {
      backgroundColor: theme.palette.background.contrast,
      [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.background.contrast,
        background: [
          'url(//images.babylist.com/image/upload/v1671219979/DME/burst.svg) no-repeat top -64px right 5%',
          'url(//images.babylist.com/image/upload/v1671220706/DME/clover.png) no-repeat top -64px left 12%',
          'url(//images.babylist.com/image/upload/v1671221071/DME/Vector.svg) no-repeat bottom -128px left 2%',
          'url(//images.babylist.com/image/upload/v1671221248/DME/Triangle.svg) no-repeat bottom 50% left -112px',
          'url(//images.babylist.com/image/upload/v1671221546/DME/arch.svg) no-repeat bottom -40px right -64px',
        ].join(','),
      },
    },
  })
)
