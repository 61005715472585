import { createStyles, makeStyles, Theme } from '@material-ui/core'

export default makeStyles((theme: Theme) =>
  createStyles({
    shippingInfoIcon: {
      padding: 0,
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      '&:hover': {
        background: 'none',
      },
    },
  })
)
