import { createContext, useContext } from 'react'
import Cookies from 'js-cookie'
import { ApplicationContext, ApplicationStrategy } from 'bl-health/types'
import { getLastCartItemAdded } from 'bl-health/store/models/helpers'
import { RootStateModel, RootStateModelType } from './models'

let initialState = RootStateModel.create()

const RootStoreContext = createContext<null | RootStateModelType>(null)

export function useDMEStore(): RootStateModelType {
  const store = useContext(RootStoreContext)

  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }

  return store
}

export const DMEStoreProvider = RootStoreContext.Provider

export let rootStore = initialState

export const createRootStore = (context?: ApplicationContext) => {
  const strategy =
    context?.strategy !== ApplicationStrategy.Pumps
      ? context?.strategy
      : ApplicationStrategy.Pumps

  initialState = RootStateModel.create({
    context: {
      formAction: context?.formAction,
      nextPath: context?.nextPath,
      strategy,
      upgradeFee: context?.upgradeFee,
      productInCart: context?.productInCart,
    },
    insurance: {
      id: context?.insuranceId,
      state: localStorage.getItem('state_name'),
      catalogCategoryId: context?.catalogCategoryId,
      requiresPrescription: context?.requiresPrescription,
      manualReviewRequired: context?.manualReviewRequired,
      shippingEligibilityDays: context?.shippingEligibilityDays,
      billingProvider: context?.billingProvider,
    },
    ui: {
      hasSeenFeatureIndicator:
        Cookies.get('has_seen_feature_indicator') === 'true',
    },
    cart: {
      lastCartItemAdded: getLastCartItemAdded(),
    },
  })
  rootStore = initialState
  return initialState
}

export * from './models'
