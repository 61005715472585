export enum ApplicationStrategy {
  Parts = 'parts',
  Pumps = 'pumps',
}

export interface ApplicationContext {
  billingProvider?: string
  catalogCategoryId?: number
  formAction?: string
  insuranceId?: number
  nextPath?: string
  requiresPrescription?: boolean
  manualReviewRequired?: boolean
  shippingEligibilityDays?: number
  strategy?: ApplicationStrategy
  upgradeFee?: boolean
  productInCart?: {
    id: number
    brand: string
    name: string
    price: string
    thumbnailImageUrl: string
  }
}

export interface ApplicationProps {
  settings?: ApplicationSettings
  context?: ApplicationContext
}

export interface ApplicationSettings {
  isOptimizedDropdownEnabled: boolean
  isPlpEmailCollectionEnabled: boolean
  catalogCategoryId?: number
  milkBagsForPartsBuyers?: boolean
  isUserReEngagementEmailCaptureEnabled: boolean
}
